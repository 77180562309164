import React, { useEffect, useState } from 'react';
import MUIDataTable from 'mui-datatables';
import Loading from 'components/Loading';
import { getEquipmentMatrix } from 'services/user';

export default function EquipmentMatrix() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  // var defaultData = {
  //   id: null,
  //   name: '',
  //   show: false,
  // };
  //const [equipmentData, setEquipmentData] = useState(defaultData);

  // const [questionaires, setQuestionaires] = useState(null);
  // const [departments, setDepartments] = useState(null);
  // const [addAction, showAddAction] = useState(false);

  const options = {
    selectableRows: 'none',
    filterType: 'checkbox',
    rowsPerPage: 100,
  };
  // const RefreshDataCloseModal = () => {
  //   setEquipmentData({
  //     id: null,
  //     name: '',
  //     show: false,
  //   });
  //   LoadData();
  // };

  const LoadData = () => {
    getEquipmentMatrix().then((values) => {
      var equipments = values.data.equipments;
      var assessments = values.data.assessments;
      var users = [...new Set(values.data.assessments.map((x) => x.email))];

      // cols.push({
      //   label: 'Email',
      //   name: 'email',
      // });
      // cols.push({
      //   label: 'Chair',
      //   name: 1,
      // });
      var cols = [];
      cols.push({
        label: 'Email',
        name: 'Email',
        options: {
          filter: false,
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return value;
        },
      });
      equipments.forEach((equipment) => {
        cols.push({
          label: equipment.name,
          name: equipment.id,
          customBodyRender: (value, tableMeta, updateValue) => {
            return value;
          },
        });
      });
      setColumns(cols);

      var dt = [];

      users.forEach((user) => {
        var recordData = [user];

        equipments.forEach((equipment) => {
          var exist = assessments.find((element) => {
            return (
              element.equipmentid === equipment.id && element.email === user
            );
          });
          recordData.push(
            exist == null ? '' : exist.required === 0 ? 'Optional' : 'Required'
          );
        });
        dt.push(recordData);
      });

      setData(dt);

      setLoading(false);
    });
  };
  useEffect(() => {
    LoadData();
  }, []);
  return (
    <>
      <Loading show={loading} />

      {!loading && (
        <MUIDataTable
          title="Equipment Matrix"
          data={data}
          columns={columns}
          options={options}
        />
      )}
    </>
  );
}
