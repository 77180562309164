import React, { useEffect, useState } from 'react';
import MUIDataTable from 'mui-datatables';
import Loading from 'components/Loading';
import { GetUsers } from 'services/user';
import { Edit, Done } from '@material-ui/icons';
import UsersAdd from './Add';
import DatatableCustomToolbar from 'components/Editor/DatatableCustomToolbar';
export default function UsersList() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  var defaultData = {
    id: null,
    email: '',
    enabled: false,
    show: false,
  };
  const [usersData, setUsersData] = useState(defaultData);

  // const [questionaires, setQuestionaires] = useState(null);
  // const [departments, setDepartments] = useState(null);
  // const [addAction, showAddAction] = useState(false);

  const columns = [
    {
      label: 'ID',
      name: 'id',
      options: {
        filter: false,
        display: false,
      },
    },
    {
      label: 'Edit',
      name: 'id',
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Edit
              onClick={() => {
                setUsersData({
                  id: value,
                  email: tableMeta.rowData[5],
                  enabled:
                    tableMeta.rowData[8] === 1 || tableMeta.rowData[8] === '1',
                  administrator: tableMeta.rowData[9] <= 1,
                  show: true,
                });
              }}
            >
              Edit
            </Edit>
          );
        },
      },
    },
    {
      label: 'User Type',
      name: 'description',
      options: {
        filter: true,
      },
    },
    {
      label: 'Name',
      name: 'first_name',
      options: {
        filter: false,
      },
    },
    {
      label: 'Department',
      name: 'department',
      options: {
        filter: true,
      },
    },
    {
      label: 'Email',
      name: 'email',
      options: {
        filter: false,
      },
    },
    {
      label: 'Activated',
      name: 'activation_key',
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          if (value == null) {
            // tableMeta contains all the info that is needed
            return <Done />;
          }
        },
      },
    },
    {
      label: 'Activation Key',
      name: 'activation_key',
      options: {
        filter: false,
      },
    },
    {
      label: 'Enabled',
      name: 'enabled',
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          if (value === 1) {
            // tableMeta contains all the info that is needed
            return <Done />;
          }
        },
      },
    },
    {
      label: 'Type',
      name: 'user_type_id',
      options: {
        display: false,
        filter: false,
      },
    },
  ];

  const options = {
    selectableRows: 'none',
    filterType: 'checkbox',
    rowsPerPage: 100,
    customToolbar: () => {
      return (
        <DatatableCustomToolbar
          onClick={() => {
            setUsersData({
              id: null,
              email: '',
              enabled: true,
              administrator: '',
              show: true,
            });
          }}
        />
      );
    },
  };
  const RefreshDataCloseModal = () => {
    setUsersData({
      id: null,
      name: '',
      email: '',
      enabled: false,
      show: false,
    });
    LoadData();
  };
  const LoadData = () => {
    GetUsers().then((res) => {
      var _data = res.data;

      if (_data.length === 0) {
        _data = [];
      }

      setData(_data);
      setLoading(false);
    });
  };
  useEffect(() => {
    LoadData();
  }, []);
  return (
    <>
      <Loading show={loading} />
      <UsersAdd
        callBack={RefreshDataCloseModal}
        data={usersData}
        cancelCallback={() => setUsersData(defaultData)}
      />
      {!loading && (
        <MUIDataTable
          title="Users List"
          data={data}
          columns={columns}
          options={options}
        />
      )}
    </>
  );
}
