import React, { useEffect, useState } from 'react';
import Loading from 'components/Loading';
import Button from 'components/CustomButtons/Button.js';
import { GetReminders, SendReminders } from 'services/user';

import { useHistory } from 'react-router-dom';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import PrimaryTypo from 'components/Typography/Primary';
import InfoTypo from 'components/Typography/Info';
import Graph from './Graph';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { CenterFocusStrong } from '@material-ui/icons';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      textAlign: 'center',
      align: CenterFocusStrong,
      margin: theme.spacing(1),
      width: theme.spacing(16),
      height: theme.spacing(16),
    },
  },

  customAlert: {
    textAlign: 'center',
    align: CenterFocusStrong,
    margin: theme.spacing(1),
    width: '100%',
    height: theme.spacing(16),
  },
}));
export default function RemindersList() {
  const [loading, setLoading] = useState(true);
  const classes = useStyles();
  var defaultData = {
    countnotactivated: null,
    countnotcompleted: null,
    graphData: [],
    disableSend: true,
  };
  const [remindersData, setRemindersData] = useState(defaultData);
  const history = useHistory();
  const LoadData = () => {
    setLoading(true);
    GetReminders().then((res) => {
      var _res = res.data;

      // if (_data.length === 0) {
      //   _data = [];
      // }
      var _data = defaultData;
      _data.countnotactivated = _res.NotActivated;
      _data.countnotcompleted = _res.NotCompleted;
      _data.disableSend = !_res.EnableSend;
      _data.graphData = _res.stats;

      setRemindersData(_data);
      setLoading(false);
    });
  };

  const SendConfirmation = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className={classes.customAlert}>
            <h1>Are you sure you want to send this reminders?</h1>
            <p>When pressing yes the reminders will be automatically sent</p>
            <Button onClick={onClose} color="secundary">
              No
            </Button>
            <Button
              color="primary"
              onClick={() => {
                Send();
                onClose();
              }}
            >
              Yes
            </Button>
          </div>
        );
      },
    });
  };
  const Send = () => {
    setLoading(true);
    SendReminders()
      .then((res) => {
        LoadData();
      })
      .catch((ex) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    LoadData();
  }, []);
  return (
    <>
      <Loading show={loading} />
      {!loading && (
        <GridContainer>
          <GridItem md={12}>
            <PrimaryTypo>
              You can use this page to send reminders to the employees
              performing assessments
            </PrimaryTypo>
          </GridItem>
          <GridItem md={8} className={classes.root}>
            <Paper variant="outlined" square>
              <PrimaryTypo>{remindersData.countnotactivated}</PrimaryTypo>
              <InfoTypo>Users not activated</InfoTypo>
            </Paper>
            <Paper variant="outlined" square>
              <PrimaryTypo>{remindersData.countnotcompleted}</PrimaryTypo>
              <InfoTypo>Users activated not completed</InfoTypo>
            </Paper>

            <Button
              loading={loading}
              disabled={remindersData.disableSend}
              onClick={SendConfirmation}
              color="primary"
            >
              {remindersData.disableSend
                ? 'Already Sent Today'
                : 'Send Reminders'}
            </Button>
          </GridItem>
          <GridItem md={4} className={classes.root}>
            <Button
              onClick={() => history.push('/reminders-select')}
              color="primary"
            >
              Send Individual
            </Button>
            <Button
              onClick={() => history.push('/reminders-history')}
              color="primary"
            >
              See History
            </Button>
          </GridItem>
          <GridItem md={12}>
            <hr />
          </GridItem>
          <GridItem md={12}>
            <Graph data={remindersData.graphData} />
          </GridItem>
        </GridContainer>
      )}
    </>
  );
}
