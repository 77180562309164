import React, { useEffect, useState } from 'react';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
// @material-ui/icons
import SnackbarContent from 'components/Snackbar/SnackbarContent.js';
import { SaveUserStore, GetMe } from 'services/auth';
// core components
import Header from 'components/Header/Header.js';
import Footer from 'components/Footer/Footer.js';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';
import styles from 'assets/jss/material-kit-react/views/loginPage.js';
import image from 'assets/img/bg7.jpg';
import Loading from 'components/Loading';
import CardFooter from 'components/Card/CardFooter';
import { useHistory, useLocation } from 'react-router-dom';
const useStyles = makeStyles(styles);

export default function SSOResultPage(props) {
  const location = useLocation();

  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [sucess, setSucess] = useState(true);

  const [errorMessage, setErrorMessage] = useState(null);
  const [cardAnimaton, setCardAnimation] = useState('cardHidden');

  useEffect(() => {
    var params = new URLSearchParams(location.search);

    var token = params.get('tk');

    const expiration = 1440;
    if (token == null) {
      setSucess(false);
      var resMessage =
        'User does not have permission to login into this application';
      setErrorMessage(resMessage);
      return;
    }
    SaveUserStore(token, expiration, false);
    setErrorMessage(null);
    GetMe(token)
      .then((result) => {
        history.push(result.home);
        setLoading(false);
      })
      .catch((er) => {
        var resMessage = 'Failed to login please verify your credentials';
        if (er.response && er.response.status === 451) {
          resMessage =
            'User does not have permission to login into this application';
        }
        setLoading(false);
        setErrorMessage(resMessage);
      });
  }, [location, history]);

  setTimeout(function () {
    setCardAnimation('');
  }, 700);
  const classes = useStyles();
  const { ...rest } = props;

  return (
    <div>
      <Header absolute color="transparent" brand="Domus" {...rest} />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: 'url(' + image + ')',
          backgroundSize: 'cover',
          backgroundPosition: 'top center',
        }}
      >
        <div className={classes.container}>
          <GridContainer justifyContent="center">
            <GridItem xs={12} sm={12} md={4}>
              <Card className={classes[cardAnimaton]}>
                <form className={classes.form}>
                  <CardHeader color="primary" className={classes.cardHeader}>
                    <h4>Login Validation</h4>
                  </CardHeader>
                  <CardBody align="center">
                    <Loading show={loading} />
                  </CardBody>
                  <CardFooter>
                    {errorMessage !== null && (
                      <SnackbarContent
                        message={errorMessage}
                        close
                        color="danger"
                        icon="info_outline"
                      />
                    )}
                  </CardFooter>
                </form>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
        <Footer whiteFont />
      </div>
    </div>
  );
}
