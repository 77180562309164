import React from 'react';
import PropTypes from 'prop-types';

import UsersList from 'components/Users/List';
import Layout from 'components/Layout/Layout';
import { SupervisorAccount } from '@material-ui/icons';
export default function UsersListPage(props) {
  const { window } = props;

  return (
    <Layout
      window={window}
      title="Users"
      icon={<SupervisorAccount fontSize="large" />}
    >
      <UsersList />
    </Layout>
  );
}

UsersListPage.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};
