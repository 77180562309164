import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import PrimaryTypo from 'components/Typography/Primary';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import IconButton from '@material-ui/core/IconButton';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from '@material-ui/pickers';
import InputLabel from '@material-ui/core/InputLabel';
import { ArrowBackIos, NavigateNext } from '@material-ui/icons';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { People, DriveEta } from '@material-ui/icons';
import InputAdornment from '@material-ui/core/InputAdornment';
import CustomInput from 'components/CustomInput/CustomInput.js';
import {
  primaryColor,
  dangerColor,
  roseColor,
  grayColor,
} from 'assets/jss/material-kit-react.js';
const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    // display: 'flex',
    maxWidth: '500px',
    // '& > *': {
    //   margin: theme.spacing(1),
    // },
  },
  large: {
    width: theme.spacing(30),
    height: theme.spacing(30),
  },
  checkRoot: {
    padding: '12px',
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  radioRoot: {
    padding: '12px',
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  labelRoot: {
    marginLeft: '-14px',
  },
  checkboxAndRadio: {
    position: 'relative',
    display: 'block',
    marginTop: '10px',
    marginBottom: '10px',
  },
  checkboxAndRadioHorizontal: {
    position: 'relative',
    display: 'block',
    '&:first-child': {
      marginTop: '10px',
    },
    '&:not(:first-child)': {
      marginTop: '-14px',
    },
    marginTop: '0',
    marginBottom: '0',
  },
  checked: {
    color: primaryColor + '!important',
  },
  checkedIcon: {
    width: '20px',
    height: '20px',
    border: '1px solid rgba(0, 0, 0, .54)',
    borderRadius: '3px',
  },
  uncheckedIcon: {
    width: '0px',
    height: '0px',
    padding: '9px',
    border: '1px solid rgba(0, 0, 0, .54)',
    borderRadius: '3px',
  },
  disabledCheckboxAndRadio: {
    opacity: '0.45',
  },
  label: {
    cursor: 'pointer',
    paddingLeft: '0',

    fontSize: '24px',
    lineHeight: '1.428571429',
    fontWeight: '800',
    display: 'inline-flex',
    transition: '0.3s ease all',
    letterSpacing: 'unset',
  },
  labelHorizontal: {
    color: 'rgba(0, 0, 0, 0.26)',
    cursor: 'pointer',
    display: 'inline-flex',
    fontSize: '14px',
    lineHeight: '1.428571429',
    fontWeight: '400',
    paddingTop: '39px',
    marginRight: '0',
    '@media (min-width: 992px)': {
      float: 'right',
    },
  },
  labelHorizontalRadioCheckbox: {
    paddingTop: '22px',
  },
  labelLeftHorizontal: {
    color: 'rgba(0, 0, 0, 0.26)',
    cursor: 'pointer',
    display: 'inline-flex',
    fontSize: '14px',
    lineHeight: '1.428571429',
    fontWeight: '400',
    paddingTop: '22px',
    marginRight: '0',
  },
  labelError: {
    color: dangerColor,
  },
  radio: {
    color: primaryColor + '!important',
  },
  radioChecked: {
    width: '16px',
    height: '16px',
    border: '1px solid ' + primaryColor,
    borderRadius: '50%',
  },
  radioUnchecked: {
    width: '0px',
    height: '0px',
    padding: '7px',
    border: '1px solid rgba(0, 0, 0, .54)',
    borderRadius: '50%',
  },
  inlineChecks: {
    marginTop: '8px',
  },
  iconCheckbox: {
    height: '116px',
    width: '116px',
    color: grayColor,
    '& > span:first-child': {
      borderWidth: '4px',
      borderStyle: 'solid',
      borderColor: '#CCCCCC',
      textAlign: 'center',
      verticalAlign: 'middle',
      borderRadius: '50%',
      color: 'inherit',
      margin: '0 auto 20px',
      transition: 'all 0.2s',
    },
    '&:hover': {
      color: roseColor,
      '& > span:first-child': {
        borderColor: roseColor,
      },
    },
  },
  iconCheckboxChecked: {
    color: roseColor,
    '& > span:first-child': {
      borderColor: roseColor,
    },
  },
  iconCheckboxIcon: {
    fontSize: '40px',
    lineHeight: '111px',
  },
  switchBase: {
    color: primaryColor + '!important',
  },
  switchIcon: {
    boxShadow: '0 1px 3px 1px rgba(0, 0, 0, 0.4)',
    color: '#FFFFFF !important',
    border: '1px solid rgba(0, 0, 0, .54)',
  },
  switchBar: {
    width: '30px',
    height: '15px',
    backgroundColor: 'rgb(80, 80, 80)',
    borderRadius: '15px',
    opacity: '0.7!important',
  },
  switchChecked: {
    '& + $switchBar': {
      backgroundColor: '#101B2C !important',
    },
    '& $switchIcon': {
      borderColor: '#C4A168',
    },
  },
  switchRoot: {
    height: '48px',
  },
  increase: {
    '& label': {
      fontSize: '20px !important',
    },
  },
}));

export default function InfoContact(props) {
  console.log('props', props);
  const booked = props.info.booked;
  const name = props.info.name;
  const tableId = props.info.id;
  const picture = props.info.picture;
  const date = props.date;
  console.log('date::', date);
  const department = props.info.department;
  const avatar = props.info.avatar;
  const classes = useStyles();
  const book = () => {};
  const cancel = () => {
    props.cancel();
  };
  const [bookingCafeteria, setBookingCafeteria] = useState(false);
  const [bookingParking, setBookingParking] = useState(false);
  const [bookingCollaborative, setBookingCollaborative] = useState(false);
  const [startCollaborativeSpace, setStartCollaborativeSpace] = useState(null);
  const [endCollaborativeSpace, setEndCollaborativeSpace] = useState(null);
  const [startCanteen, setStartCanteen] = useState(null);
  const [endCanteen, setEndCanteen] = useState(null);
  return (
    <>
      {!booked && (
        <GridContainer className={classes.root}>
          <GridItem md={12}>
            <PrimaryTypo variant="h5">
              You are booking desk {tableId}
              <br />
              {new Date(date).toDateString()}
            </PrimaryTypo>
          </GridItem>
          <GridItem md={12}>
            <h3>Do you need anything else?</h3>
          </GridItem>
          <GridItem md={12}>
            <PrimaryTypo>
              <FormControlLabel
                control={
                  <Switch
                    checked={bookingCollaborative}
                    onChange={(event) =>
                      setBookingCollaborative(event.target.checked)
                    }
                    classes={{
                      switchBase: classes.switchBase,
                      checked: classes.switchChecked,
                      thumb: classes.switchIcon,
                      track: classes.switchBar,
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                }}
                label="Book Collaborative Space"
              />
            </PrimaryTypo>
            {bookingCollaborative && (
              <GridContainer>
                <GridItem md={12}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardTimePicker
                      margin="normal"
                      id="time-picker"
                      label="Start Time"
                      value={startCanteen}
                      onChange={setStartCanteen}
                      KeyboardButtonProps={{
                        'aria-label': 'change time',
                      }}
                    />
                    <KeyboardTimePicker
                      margin="normal"
                      id="time-picker"
                      label="End Time"
                      value={endCanteen}
                      onChange={setEndCanteen}
                      KeyboardButtonProps={{
                        'aria-label': 'change time',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </GridItem>
                <GridItem>
                  <InputLabel id="demo-simple-select-helper-label">
                    Available Space
                  </InputLabel>
                  <Select
                    className={classes.formControl}
                    id="collaborativeSpace"
                    label="Choose space"
                    fullWidth
                  >
                    <MenuItem key="1" value="1">
                      Donegal Room
                    </MenuItem>
                    <MenuItem key="2" value="2">
                      Wicklow Room
                    </MenuItem>
                    <MenuItem key="3" value="3">
                      Mayo Room
                    </MenuItem>
                  </Select>
                </GridItem>
                <GridItem>
                  <CustomInput
                    labelText="Invite Colleagues"
                    id="first"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    labelProps={{
                      className: classes.increase,
                    }}
                    inputProps={{
                      type: 'text',
                      endAdornment: (
                        <InputAdornment position="end">
                          <People className={classes.inputIconsColor} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </GridItem>
              </GridContainer>
            )}
          </GridItem>
          <GridItem md={12}>
            <PrimaryTypo>
              <FormControlLabel
                control={
                  <Switch
                    checked={bookingCafeteria}
                    onChange={(e) => setBookingCafeteria(e.target.checked)}
                    classes={{
                      switchBase: classes.switchBase,
                      checked: classes.switchChecked,
                      thumb: classes.switchIcon,
                      track: classes.switchBar,
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                }}
                label="Book Canteen"
              />
            </PrimaryTypo>
            {bookingCafeteria && (
              <GridContainer>
                <GridItem md={12}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardTimePicker
                      margin="normal"
                      id="time-picker"
                      label="Start Time"
                      value={startCollaborativeSpace}
                      onChange={setStartCollaborativeSpace}
                      KeyboardButtonProps={{
                        'aria-label': 'change time',
                      }}
                    />
                    <KeyboardTimePicker
                      margin="normal"
                      id="time-picker"
                      label="End Time"
                      value={endCollaborativeSpace}
                      onChange={setEndCollaborativeSpace}
                      KeyboardButtonProps={{
                        'aria-label': 'change time',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </GridItem>

                <GridItem>
                  <CustomInput
                    labelText="Invite Colleagues"
                    id="first"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    labelProps={{
                      className: classes.increase,
                    }}
                    inputProps={{
                      type: 'text',
                      endAdornment: (
                        <InputAdornment position="end">
                          <People className={classes.inputIconsColor} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </GridItem>
              </GridContainer>
            )}
          </GridItem>

          <GridItem md={12}>
            <PrimaryTypo>
              <FormControlLabel
                control={
                  <Switch
                    checked={bookingParking}
                    onChange={(event) =>
                      setBookingParking(event.target.checked)
                    }
                    classes={{
                      switchBase: classes.switchBase,
                      checked: classes.switchChecked,
                      thumb: classes.switchIcon,
                      track: classes.switchBar,
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                }}
                label="Book Parking"
              />
            </PrimaryTypo>
            {bookingParking && (
              <GridContainer>
                <GridItem>
                  <CustomInput
                    labelText="Registration"
                    id="first"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: 'text',
                      endAdornment: (
                        <InputAdornment position="end">
                          <DriveEta className={classes.inputIconsColor} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </GridItem>
              </GridContainer>
            )}
          </GridItem>

          <GridItem md={6}>
            <IconButton onClick={cancel}>
              <ArrowBackIos /> Cancel
            </IconButton>
          </GridItem>
          <GridItem md={6}>
            <IconButton onClick={book}>
              Continue <NavigateNext />
            </IconButton>
          </GridItem>
        </GridContainer>
      )}
      {booked && (
        <GridContainer className={classes.root}>
          <GridItem md={12}>
            <Avatar alt={name} src={picture} className={classes.large} />
          </GridItem>
          <GridItem md={12}>
            <PrimaryTypo>{name}</PrimaryTypo>
          </GridItem>
          <GridItem md={12}>
            <PrimaryTypo>{department}</PrimaryTypo>
          </GridItem>
        </GridContainer>
      )}
    </>
  );
}
