import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from 'components/CustomButtons/Button';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import PropTypes from 'prop-types';
import Consent from './Consent';
import VideoTraining from './VideoTraining';
import Questionaire from './Questionaire';
import UploadPhotos from './UploadPhotos';
import Confirm from './Confirm';
import Confirmation from './Confirmation';
import SnackbarContent from 'components/Snackbar/SnackbarContent.js';
import { getCurrentUser } from 'services/auth';
import {
  getActiveAssessment,
  addAssessment,
  addAssessmentPhotos,
} from '../../../services/user';
import { StepsContext, stepsInfo } from './Context';
import { CircularProgress } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  customStepper: {
    '& .MuiStepIcon-completed, & .MuiStepIcon-completed > path': {
      color: '#C2A55C',
    },
  },
}));

function getSteps() {
  return [
    'Consent',
    'Video Training',
    'Questionnaire',
    'Upload Photos',
    'Confirmation',
  ];
}

function getStepContent(stepIndex, loading, questions) {
  if (loading) {
    return <CircularProgress size={24} />;
  }
  switch (stepIndex) {
    case 0:
      return <Consent key="Consent" />;
    case 1:
      return <VideoTraining key="VideoTraining" />;
    case 2:
      return <Questionaire key="Questionnaire" questions={questions} />;
    case 3:
      return <UploadPhotos key="uploadPhotos" />;
    case 4:
      return <Confirm key="confirmation" />;
    default:
      return 'Unknown stepIndex';
  }
}

export default function AssessmentStepper({ Callback }) {
  const classes = useStyles();
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [activeStep, setActiveStep] = React.useState(0);
  const [context, setContext] = useState(stepsInfo);
  const [questions, setQuestions] = useState(null);
  const steps = getSteps();
  const CheckValid = () => {
    var numAnswers = 0;
    var valid = true;
    var error = null;
    if (activeStep === 0) {
      valid = context[0].consent !== '';

      error = 'Please select one of the options for consent';
    }
    if (activeStep === 1) {
      valid = context[1].confirmVideo === true;

      error = 'Please confirm that you have watched the training video';
    }
    if (activeStep === 2) {
      let requiredQuestions = questions.filter(
        (item) => item.isRequired === true
      );
      requiredQuestions.forEach((elm) => {
        context[2].questionaire.forEach((quest) => {
          if (elm.name === quest.key) {
            numAnswers++;
          }
        });
      });

      valid = requiredQuestions.length === numAnswers;
      error = 'Please answer all the questions';
    }
    if (activeStep === 3) {
      var frontValid = context[3].FrontPhoto !== null;
      var sideValid = context[3].SidePhoto !== null;
      valid = frontValid && sideValid;

      if (!sideValid) {
        error = 'Please upload a side image of your desk';
      }
      if (!frontValid) {
        error = 'Please upload a front image of your desk';
      }
    }
    if (!valid) {
      setErrorMessage(error);
    }

    return valid;
  };

  React.useEffect(() => {
    getActiveAssessment().then((response) => {
      var data = response.data;
      var _consent = '';
      var _video = false;
      var _activeStep = 0;

      var _canContinue = getCurrentUser().continue !== true;

      stepsInfo[3].canContinue = _canContinue;
      if (data.questions !== null) {
        try {
          setQuestions(JSON.parse(data.questions));
        } catch (error) {
          console.log(error);
        }
      }

      if (data.terms !== null) {
        _consent = data.terms === '1' || data.terms === 1 ? true : false;
        stepsInfo[0].consent = _consent;
        _activeStep = 1;
      } else {
        //default value
        stepsInfo[0].consent = true;
      }
      if (data.training_video !== null) {
        _video = data.training_video === 1 ? true : false;
        stepsInfo[1].confirmVideo = _video;
        if (_video) {
          _activeStep = 2;
        }
      }

      var _questionaire = [];
      if (data.step2 !== null) {
        var jsonStep2 = JSON.parse(data.step2)[0];
        _questionaire = jsonStep2;
        stepsInfo[2].questionaire = _questionaire;
        _activeStep = 3;
      }

      setContext(stepsInfo);
      setActiveStep(_activeStep);
      setLoading(false);
    });
  }, []);
  const handleNext = () => {
    setErrorMessage(null);
    if (CheckValid()) {
      setLoading(true);
      //save
      if (
        activeStep === 0 ||
        activeStep === 1 ||
        activeStep === 2 ||
        activeStep === 4
      ) {
        addAssessment(stepsInfo[activeStep])
          .then(() => {
            Callback(activeStep + 1);
            setActiveStep(activeStep + 1);
            setLoading(false);
          })
          .catch(() => {
            //error happened
          });
      }

      if (activeStep === 3) {
        addAssessmentPhotos(
          stepsInfo[activeStep].FrontPhoto,
          stepsInfo[activeStep].SidePhoto,
          stepsInfo[activeStep].step
        )
          .then(() => {
            setActiveStep(activeStep + 1);
            setLoading(false);

            window.scrollTo(0, 0);
          })
          .catch((error) => {
            console.error(error);
            setLoading(false);
            setErrorMessage(
              <>
                Error has occoured while saving your photos. Please try again
                later or contact{' '}
                <a href="mailto:support@capella.ie">support@capella.ie</a>
              </>
            );
          });
      }
    }
  };

  const handleBack = () => {
    setErrorMessage(null);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    Callback(activeStep - 1);
  };

  return (
    <div className={classes.root}>
      <Stepper
        activeStep={activeStep}
        className={classes.customStepper}
        alternativeLabel
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div>
            <Confirmation />
          </div>
        ) : (
          <GridContainer justifyContent="center">
            <GridItem xs={12} sm={12} md={12}>
              <StepsContext.Provider value={[context, setContext, questions]}>
                {getStepContent(activeStep, loading, questions)}
              </StepsContext.Provider>
            </GridItem>

            <GridItem
              xs={12}
              sm={12}
              md={12}
              style={{ textAlign: 'center', marginBottom: '20px' }}
            >
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                className={classes.backButton}
                variant="contained"
              >
                Back
              </Button>
              <Button color="primary" onClick={handleNext} variant="contained">
                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
              </Button>
              {errorMessage !== null && (
                <SnackbarContent
                  message={errorMessage}
                  close
                  color="danger"
                  icon="info_outline"
                />
              )}
            </GridItem>
          </GridContainer>
        )}
      </div>
    </div>
  );
}

AssessmentStepper.propTypes = {
  //activeStep: PropTypes.number,
  Callback: PropTypes.func,
};
