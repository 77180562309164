import React, { useState, useEffect } from 'react';
import { getAssessmentPhotos } from 'services/user';
import { withStyles } from '@material-ui/core/styles';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Loading from 'components/Loading';
import Button from 'components/CustomButtons/Button.js';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Display from './Display';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function ShowPhotosModal({ data, onClose }) {
  const [loading, setLoading] = useState(false);
  const [front, setFront] = useState(null);
  const [side, setSide] = useState(null);

  useEffect(() => {
    if (data !== null && data.show) {
      setLoading(true);
      getAssessmentPhotos(data.id).then((res) => {
        setFront(res.data.front);
        setSide(res.data.side);
        setLoading(false);
      });
    }
  }, [data, data.show, setLoading, setFront, setSide]);

  const clearAndClose = () => {
    setLoading(false);
    setFront(null);
    setSide(null);
    onClose();
  };
  // getModalStyle is not a pure function, we roll the style only on the first render

  const body =
    data.id === null ? (
      <Loading show={true} />
    ) : (
      <GridContainer style={{ width: '100%', textAlign: 'center' }}>
        <GridItem>
          <Loading show={loading} />
        </GridItem>
        <Display show={!loading} front={front} side={side} />
      </GridContainer>
    );

  return (
    <div>
      <Dialog
        onClose={clearAndClose}
        aria-labelledby="customized-dialog-title"
        open={data.show}
        fullWidth={true}
        maxWidth="xl"
      >
        <DialogTitle id="customized-dialog-title" onClose={onClose}>
          Photos
        </DialogTitle>
        <DialogContent dividers>{body}</DialogContent>
        <DialogActions>
          <Button autoFocus onClick={clearAndClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
