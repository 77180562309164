const cardStyle = {
  card: {
    border: '0',

    marginTop: '30px',
    borderRadius: '6px',
    color: 'rgba(0, 0, 0, 0.87)',
    background: '#fff',
    width: '100%',
    boxShadow:
      '0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12)',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',

    justifyContent: 'space-between',
    minWidth: '0',
    wordWrap: 'break-word',
    fontSize: '.875rem',
    transition: 'all 300ms linear',
  },
  cardPlain: {
    background: 'transparent',
    boxShadow: 'none',
  },
  cardCarousel: {
    overflow: 'hidden',
  },
  primaryCardSlide: {
    border: '3px solid #2D9CDB !important',
    '& .footer': {
      borderTop: '1px solid #2D9CDB',
    },
    '&:hover': {
      '& .footer': {
        borderTop: '1px solid #2D9CDB',
        backgroundColor: '#2D9CDB',
        color: 'white',
        '& a': {
          color: 'white',
        },
      },
    },
  },
  infoSuccessCardSlide: {
    border: '3px solid #6FCF97 !important',
    '& .footer': {
      borderTop: '1px solid #6FCF97',
    },
    '&:hover': {
      '& .footer': {
        backgroundColor: '#6FCF97',
        color: 'white',
        '& a': {
          color: 'white',
        },
      },
    },
  },
  requestedCardSlide: {
    border: '3px solid #F2C94C !important',
    '& .footer': {
      borderTop: '1px solid #F2C94C',
    },
    '&:hover': {
      '& .footer': {
        backgroundColor: '#F2C94C',
        color: 'white',
        '& a': {
          color: 'white',
        },
      },
    },
  },
  recommendedCardSlide: {
    border: '3px solid #27AE60 !important',
    '& .footer': {
      borderTop: '1px solid #27AE60',
    },
    '&:hover': {
      '& .footer': {
        backgroundColor: '#27AE60',
        color: 'white',
        '& a': {
          color: 'white',
        },
      },
    },
  },
  commentsCardSlide: {
    border: '3px solid #1E3252 !important',
    '& .footer': {
      borderTop: '1px solid #1E3252',
    },
    '&:hover': {
      '& .footer': {
        backgroundColor: '#1E3252',
        color: 'white',
        '& a': {
          color: 'white',
        },
      },
    },
  },
  alertCardSlide: {
    border: '3px solid #EB5757 !important',
    '& .footer': {
      borderTop: '1px solid #EB5757',
    },
    '&:hover': {
      '& .footer': {
        backgroundColor: '#EB5757',
        color: 'white',
        '& a': {
          color: 'white',
        },
      },
    },
  },
  warningCardSlide: {
    border: '3px solid #F2994A !important',
    '& .footer': {
      borderTop: '1px solid #F2994A',
    },
    '&:hover': {
      '& .footer': {
        backgroundColor: '#F2994A',
        color: 'white',
        '& a': {
          color: 'white',
        },
      },
    },
  },
  roseCardSlide: {
    border: '3px solid #9B51E0 !important',
    '& .footer': {
      borderTop: '1px solid #9B51E0',
    },
    '&:hover': {
      '& .footer': {
        backgroundColor: '#9B51E0',
        color: 'white',
        '& a': {
          color: 'white',
        },
      },
    },
  },
  infoCardSlide: {
    border: '3px solid #CDA96C !important',
    '&:hover': {
      '& .footer': {
        backgroundColor: '#CDA96C',
        color: 'white',
        '& a': {
          color: 'white',
        },
      },
    },
  },
};

export default cardStyle;
