import React, { useEffect, useState } from 'react';
import ShowQuestionaire from 'components/Questionaire/Modal';
import ShowPhotos from 'components/Photos/Modal';
import MUIDataTable from 'mui-datatables';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Loading from 'components/Loading';
import { getEmployees } from 'services/user';
import MonochromePhotosIcon from '@material-ui/icons/MonochromePhotos';
import ListAltIcon from '@material-ui/icons/ListAlt';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import AlertIcon from 'assets/icons/alert.png';
import ConsultationsReqIcon from 'assets/icons/consultations.png';
import ConsultationsRecIcon from 'assets/icons/assettracking.png';
import LibraryAddCheckIcon from '@material-ui/icons/LibraryAddCheck';
import AssessmentProgress from 'components/Assessment/Progress';
import EmployeeAdd from './Add';
import DatatableCustomToolbar from 'components/Editor/DatatableCustomToolbar';
import { useHistory, useLocation } from 'react-router-dom';
import { isCapellaAdmin, isReviewerAdmin } from 'services/context';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import ThumbUpDownIcon from '@material-ui/icons/ThumbsUpDown';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import moment from 'moment';
export default function EmployeesList() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const today = moment();
  const nextWeek = today.add(7, 'days');

  const [endExpected, setEndExpected] = useState(
    moment(nextWeek).format('YYYY-MM-DD').toString()
  );
  const [questionaires, setQuestionaires] = useState(null);
  //const [departments, setDepartments] = useState(null);

  const defaultPhotoData = { id: null, show: false };
  const [photosData, setPhotoData] = useState(defaultPhotoData);
  const [showQuestionaire, setShowQuestionaire] = useState(false);
  const [questionaireData, setQuestionaireData] = useState(null);
  var defaultData = {
    file: null,
    email: '',
    show: false,
  };
  const [employeeAddData, setEmployeeAddData] = useState(defaultData);

  const defaultOptions = {
    searchActivated: [],
    searchHighRisk: [],
    searchConsultationRec: [],
    searchConsultationReq: [],
    searchComments: [],
    searchConsultationClosed: [],
  };
  const [searchOptions, setSearchOptions] = useState(defaultOptions);

  const location = useLocation();

  const getSearch = (value, key) => {
    if (value !== null) {
      return value === 'true' ? ['Yes'] : ['No'];
    }
    return [];
  };

  const columns = [
    {
      label: 'ID',
      name: 'id',
      options: {
        filter: false,
        print: false,
        download: false,
        display: false,
      },
    },
    {
      label: 'First Name',
      name: 'first_name',
      options: {
        filter: false,
      },
    },
    {
      label: 'Last Name',
      name: 'last_name',
      options: {
        filter: false,
      },
    },
    {
      label: ' ',
      name: 'id',
      options: {
        filter: false,
        download: false,
        display: true,
        setCellProps: () => ({ style: { minWidth: '10px', maxWidth: '20px' } }),
        customBodyRender: (value, tableMeta, updateValue) => {
          if (value != null) {
            // tableMeta contains all the info that is needed
            return getIcons(value);
          }
        },
      },
    },
    {
      label: 'Email',
      name: 'email',
      options: {
        filter: false,
      },
    },
    {
      label: 'Department',
      name: 'department',
      options: {
        filter: true,
        display: false,
      },
    },
    {
      label: 'Job Title',
      name: 'job_title',
      options: {
        display: false,
      },
    },

    {
      label: 'Closed Date',
      name: 'closed_at',
      options: {
        filter: false,
      },
    },
    {
      label: 'Photos',
      name: 'FrontImage_filename',
      options: {
        filter: false,
        display: true,
        print: false,
        download: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          if (value != null) {
            // tableMeta contains all the info that is needed
            return (
              <MonochromePhotosIcon
                fontSize="large"
                onClick={(e) => displayPhotos(tableMeta.rowData[0])}
              />
            );
          }
        },
      },
    },

    {
      label: 'Self Assessment',
      name: 'step_2',
      options: {
        filter: false,
        display: true,
        print: false,
        download: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          if (value != null) {
            // tableMeta contains all the info that is needed

            // tableMeta contains all the info that is needed
            return (
              <ListAltIcon
                fontSize="large"
                onClick={(e) =>
                  displayQuestionaire(tableMeta.rowData[0], value)
                }
              />
            );
          }
        },
      },
    },
    {
      label: 'Analysis',
      name: 'reviewed',
      options: {
        filter: false,
        display: true,
        print: false,
        download: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          var closed =
            tableMeta.rowData[16] === 1 || tableMeta.rowData[16] === '1';
          var autoAnalized =
            tableMeta.rowData[18] === 1 || tableMeta.rowData[18] === '1';
          var analized = value != null && value !== '';
          var reviewed = value === '1' || value === 1;
          var tooltip = 'Not yet reviewed';
          if (
            autoAnalized &&
            (reviewed || (closed && (isCapellaAdmin() || isReviewerAdmin())))
          ) {
            var color = '#ccc';

            if (value === '0' || value === 0) {
              color = 'black';
              tooltip = '1st Review';
            }

            if (reviewed) {
              color = 'green';
              tooltip = '2nd Review';
            }

            if (reviewed) {
              return (
                <>
                  {analized && (
                    <Tooltip title="Assessment Reviewed">
                      <LibraryAddCheckIcon
                        fontSize="large"
                        style={{ color: color }}
                        onClick={(e) =>
                          history.push('analysis/' + tableMeta.rowData[0])
                        }
                      />
                    </Tooltip>
                  )}
                  {/* <Tooltip title="See Individual Report">
                    <IconButton
                      fontSize="large"
                      aria-label="See Individual Report"
                      onClick={(e) =>
                        history.push('analysis/' + tableMeta.rowData[0])
                      }
                    >
                      <ArrowForwardIosIcon />
                    </IconButton>
                  </Tooltip> */}
                </>
              );
            }

            return (
              <>
                <Tooltip title={tooltip}>
                  <LibraryAddCheckIcon
                    fontSize="large"
                    style={{ color: color }}
                    onClick={(e) =>
                      history.push('analysis/' + tableMeta.rowData[0])
                    }
                  />
                </Tooltip>
                <Tooltip title="See Individual Report">
                  <IconButton
                    aria-label="See Individual Report"
                    fontSize="large"
                    tool
                    onClick={(e) =>
                      history.push('analysis/' + tableMeta.rowData[0])
                    }
                  >
                    <ArrowForwardIosIcon />
                  </IconButton>
                </Tooltip>
              </>
            );
          }
        },
      },
    },

    {
      label: 'Completed',
      name: 'id',
      options: {
        filter: false,
        display: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return getCompleted(value);
        },
      },
    },
    {
      label: 'HighRisk',
      name: 'high_risk',
      options: {
        display: false,
        print: false,
        download: false,
        filterList: searchOptions.searchHighRisk,
        customBodyRender: (value, tableMeta, updateValue) => {
          if (value === '' || value === null) {
            return 'N/A';
          }
          return value === 1 || value === '1' ? 'Yes' : 'No';
        },
      },
    },
    {
      label: 'Comments',
      name: 'step_2',
      options: {
        display: false,
        print: false,
        download: false,
        filterList: searchOptions.searchComments,
        customBodyRender: (value, tableMeta, updateValue) => {
          var element =
            value !== null &&
            JSON.parse(value)[0] !== null &&
            JSON.parse(value)[0].filter((l) => {
              return l.key === 'Further-Information';
            });

          return element !== null && element.length > 0 ? 'Yes' : 'No';
        },
      },
    },
    {
      label: 'Consultation Requested',
      name: 'step_2',
      options: {
        display: false,
        print: false,
        download: false,
        filterList: searchOptions.searchConsultationReq,
        customBodyRender: (value, tableMeta, updateValue) => {
          var element =
            value !== null &&
            JSON.parse(value)[0] !== null &&
            JSON.parse(value)[0].filter((l) => {
              return l.key === 'Concerns-Setup';
            });

          if (element !== null && element.length > 0) {
            return element[0].value === 'yes' ? 'Yes' : 'No';
          }
          return 'N/A';
        },
      },
    },
    {
      label: 'Consultation Recommended',
      name: 'consultation_recomended',
      options: {
        display: false,
        print: false,
        download: false,
        filterList: searchOptions.searchConsultationRec,
        customBodyRender: (value, tableMeta, updateValue) => {
          if (value === '' || value === null) {
            return 'N/A';
          }
          return value === 1 || value === '1' ? 'Yes' : 'No';
        },
      },
    },
    {
      label: 'Closed',
      name: 'is_closed',
      options: {
        display: false,
        print: false,
        download: false,
        filterList: searchOptions.searchConsultationClosed,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value === 1 || value === '1' ? 'Yes' : 'No';
        },
      },
    },
    {
      label: 'Activated',
      name: 'first_name',
      options: {
        display: false,
        print: false,
        download: false,
        filterList: searchOptions.searchActivated,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value !== null ? 'Yes' : 'No';
        },
      },
    },
    {
      label: 'autoanalized',
      name: 'auto_analized',
      options: {
        display: false,
        filter: false,
        print: false,
        download: false,
      },
    },
  ];

  const history = useHistory();
  const options = {
    selectableRows: 'none',
    filterType: 'checkbox',
    rowsPerPage: 100,
    setFilterChipProps: (colIndex, colName, data) => {
      return {
        color: 'primary',
        variant: 'outlined',
        className: 'testClass123',
      };
    },
    customToolbar: () => {
      if (!isCapellaAdmin()) {
        return <></>;
      }
      return (
        <DatatableCustomToolbar
          onClick={() => {
            setEmployeeAddData({
              file: null,
              email: '',
              show: true,
            });
          }}
        />
      );
    },
  };
  const IconHighRisk = ({ recordData }) => {
    if (recordData.high_risk === 1 || recordData.high_risk === '1') {
      return (
        <Tooltip title="High Risk">
          <IconButton>
            <img style={{ width: '20px' }} src={AlertIcon} alt="High Risk" />
          </IconButton>
        </Tooltip>
      );
    }
    return <></>;
  };
  const IconRequested = ({ recordData }) => {
    if (
      recordData.consultation_recomended === 1 ||
      recordData.consultation_recomended === '1'
    ) {
      return (
        <Tooltip title="Consultation Recommended">
          <IconButton>
            <img
              style={{ width: '20px' }}
              src={ConsultationsRecIcon}
              alt="Consultation Recommended"
            />
          </IconButton>
        </Tooltip>
      );
    }
    return <></>;
  };
  const IconRecommended = ({ recordData }) => {
    if (recordData.step_2 !== null) {
      let questionaire = JSON.parse(recordData.step_2);
      if (
        questionaire != null &&
        questionaire.length > 0 &&
        questionaire[0] !== null
      ) {
        let consReqVal = questionaire[0].filter((l) => {
          return l.key === 'Concerns-Setup';
        });
        if (consReqVal[0].value === 'yes') {
          return (
            <Tooltip title="Consultation Requested">
              <IconButton>
                <img
                  style={{ width: '20px' }}
                  src={ConsultationsReqIcon}
                  alt="Consultation Requested"
                />
              </IconButton>
            </Tooltip>
          );
        }
      }
    }
    return <></>;
  };
  const IconSentiment = ({ recordData }) => {
    if (recordData.step_2 !== null && recordData.sentiment_comment !== null) {
      var icon = <></>;
      switch (recordData.sentiment_comment) {
        case 'NEGATIVE':
          icon = <ThumbDownIcon />;
          break;
        case 'POSITIVE':
          icon = <ThumbUpIcon />;
          break;
        case 'NEUTRAL':
          icon = <ThumbUpDownIcon />;
          break;
        default:
          break;
      }

      return (
        <Tooltip title={'Comment Sentiment ' + recordData.sentiment_comment}>
          <IconButton>{icon}</IconButton>
        </Tooltip>
      );
    }
    return <></>;
  };
  const getIcons = (id) => {
    var recordData = data.filter((x) => x.id === id)[0];
    return (
      <GridContainer>
        <GridItem md={12}>
          <IconHighRisk recordData={recordData} />
        </GridItem>
        <GridItem md={12}>
          <IconRecommended recordData={recordData} />
        </GridItem>
        <GridItem md={12}>
          <IconRequested recordData={recordData} />
        </GridItem>
        <GridItem md={12}>
          <IconSentiment recordData={recordData} />
        </GridItem>
      </GridContainer>
    );
  };

  const getCompleted = (id) => {
    var recordData = data.filter((x) => x.id === id)[0];

    var step = 0;
    if (recordData.terms_conditions !== null) {
      step += 1;
    }
    if (recordData.training_video !== null) {
      step += 1;
    }
    if (recordData.step_2 !== null) {
      step += 1;
    }
    if (
      recordData.FrontImage_filename !== null &&
      recordData.SideImage_filename !== null
    ) {
      step += 1;
    }
    if (recordData.is_closed === 1 || recordData.is_closed === '1') {
      step += 1;
    }

    return (
      <span>
        <AssessmentProgress currentProgress={step} />
      </span>
    );
  };
  const displayQuestionaire = (id, step_2) => {
    if (id === null) {
      setQuestionaireData(null);
      setShowQuestionaire(false);
    } else {
      try {
        var recordData = data.filter((x) => x.id === id)[0];

        var questionaireData = questionaires.filter(
          (item) => item.id == recordData.questionaire_id
        )[0].questions;

        var _questions = JSON.parse(questionaireData);
        var _selfassessment = JSON.parse(step_2)[0];
        setQuestionaireData({
          questionaire: _selfassessment,
          questions: _questions,
        });
        setShowQuestionaire(true);
      } catch (e) {
        //console.log('error', e);
        setShowQuestionaire(false);
      }
    }
  };
  const displayPhotos = (id) => {
    if (id === null) {
      setPhotoData(defaultPhotoData);
    } else {
      setPhotoData({
        id: id,
        show: true,
      });
    }
  };
  const RefreshDataCloseModal = () => {
    LoadData();
    setEmployeeAddData(defaultData);
  };
  const SetFormatedDate = (dt) => {
    if (dt != null) {
      setEndExpected(moment(dt).format('YYYY-MM-DD').toString());
    }
  };
  const LoadData = () => {
    getEmployees().then((res) => {
      var _data = res.data.employees;
      var _questionaires = res.data.questionaires;

      SetFormatedDate(res.data.defaultEndExpected);

      if (_data == null || _data.length === 0) {
        _data = [];
      }

      // var _departments = [];
      // _data.forEach((element) => {
      //   if (element.department !== null && element.department !== '') {
      //     _departments.push(element.department);
      //   }
      // });
      // const uniqueDepts = Array.from(new Set(_departments));
      setData(_data);

      setQuestionaires(_questionaires);
      //setDepartments(uniqueDepts);

      setLoading(false);
    });
  };
  useEffect(() => {
    var params = new URLSearchParams(location.search);
    var activated = params.get('activated');
    var highrisk = params.get('highrisk');
    var consultationrequested = params.get('consultationrequested');
    var consultationrecommended = params.get('consultationrecommended');
    var comments = params.get('comments');
    var closed = params.get('closed');

    const searchOptions = {
      searchActivated: getSearch(activated, 'activated'),
      searchHighRisk: getSearch(highrisk, 'highrisk'),
      searchConsultationRec: getSearch(
        consultationrecommended,
        'consultationrecommended'
      ),
      searchConsultationReq: getSearch(
        consultationrequested,
        'consultationrequested'
      ),
      searchComments: getSearch(comments, 'comments'),
      searchConsultationClosed: getSearch(closed, 'closed'),
    };
    setSearchOptions(searchOptions);

    LoadData();
  }, [location]);
  return (
    <>
      <Loading show={loading} />
      {!loading && (
        <>
          <MUIDataTable
            title="Assessments List"
            data={data}
            columns={columns}
            options={options}
          />
        </>
      )}
      <EmployeeAdd
        callBack={RefreshDataCloseModal}
        defaultEndExpected={endExpected}
        data={employeeAddData}
        cancelCallback={() => RefreshDataCloseModal}
      />
      <ShowPhotos onClose={() => displayPhotos(null)} data={photosData} />
      <ShowQuestionaire
        show={showQuestionaire}
        onClose={() => displayQuestionaire(null)}
        data={questionaireData}
      />
    </>
  );
}
