import React, { useEffect } from 'react';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
// @material-ui/icons
import SnackbarContent from 'components/Snackbar/SnackbarContent.js';
import Link from '@material-ui/core/Link';
import variables from 'services/variables';
// core components
import Header from 'components/Header/Header.js';
import Footer from 'components/Footer/Footer.js';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import PrimaryTypo from 'components/Typography/Primary';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';
import styles from 'assets/jss/material-kit-react/views/loginPage.js';
import { Redirect } from 'react-router-dom';
import image from 'assets/img/bg7.jpg';
import { useHistory } from 'react-router-dom';
const useStyles = makeStyles(styles);

export default function SSOPage(props) {
  const history = useHistory();
  const [cardAnimaton, setCardAnimation] = React.useState('cardHidden');

  setTimeout(function () {
    setCardAnimation('');
  }, 700);
  const classes = useStyles();
  const { ...rest } = props;

  return (
    <div>
      <Header absolute color="transparent" brand="Domus" {...rest} />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: 'url(' + image + ')',
          backgroundSize: 'cover',
          backgroundPosition: 'top center',
        }}
      >
        <div className={classes.container}>
          <GridContainer justifyContent="center">
            <GridItem xs={12} sm={12} md={4}>
              <Card className={classes[cardAnimaton]}>
                <form className={classes.form}>
                  <CardHeader color="primary" className={classes.cardHeader}>
                    <h4>Login</h4>
                  </CardHeader>
                  <CardBody align="center">
                    <br />
                    <br />
                    <PrimaryTypo>
                      Welcome to DOMUS.
                      <br />
                      Here to support you while you work from home
                    </PrimaryTypo>
                    <br />
                    <br />
                    <Link
                      color="primary"
                      href={variables.api.url + 'sso/'}
                      className={classes.link}
                    >
                      Single Sign On
                    </Link>
                    <br />
                    <br />
                    <br />
                    <br />
                  </CardBody>
                </form>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
        <Footer whiteFont />
      </div>
    </div>
  );
}
