import React, { useState, useEffect } from 'react';
import Question from './Question';
import Answer from './Answer';
import PropTypes from 'prop-types';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { isArray } from 'services/variables';

export default function QuestionAnswer(props) {
  const [showOptions, setShowOptions] = useState();
  let elm;

  const showDetails = (event) => {
    const { name, value } = event.target;
    setShowOptions(value === 'yes');
    props.handleChange(event);

    //console.log('clear show details', value);
    //clear
    props.handleChangeArray(name, value, []);
  };

  useEffect(() => {
    // code to run on component mount

    if (props.options != null && isArray(props.options)) {
      setShowOptions(props.value === 'yes');
    }
  }, [props.value, props.options]);

  if (props.type === 'openquestion') {
    elm = (
      <>
        <TableRow>
          <TableCell colSpan={2}>
            <Answer
              handleChange={props.handleChange}
              type={props.type}
              name={props.name}
              question={props.title}
              options={props.options}
              value={props.value}
            />
          </TableCell>
        </TableRow>
      </>
    );
  } else if (props.type === 'multipleoptions') {
    elm = (
      <>
        <TableRow>
          <TableCell>
            <Question
              name={props.name}
              question={props.title}
              tooltip={props.tooltip}
              isRequired={props.isRequired}
            />
          </TableCell>
          <TableCell>
            <Answer
              handleChange={showDetails}
              type={'yesno'}
              name={props.name}
              question={props.title}
              value={props.value}
            />
          </TableCell>
        </TableRow>
        {showOptions === true && (
          <TableRow>
            <TableCell colSpan={2}>
              <Answer
                handleChangeArray={props.handleChangeArray}
                type={props.type}
                name={props.name}
                question={props.title}
                options={props.options}
                value={props.value}
                valueOptions={props.valueOptions}
              />
            </TableCell>
          </TableRow>
        )}
      </>
    );
  } else {
    elm = (
      <>
        <TableRow>
          <TableCell>
            <Question
              name={props.name}
              question={props.title}
              tooltip={props.tooltip}
              isRequired={props.isRequired}
            />
          </TableCell>
          <TableCell>
            <Answer
              handleChange={props.handleChange}
              type={props.type}
              name={props.name}
              question={props.title}
              options={props.options}
              value={props.value}
            />
          </TableCell>
        </TableRow>
      </>
    );
  }

  return <>{elm}</>;
}
QuestionAnswer.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  title: PropTypes.string,
  isRequired: PropTypes.bool,
  handleChange: PropTypes.func,
  handleChangeArray: PropTypes.func,
  tooltip: PropTypes.string,
  value: PropTypes.string,
  valueOptions: PropTypes.array || null,
  options: PropTypes.array || null,
};
