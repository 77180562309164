import React from 'react';
import PropTypes from 'prop-types';

import List from 'components/Reminders/History';
import Layout from 'components/Layout/Layout';
import { Email } from '@material-ui/icons';
export default function RemindersHistoryPage(props) {
  const { window } = props;

  return (
    <Layout window={window} title="Reminders History" icon={<Email />}>
      <List />
    </Layout>
  );
}

RemindersHistoryPage.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};
