import React from 'react';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
// import MeasurementApp from 'components/Measurement/MeasurementApp';

export default function ShowPhotosModal({
  show,
  front,
  side,
  showMeasure = false,
}) {
  return (
    show && (
      <GridContainer>
        <GridItem md={6}>
          {!showMeasure && (
            <img
              className="MuiGrid-grid-md-12 smallerImages"
              src={`data:image/png;base64,${front}`}
              alt="Front Desk"
            />
          )}

          {/* {showMeasure && <MeasurementApp image={front} />} */}
        </GridItem>
        <GridItem md={6}>
          {!showMeasure && (
            <img
              className="MuiGrid-grid-md-12 smallerImages"
              src={`data:image/png;base64,${side}`}
              alt="Side Desk"
            />
          )}
          {/* {showMeasure && <MeasurementApp image={side} />} */}
        </GridItem>
      </GridContainer>
    )
  );
}
