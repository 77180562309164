/*eslint-disable*/
import React, { useState } from 'react';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// @material-ui/icons
import Face from '@material-ui/icons/Face';
import Chat from '@material-ui/icons/Chat';
import Risk from '@material-ui/icons/AddAlert';
import Build from '@material-ui/icons/Build';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
// nodejs library that concatenates classes
import CustomTabs from 'components/CustomTabs/CustomTabs.js';
import { makeStyles } from '@material-ui/core/styles';
import Button from 'components/CustomButtons/Button.js';
import styles from 'assets/jss/material-kit-react/views/componentsSections/tabsStyle.js';
import HazardsApproval from './HazardsValidate';
import PhotosValidate from './PhotosValidate';
import EquipmentApproval from './EquipmentValidate';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import { UpdateReview, getAssessmentRecommendation } from 'services/user';

import AutorenewIcon from '@material-ui/icons/Autorenew';
const useStyles = makeStyles(styles);
export default function ApprovalPlugin({ show, callback, data, hazards }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleFixedClick = () => {
    setOpen(!open);
  };

  const handleClick = () => {
    handleFixedClick();
  };

  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);
  const [highRisk, setHighRisk] = useState(data.high_risk);
  const [consultationRecomended, setConsultationRecomended] = useState(
    data.consultation_recomended
  );
  const [reviewed, setReviewed] = useState(data.reviewed);
  const [manualRecommendation, setManualRecommendation] = useState(
    data.recomendation
  );
  const [automatedRecommendation, setAutomatedRecommendation] = useState(
    data.autorecomendation
  );
  const getAutomatedInfo = () => {
    setLoading(true);
    setSaving(true);
    getAssessmentRecommendation(data.assessmentid).then((res) => {
      setAutomatedRecommendation(res.data);
      setLoading(false);
      setSaving(false);
    });
  };

  const photosCallback = () => {
    //console.log('photosCallback');
    setSaving(true);

    getAssessmentRecommendation(data.assessmentid).then((res) => {
      setAutomatedRecommendation(res.data);
      var body = {
        assessmentid: data.assessmentid,
        auto_recomendation: res.data,
        recomendation: manualRecommendation,
        highRisk: highRisk,
        consultation_recomended: consultationRecomended,
        reviewed: reviewed,
      };

      UpdateReview(data.assessmentid, body).then(() => {
        setSaving(false);
        callback();
      });
    });
  };

  const save = () => {
    // var body = {
    //   assessmentid: data.id,
    //   equipment: equipment,
    //   priority: priority,
    // };
    setSaving(true);
    var body = {
      assessmentid: data.assessmentid,
      auto_recomendation: automatedRecommendation,
      recomendation: manualRecommendation,
      highRisk: highRisk,
      consultation_recomended: consultationRecomended,
      reviewed: reviewed,
    };

    UpdateReview(data.assessmentid, body).then(() => {
      setSaving(false);
      callback();
    });
  };

  if (!show) {
    return <></>;
  }
  return (
    <div className="fixed-plugin">
      <div
        id="fixedPluginClasses"
        className={open ? 'dropdown show' : 'dropdown'}
      >
        <div onClick={handleClick}>
          <i className="fa fa-cog fa-2x" />
        </div>
        <GridContainer
          className="dropdown-menu"
          style={{ backgroundColor: '#fafafa' }}
        >
          <GridItem>
            <CustomTabs
              headerColor="primary"
              tabs={[
                {
                  tabName: 'Photos',
                  tabIcon: Face,
                  tabContent: (
                    <PhotosValidate data={data} callback={photosCallback} />
                  ),
                },
                {
                  tabName: 'Hazards',
                  tabIcon: Risk,
                  tabContent: (
                    <HazardsApproval data={data} callback={callback} />
                  ),
                },
                {
                  tabName: 'Equipments',
                  tabIcon: Build,
                  tabContent: (
                    <EquipmentApproval data={data} callback={callback} />
                  ),
                },
                {
                  tabName: 'Recommendations',
                  tabIcon: Chat,
                  tabContent: (
                    <GridContainer
                      style={{ paddingTop: '10px', paddingLeft: '10px' }}
                    >
                      <GridItem xs={4} sm={4} md={4}>
                        <InputLabel id="demo-simple-select-helper-label">
                          Consultation Recommended
                        </InputLabel>
                        <Select
                          className={classes.formControl}
                          id="consultationRecomended"
                          label="Consultation Recommended"
                          minWidth="150"
                          fullWidth
                          value={consultationRecomended}
                          onChange={(e) => {
                            setConsultationRecomended(e.target.value);
                            save();
                            getAutomatedInfo();
                            save();
                          }}
                          helperText="Please select if you recommend a consultation"
                        >
                          <MenuItem key="na" value="">
                            N/A
                          </MenuItem>
                          <MenuItem key="yes" value="1">
                            Yes
                          </MenuItem>
                          <MenuItem key="no" value="0">
                            No
                          </MenuItem>
                        </Select>
                      </GridItem>
                      <GridItem xs={3} sm={3} md={3}>
                        <InputLabel id="demo-simple-select-helper-label">
                          High Risk
                        </InputLabel>
                        <Select
                          className={classes.formControl}
                          id="highRisk"
                          label="High Risk"
                          minWidth="150"
                          fullWidth
                          value={highRisk}
                          onChange={(e) => {
                            setHighRisk(e.target.value);
                            save();
                            getAutomatedInfo();
                            save();
                          }}
                          helperText="Please select if this is a high risk case"
                        >
                          <MenuItem key="na" value="">
                            N/A
                          </MenuItem>
                          <MenuItem key="yes" value="1">
                            Yes
                          </MenuItem>
                          <MenuItem key="No" value="0">
                            No
                          </MenuItem>
                        </Select>
                      </GridItem>
                      <GridItem xs={3} sm={3} md={3}>
                        <InputLabel id="demo-simple-select-helper-label">
                          Reviewed
                        </InputLabel>
                        <Select
                          className={classes.formControl}
                          id="reviewed"
                          label="Reviewed"
                          minWidth="150"
                          fullWidth
                          value={reviewed}
                          onChange={(e) => setReviewed(e.target.value)}
                          helperText="Please select if it was reviewed / pair reviewed"
                        >
                          <MenuItem key="na" value="">
                            Not Reviewed
                          </MenuItem>
                          <MenuItem key="yes" value="1">
                            2nd Review
                          </MenuItem>
                          <MenuItem key="No" value="0">
                            1st Review
                          </MenuItem>
                        </Select>
                      </GridItem>
                      <GridItem xs={10} sm={10} md={10}>
                        <TextField
                          id="automatedRecommendation"
                          label="Automated Recommendation"
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          multiline
                          value={automatedRecommendation}
                          onChange={(e) => {
                            setAutomatedRecommendation(e.target.value);
                          }}
                        />
                      </GridItem>
                      <GridItem xs={2} sm={2} md={2}>
                        <Button
                          variant="outlined"
                          loading={loading}
                          color="secundary"
                          onClick={getAutomatedInfo}
                        >
                          <AutorenewIcon />
                        </Button>
                      </GridItem>
                      <GridItem md={11}>
                        <TextField
                          id="manualRecommendation"
                          label="Extra Recommendation"
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          multiline
                          value={manualRecommendation}
                          onChange={(e) =>
                            setManualRecommendation(e.target.value)
                          }
                        />
                      </GridItem>

                      <GridItem md={12}>
                        <Button
                          variant="outlined"
                          loading={saving}
                          color="primary"
                          onClick={save}
                        >
                          Save
                        </Button>
                      </GridItem>
                    </GridContainer>
                  ),
                },
              ]}
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}

ApprovalPlugin.propTypes = {
  show: PropTypes.bool,
  photos: PropTypes.object,
  callback: PropTypes.func,
};
