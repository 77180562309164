import React, { useContext, useState } from 'react';
import { StepsContext } from './Context';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import FiberManualRecord from '@material-ui/icons/FiberManualRecord';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  checkRoot: {
    padding: '12px',
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  radioRoot: {
    padding: '12px',
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  labelRoot: {
    marginLeft: '-14px',
  },
  checkboxAndRadio: {
    position: 'relative',
    display: 'block',
    marginTop: '10px',
    marginBottom: '10px',
  },
  checkboxAndRadioHorizontal: {
    position: 'relative',
    display: 'block',
    '&:first-child': {
      marginTop: '10px',
    },
    '&:not(:first-child)': {
      marginTop: '-14px',
    },
    marginTop: '0',
    marginBottom: '0',
  },
  checked: {
    color: '#C2A55C !important',
  },
  checkedIcon: {
    width: '20px',
    height: '20px',
    border: '1px solid rgba(0, 0, 0, .54)',
    borderRadius: '3px',
  },
  uncheckedIcon: {
    width: '0px',
    height: '0px',
    padding: '9px',
    border: '1px solid rgba(0, 0, 0, .54)',
    borderRadius: '3px',
  },
  disabledCheckboxAndRadio: {
    opacity: '0.45',
  },
  label: {
    cursor: 'pointer',
    paddingLeft: '0',
    color: '#000',
    fontSize: '14px',
    lineHeight: '1.428571429',
    fontWeight: '400',
    display: 'inline-flex',
    transition: '0.3s ease all',
    letterSpacing: 'unset',
  },
  labelHorizontal: {
    color: 'rgba(0, 0, 0, 0.26)',
    cursor: 'pointer',
    display: 'inline-flex',
    fontSize: '14px',
    lineHeight: '1.428571429',
    fontWeight: '400',
    paddingTop: '39px',
    marginRight: '0',
    '@media (min-width: 992px)': {
      float: 'right',
    },
  },
  labelHorizontalRadioCheckbox: {
    paddingTop: '22px',
  },
  labelLeftHorizontal: {
    color: 'rgba(0, 0, 0, 0.26)',
    cursor: 'pointer',
    display: 'inline-flex',
    fontSize: '14px',
    lineHeight: '1.428571429',
    fontWeight: '400',
    paddingTop: '22px',
    marginRight: '0',
  },
  radio: {
    color: '#C2A55C !important',
  },
  radioChecked: {
    width: '16px',
    height: '16px',
    border: '1px solid #C2A55C',
    borderRadius: '50%',
  },
  radioUnchecked: {
    width: '0px',
    height: '0px',
    padding: '7px',
    border: '1px solid rgba(0, 0, 0, .54)',
    borderRadius: '50%',
  },
  inlineChecks: {
    marginTop: '8px',
  },
  iconCheckbox: {
    height: '116px',
    width: '116px',
    color: '#C2A55C',
    '& > span:first-child': {
      borderWidth: '4px',
      borderStyle: 'solid',
      borderColor: '#CCCCCC',
      textAlign: 'center',
      verticalAlign: 'middle',
      borderRadius: '50%',
      color: 'inherit',
      margin: '0 auto 20px',
      transition: 'all 0.2s',
    },
    '&:hover': {
      color: '#9D813B',
      '& > span:first-child': {
        borderColor: '#9D813B',
      },
    },
  },
  iconCheckboxChecked: {
    color: '#9D813B',
    '& > span:first-child': {
      borderColor: '#9D813B',
    },
  },
  iconCheckboxIcon: {
    fontSize: '40px',
    lineHeight: '111px',
  },
  switchBase: {
    color: '#C2A55C !important',
  },
  switchIcon: {
    boxShadow: '0 1px 3px 1px rgba(0, 0, 0, 0.4)',
    color: '#FFFFFF !important',
    border: '1px solid rgba(0, 0, 0, .54)',
  },
  switchBar: {
    width: '30px',
    height: '15px',
    backgroundColor: 'rgb(80, 80, 80)',
    borderRadius: '15px',
    opacity: '0.7!important',
  },
  switchChecked: {
    '& + $switchBar': {
      backgroundColor: 'rgba(156, 39, 176, 1) !important',
    },
    '& $switchIcon': {
      borderColor: '#9c27b0',
    },
  },
  switchRoot: {
    height: '48px',
  },
}));
export default function Consent() {
  const [context, setContext] = useContext(StepsContext);
  const [consent, setConsent] = useState(context[0].consent);
  const classes = useStyles();
  const handleSave = (consentSelected) => {
    var newContext = context;
    newContext[0].consent = consentSelected;
    setConsent(consentSelected);
    setContext(newContext);
  };
  return (
    <GridContainer justifyContent="center">
      <GridItem
        xs={12}
        sm={12}
        md={12}
        style={{ marginBottom: '20px', textAlign: 'center' }}
      >
        <h2>Consent</h2>
        <p>
          This portal has been developed in order to help your employer ensure
          that your working environment at home is safe, and to assist you in
          correctly setting up your home work space.
        </p>
        <p>
          An important part of working from home is ensuring your safety. If you
          have any concerns about your working environment at home, you can
          report them in your self-assessment.
        </p>
        <p style={{ paddingTop: '20px' }}>
          I consent to taking part in this home working survey and authorise
          Capella Workplace Solutions to share the information I submit with my
          employer.
        </p>
        <br />
        <div
          className={
            classes.checkboxAndRadio + ' ' + classes.checkboxAndRadioHorizontal
          }
        >
          <FormControlLabel
            control={
              <Radio
                checked={consent === true}
                onChange={() => handleSave(true)}
                name="radio button enabled"
                aria-label="Yes"
                icon={<FiberManualRecord className={classes.radioUnchecked} />}
                checkedIcon={
                  <FiberManualRecord className={classes.radioChecked} />
                }
                classes={{
                  checked: classes.radio,
                  root: classes.radioRoot,
                }}
              />
            }
            classes={{
              label: classes.label,
              root: classes.labelRoot,
            }}
            label="Yes"
          />
          <FormControlLabel
            control={
              <Radio
                checked={consent === false}
                onChange={() => handleSave(false)}
                name="radio button enabled"
                aria-label="No"
                icon={<FiberManualRecord className={classes.radioUnchecked} />}
                checkedIcon={
                  <FiberManualRecord className={classes.radioChecked} />
                }
                classes={{
                  checked: classes.radio,
                  root: classes.radioRoot,
                }}
              />
            }
            classes={{
              label: classes.label,
              root: classes.labelRoot,
            }}
            label="No"
          />
        </div>

        <p>
          We will retain your data for the duration of the assessment process.
          <br />
          30 days after your employer receives the report your data will be
          purged.
        </p>
        <p>
          For further information on the privacy of your data, please see our{' '}
          <a style={{ marginLeft: '0px' }} href="privacy" target="_blank">
            privacy statement
          </a>
          .
        </p>
      </GridItem>
    </GridContainer>
  );
}
