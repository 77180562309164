import React from 'react';
export const stepsInfo = [
  {
    step: 1,
    consent: false,
  },
  {
    step: 2,
    confirmVideo: false,
  },
  {
    step: 3,
    questionaire: [],
  },
  {
    step: 4,
    SidePhoto: null,
    FrontPhoto: null,
    SidePhotoUrl: '',
    FrontPhotoUrl: '',
    canContinue: true,
  },
  {
    step: 5,
  },
];
export const questions = [];
export const QuestionsContext = React.createContext(questions);
export const StepsContext = React.createContext(stepsInfo);
