import React, { useEffect, useState } from 'react';
import MUIDataTable from 'mui-datatables';
import Loading from 'components/Loading';
import { Hazards } from 'services/user';
import { Edit } from '@material-ui/icons';
import HazardsAdd from './Add';
import DatatableCustomToolbar from 'components/Editor/DatatableCustomToolbar';
export default function HazardsList() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  var defaultData = {
    id: null,
    name: '',
    risk: '',
    likelihood: '',
    severity: '',
    rating: '',
    controlMeasure: '',
    show: false,
  };
  const [hazardData, setHazardData] = useState(defaultData);

  // const [questionaires, setQuestionaires] = useState(null);
  // const [departments, setDepartments] = useState(null);
  // const [addAction, showAddAction] = useState(false);

  const columns = [
    {
      label: 'ID',
      name: 'id',
      options: {
        filter: false,
        display: false,
      },
    },
    {
      name: 'Edit',
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Edit
              onClick={() => {
                setHazardData({
                  id: tableMeta.rowData[0],
                  name: tableMeta.rowData[2],
                  risk: tableMeta.rowData[3],
                  likelihood: tableMeta.rowData[4],
                  severity: tableMeta.rowData[5],
                  rating: tableMeta.rowData[6],
                  controlMeasure: tableMeta.rowData[7],
                  show: true,
                });
              }}
            >
              Edit
            </Edit>
          );
        },
      },
    },
    {
      label: 'Name',
      name: 'name',
      options: {
        filter: false,
      },
    },
    {
      label: 'Risk',
      name: 'risk',
      options: {
        filter: false,
      },
    },
    {
      label: 'Likelihood',
      name: 'likelihood',
      options: {
        filter: true,
      },
    },
    {
      label: 'Severity',
      name: 'severity',
      options: {
        filter: true,
      },
    },
    {
      label: 'Rating',
      name: 'rating',
      options: {
        filter: true,
      },
    },
    {
      label: 'Control Measure',
      name: 'control_measure',
      options: {
        filter: false,
      },
    },
  ];

  const options = {
    selectableRows: 'none',
    filterType: 'checkbox',
    rowsPerPage: 100,
    customToolbar: () => {
      return (
        <DatatableCustomToolbar
          onClick={() => {
            setHazardData({
              id: null,
              name: '',
              risk: '',
              likelihood: '',
              severity: '',
              rating: '',
              controlMeasure: '',
              show: true,
            });
          }}
        />
      );
    },
  };
  const RefreshDataCloseModal = () => {
    setHazardData({
      id: null,
      name: '',
      risk: '',
      likelihood: '',
      severity: '',
      rating: '',
      controlMeasure: '',
      show: false,
    });
    LoadData();
  };
  const LoadData = () => {
    Hazards().then((res) => {
      var _data = res.data;

      if (_data.length === 0) {
        _data = [];
      }

      setData(_data);
      setLoading(false);
    });
  };
  useEffect(() => {
    LoadData();
  }, []);
  return (
    <>
      <Loading show={loading} />
      <HazardsAdd
        callBack={RefreshDataCloseModal}
        data={hazardData}
        cancelCallback={() => setHazardData(defaultData)}
      />
      {!loading && (
        <MUIDataTable
          title="Hazards List"
          data={data}
          columns={columns}
          options={options}
        />
      )}
    </>
  );
}
