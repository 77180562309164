import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import 'assets/scss/material-kit-react.scss?v=1.9.0';
// pages for this product
//import ActivationPage from 'views/Activation/ActivationPage';
import LoginPage from 'views/LoginPage/LoginPage.js';
import SSOPage from 'views/LoginPage/SSOPage.js';
import { defaultSSOLogin } from 'services/variables';
import { ThemeProvider } from '@material-ui/core';
import theme from './theme';
import Routes from './routes';
var hist = createBrowserHistory();
//define state
const EnabledSSO = process.env.REACT_APP_SSO_ONLY === 'true';

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Router history={hist}>
      <Switch>
        {Routes.map((r) => {
          return <Route path={r.path} key={r.name} component={r.component} />;
        })}

        <Route path="/admin/dashboard">
          <Redirect to="/dashboard" />
        </Route>
        <Route path="/admin/CompanySwitch">
          <Redirect to="/companyoverview" />
        </Route>
        {!EnabledSSO && <Route path="/" key="main" component={LoginPage} />}
        {EnabledSSO && <Route path="/" key="mainsso" component={SSOPage} />}
      </Switch>
    </Router>
  </ThemeProvider>,
  document.getElementById('root')
);
