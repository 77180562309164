import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from '@material-ui/core';

export default function Loading(props) {
  const { show, size } = props;

  return show && <CircularProgress size={size} />;
}

Loading.defaultProps = {
  show: false,
  size: 100,
};

Loading.propTypes = {
  show: PropTypes.bool,
  size: PropTypes.number,
};
