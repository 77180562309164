import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/Layout/Layout';

import { EventSeat } from '@material-ui/icons';
import DeskBooking from 'components/DeskBooking';
export default function FloorMapPage(props) {
  const { window } = props;
  const [office, SetOffice] = useState(null);
  console.log('office', office);
  return (
    <Layout window={window} title="Office Smart" icon={<EventSeat />}>
      <DeskBooking Admin={false} />
    </Layout>
  );
}
FloorMapPage.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */

  window: PropTypes.func,
};
