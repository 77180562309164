import React from 'react';
// @material-ui/core components
import { Logout } from '../../services/auth';

import { useHistory } from 'react-router-dom';

export default function LogoutPage() {
  const history = useHistory();

  Logout();
  history.push('/');

  return <></>;
}
