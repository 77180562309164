import React from 'react';

export default function Confirme() {
  return (
    <>
      <h2>Confirmation</h2>
      <div className="row">
        <div className="col">
          <p>
            You have successfully completed your self-assessment. Thank you!
            This information will be shared with your manager who will advise
            you of next steps.
          </p>
          <p>
            Please note that once you have submitted your data, you will not be
            able to modify your answers.
          </p>
        </div>
      </div>
    </>
  );
}
