import L from 'leaflet';
import ReactDOMServer from 'react-dom/server';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import iconRetina from 'leaflet/dist/images/marker-icon-2x.png';
// import markerGreen from './marker-green.png';
// import markerRed from './marker-red.png';
// import shadow from './marker-shadow.png';
export const greenIcon = L.Icon.Default.mergeOptions({
  iconRetinaUrl: iconRetina,
  iconUrl: icon,
  shadowUrl: iconShadow,
});

delete L.Icon.Default.prototype._getIconUrl;
export const defaultIcon = L.Icon.Default.mergeOptions({
  iconRetinaUrl: iconRetina,
  iconUrl: icon,
  shadowUrl: iconShadow,
});

// export const thisIcon = new L.Icon({
//   iconUrl: require('./img/marker-red.png'),
// });

export const thisIcon = L.divIcon({
  className: 'custom-div-icon',
  html: ReactDOMServer.renderToString('<div color="red">aa</div>'),
  iconSize: [30, 42],
  iconAnchor: [15, 42],
});

export const iconForge = (initials, booked) => {
  const _class = booked ? 'pin-booked' : 'pin';
  var content = '+';
  if (booked) {
    content = initials;
  }

  const html = (
    <div className={_class}>
      <span>{content}</span>
    </div>
  );

  return L.divIcon({
    className: 'custom-div-icon',
    html: ReactDOMServer.renderToString(html),
  });
};
export const iconPerson = new L.Icon({
  iconUrl: require('./img/marker-red.png'),
  iconRetinaUrl: require('./img/marker-red.png'),
  iconAnchor: null,
  popupAnchor: null,
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(60, 75),
  className: 'leaflet-div-icon',
});

export const myIcon = new L.icon({
  iconUrl: require('./img/marker-red.png'),
  iconSize: [64, 64],
  iconAnchor: [32, 64],
  popupAnchor: null,
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
});

// L.Icon.Default.mergeOptions({
//   iconRetinaUrl: iconRetina,
//   iconUrl: icon,
//   shadowUrl: iconShadow,
// });

/*
export const redIcon = new L.Icon({
  icon: markerRed,
  shadow: shadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});
*/
