import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import Chart from 'react-google-charts';
import { isCapellaAdmin } from 'services/context';
import Layout from 'components/Layout/Layout';
import { getAdminOverview } from 'services/user';
import CompanyAdd from 'components/Company/Add';
import Loading from 'components/Loading';
import { addDays } from 'services/variables';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import PrimaryTypo from 'components/Typography/Primary';
import { Dashboard } from '@material-ui/icons';
export default function CompanyOverviewPage(props) {
  const { window } = props;
  const [questionaires, setQuestionaires] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [timelineData, setTimelineData] = React.useState([]);

  const LoadData = () => {
    setLoading(true);
    getAdminOverview().then((res) => {
      let _companies = res.data.info;
      let _questionaires = res.data.questionaires;

      setQuestionaires(_questionaires);
      //set data

      let _timelineInfo = [];
      _timelineInfo.push([
        { type: 'string', id: 'Company' },

        { type: 'string', id: 'Name' },
        { type: 'date', id: 'Start' },
        { type: 'date', id: 'End' },
      ]);

      _companies.forEach((element) => {
        if (element.started_at != null && element.endexpected_at != null) {
          let _start = new Date(element.started_at);
          let _end = new Date(element.endexpected_at);

          _timelineInfo.push([element.company, 'Assessments', _start, _end]);
          let _endReview = addDays(14, _end);

          _timelineInfo.push([element.company, 'Review', _end, _endReview]);
        }
      });

      setTimelineData(_timelineInfo);
      setLoading(false);
    });
  };

  useEffect(() => {
    // code to run on component mount
    LoadData();
  }, []);
  return (
    <Layout window={window} title="Company Overview" icon={<Dashboard />}>
      <PrimaryTypo>Manage Companies</PrimaryTypo>
      <GridContainer>
        {isCapellaAdmin() && (
          <GridItem md={1}>
            <CompanyAdd questionaires={questionaires} callBack={LoadData} />
          </GridItem>
        )}
        <GridItem>
          <Loading show={loading} />
          {!loading && (
            <Chart
              height={'600px'}
              chartType="Timeline"
              data={timelineData}
              options={{
                avoidOverlappingGridLines: false,
                timeline: {
                  colorByRowLabel: true,
                },
              }}
              rootProps={{ 'data-testid': '5' }}
            />
          )}
        </GridItem>
      </GridContainer>
    </Layout>
  );
}

CompanyOverviewPage.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};
