import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Radio from '@material-ui/core/Radio';
import FiberManualRecord from '@material-ui/icons/FiberManualRecord';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { isArray } from 'services/variables';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import { primaryColor } from 'assets/jss/material-kit-react.js';
const useStyles = makeStyles(() => ({
  checkRoot: {
    padding: '12px',
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  radioRoot: {
    padding: '12px',
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  labelRoot: {
    marginLeft: '-14px',
  },
  checkboxAndRadio: {
    position: 'relative',
    display: 'block',
    marginTop: '10px',
    marginBottom: '10px',
  },
  checkboxAndRadioHorizontal: {
    position: 'relative',
    display: 'block',
    '&:first-child': {
      marginTop: '10px',
    },
    '&:not(:first-child)': {
      marginTop: '-14px',
    },
    marginTop: '0',
    marginBottom: '0',
  },
  checked: {
    color: '#C2A55C !important',
  },
  checkedIcon: {
    width: '20px',
    height: '20px',
    border: '1px solid rgba(0, 0, 0, .54)',
    borderRadius: '3px',
  },
  uncheckedIcon: {
    width: '0px',
    height: '0px',
    padding: '9px',
    border: '1px solid rgba(0, 0, 0, .54)',
    borderRadius: '3px',
  },
  disabledCheckboxAndRadio: {
    opacity: '0.45',
  },
  label: {
    cursor: 'pointer',
    paddingLeft: '0',
    color: '#000',
    fontSize: '14px',
    lineHeight: '1.428571429',
    fontWeight: '400',
    display: 'inline-flex',
    transition: '0.3s ease all',
    letterSpacing: 'unset',
  },
  labelHorizontal: {
    color: 'rgba(0, 0, 0, 0.26)',
    cursor: 'pointer',
    display: 'inline-flex',
    fontSize: '14px',
    lineHeight: '1.428571429',
    fontWeight: '400',
    paddingTop: '39px',
    marginRight: '0',
    '@media (min-width: 992px)': {
      float: 'right',
    },
  },
  checkOption: {
    color: primaryColor,
  },
  labelHorizontalRadioCheckbox: {
    paddingTop: '22px',
  },
  labelLeftHorizontal: {
    color: 'rgba(0, 0, 0, 0.26)',
    cursor: 'pointer',
    display: 'inline-flex',
    fontSize: '14px',
    lineHeight: '1.428571429',
    fontWeight: '400',
    paddingTop: '22px',
    marginRight: '0',
  },
  radio: {
    color: '#C2A55C !important',
  },
  radioChecked: {
    width: '16px',
    height: '16px',
    border: '1px solid #C2A55C',
    borderRadius: '50%',
  },
  radioUnchecked: {
    width: '0px',
    height: '0px',
    padding: '7px',
    border: '1px solid rgba(0, 0, 0, .54)',
    borderRadius: '50%',
  },
  inlineChecks: {
    marginTop: '8px',
  },
  iconCheckbox: {
    height: '16px',
    width: '16px',
    border: '1px solid ' + primaryColor,

    // '&:hover': {
    //   color: '#9D813B',
    //   '& > span:first-child': {
    //     borderColor: '#9D813B',
    //   },
    // },
  },
  customCheckbox: {
    color: primaryColor,
  },
  customCheckboxChecked: {
    color: primaryColor,
  },

  switchBase: {
    color: '#C2A55C !important',
  },
  switchIcon: {
    boxShadow: '0 1px 3px 1px rgba(0, 0, 0, 0.4)',
    color: '#FFFFFF !important',
    border: '1px solid rgba(0, 0, 0, .54)',
  },
  switchBar: {
    width: '30px',
    height: '15px',
    backgroundColor: 'rgb(80, 80, 80)',
    borderRadius: '15px',
    opacity: '0.7!important',
  },
  multipleOptions: {
    maxWidth: '150px',
    minWidth: '150px',
  },
  switchChecked: {
    '& + $switchBar': {
      backgroundColor: 'rgba(156, 39, 176, 1) !important',
    },
    '& $switchIcon': {
      borderColor: '#C4A168',
    },
  },
  switchRoot: {
    height: '48px',
  },
}));

export default function Answer(props) {
  const classes = useStyles();

  const _options = props.options == null ? [] : props.options;
  var _value = props.value;
  var _valueOptions = props.valueOptions;
  const [answer, setAnswer] = useState(_value);
  const [answerOptions, setAnswerOptions] = useState(_valueOptions);
  const [possibleOptions] = useState(_options);

  let answerElement;

  const handleCheck = (event) => {
    const { name, value } = event.target;
    var newAnswer = [];

    if (answerOptions != null && answerOptions.includes(value)) {
      newAnswer = answerOptions.filter((c) => c !== value);
    } else {
      if (isArray(answerOptions)) {
        answerOptions.forEach((answ) => {
          newAnswer.push(answ);
        });
      }
      newAnswer.push(value);
    }
    setAnswerOptions(newAnswer);

    props.handleChangeArray(name, newAnswer);
  };

  const handleSave = (event) => {
    const { value } = event.target;

    setAnswer(value);
    props.handleChange(event);
  };

  if (props.type === 'yesno' || props.type === 'yesnona') {
    answerElement = (
      <>
        <FormControlLabel
          control={
            <Radio
              checked={answer === 'yes'}
              onChange={handleSave}
              value="yes"
              name={props.name}
              aria-label="Yes"
              icon={<FiberManualRecord className={classes.radioUnchecked} />}
              checkedIcon={
                <FiberManualRecord className={classes.radioChecked} />
              }
              classes={{
                checked: classes.radio,
                root: classes.radioRoot,
              }}
            />
          }
          classes={{
            label: classes.label,
            root: classes.labelRoot,
          }}
          label="Yes"
        />
        <FormControlLabel
          control={
            <Radio
              checked={answer === 'no'}
              onChange={handleSave}
              value="no"
              name={props.name}
              aria-label="No"
              icon={<FiberManualRecord className={classes.radioUnchecked} />}
              checkedIcon={
                <FiberManualRecord className={classes.radioChecked} />
              }
              classes={{
                checked: classes.radio,
                root: classes.radioRoot,
              }}
            />
          }
          classes={{
            label: classes.label,
            root: classes.labelRoot,
          }}
          label="No"
        />
      </>
    );
  }
  if (props.type === 'yesnona') {
    answerElement = (
      <>
        {answerElement}
        <FormControlLabel
          control={
            <Radio
              checked={answer === 'na'}
              onChange={handleSave}
              value="na"
              name={props.name}
              aria-label="NA"
              icon={<FiberManualRecord className={classes.radioUnchecked} />}
              checkedIcon={
                <FiberManualRecord className={classes.radioChecked} />
              }
              classes={{
                checked: classes.radio,
                root: classes.radioRoot,
              }}
            />
          }
          classes={{
            label: classes.label,
            root: classes.labelRoot,
          }}
          label="N/A"
        />
      </>
    );
  }

  if (props.type === 'openquestion') {
    answerElement = (
      <TextField
        id="standard-basic"
        rowsMax={5}
        multiline
        name={props.name}
        style={{ width: '100%' }}
        onChange={handleSave}
        label={props.question}
        placeholder={props.question}
        defaultValue={answer}
      />
    );
  }

  if (props.type === 'multipleoptions') {
    answerElement = (
      <>
        {possibleOptions.map((val) => (
          <FormControlLabel
            control={
              <>
                <>
                  <Checkbox
                    className={classes.customCheckbox}
                    key={props.name + '_' + val}
                    value={val}
                    name={props.name}
                    icon={<CheckBoxOutlineBlankIcon />}
                    checkedIcon={
                      <CheckBoxIcon className={classes.customCheckboxChecked} />
                    }
                    onChange={(e) => handleCheck(e)}
                    checked={
                      answerOptions !== undefined &&
                      answerOptions !== null &&
                      answerOptions.indexOf(val) > -1
                    }
                  />
                  <ListItemText primary={val} />
                </>
              </>
            }
            classes={{
              label: classes.label,
              root: classes.labelRoot,
            }}
          />
        ))}
      </>
    );
  }

  return <>{answerElement}</>;
}

Answer.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  answers: PropTypes.string,
  question: PropTypes.string,
  handleChange: PropTypes.func,
  handleChangeArray: PropTypes.func,
  value: PropTypes.string,
  valueOptions: PropTypes.array || null,
  options: PropTypes.array || null,
};
