import Components from 'views/Components/Components.js';
//import LandingPage from 'views/LandingPage/LandingPage.js';
import AssessmentPage from 'views/AssessmentPage/AssessmentPage.js';
import AccountPage from 'views/Account/AccountPage.js';
import LoginPage from 'views/LoginPage/LoginPage.js';
import ClosedPage from 'views/AssessmentPage/ClosedPage.js';
import ResetPasswordPage from 'views/ResetPage/ResetPage.js';
import ForgotPasswordPage from 'views/ResetPage/ForgotPage.js';
//import SignupPage from 'views/SignupPage/SignupPage.js';
import LogoutPage from 'views/LogoutPage/LogoutPage.js';
import DashboardPage from 'views/Home/DashboardPage';
import HomePage from 'views/Home/HomePage';
import AssessmentsPage from 'views/AssessmentPage/ListPage';
import FloorMapPage from 'views/Office/FloorMapPage';
import AnalysisPage from 'views/Admin/AnalysisPage';
import FloorMapAdminPage from 'views/Admin/FloorMapAdminPage';
import CompanyOverviewPage from 'views/Admin/CompanyOverviewPage';
import CompanySwitchPage from 'views/Admin/CompanySwitchPage';
import IntegrationsPage from 'views/Admin/IntegrationsPage';

import HazardsPage from 'views/HazardsPage/HazardsManagePage';
import EquipmentsManagePage from 'views/EquipmentsPage/EquipmentsManagePage';
import EquipmentPage from 'views/AssessmentPage/EquipmentsPage';
//import CommunicationPage from 'views/Communication/CommunicationPage';
import ActivationPage from 'views/Activation/ActivationPage';
import UsersListPage from 'views/UsersPage/UsersListPage';
import RemindersPage from 'views/RemindersPage/RemindersPage';
import RemindersHistoryPage from 'views/RemindersPage/RemindersHistoryPage';
import RemindersSelectPage from 'views/RemindersPage/RemindersSelectPage';
import ContinueAssessmentPage from 'views/AssessmentPage/Continue';
import EmailTemplatesPage from 'views/EmailsPage';
import SSOPage from 'views/LoginPage/SSOPage.js';
import SSOResultPage from 'views/LoginPage/SSOResultPage';
import PrivacyPage from 'views/Privacy/PrivacyPage';

import {
  ExitToApp,
  EventSeat,
  Event,
  EmojiPeople,
  AccountCircle,
  Dashboard,
  RateReview,
  Build,
  SupervisorAccount,
  Email,
  TrackChanges,
  Assignment,
  SettingsInputComponent,
  Apps,
} from '@material-ui/icons';

const EnableDeskSmart = process.env.REACT_APP_ENABLE_DESKSMART === 'true';
const EnabledSSO = process.env.REACT_APP_SSO_ONLY === 'True';
const dashboardRoutes = [
  {
    path: '/companyoverview',
    name: 'Company Overview',
    master: true,
    admin: true,
    component: CompanyOverviewPage,
    icon: <Dashboard />,
    menu: true,
  },
  {
    path: '/external-integrations',
    name: 'External integrations',
    master: true,
    admin: true,
    component: IntegrationsPage,
    icon: <SettingsInputComponent />,
    menu: false,
  },
  {
    path: '/impersonate',
    name: 'Select Company',
    master: true,
    admin: true,
    component: CompanySwitchPage,
    icon: <TrackChanges />,
    menu: true,
  },
  {
    path: '/users',
    name: 'Users',
    master: true,
    component: UsersListPage,
    icon: <SupervisorAccount />,
    menu: true,
  },
  {
    path: '/templates',
    name: 'Email Templates',
    master: true,
    component: EmailTemplatesPage,
    icon: <Email />,
    menu: true,
  },
  {
    path: '/reminders',
    name: 'Reminders',
    master: true,
    component: RemindersPage,
    icon: <Event />,
    menu: true,
  },
  {
    path: '/reminders-history',
    name: 'Reminders History',
    master: true,
    component: RemindersHistoryPage,
    icon: <Email />,
    menu: false,
  },
  {
    path: '/reminders-select',
    name: 'Individual Reminders',
    master: true,
    component: RemindersSelectPage,
    icon: <Email />,
    menu: false,
  },
  {
    path: '/office-plan',
    name: 'Office Plans',
    master: true,
    component: FloorMapAdminPage,
    icon: <EventSeat />,
    menu: EnableDeskSmart,
  },

  {
    path: '/hazards',
    name: 'Manage Hazards',
    admin: true,
    component: HazardsPage,
    icon: <RateReview />,
    menu: true,
  },
  {
    path: '/equipments',
    name: 'Manage Equipment',
    admin: true,
    component: EquipmentsManagePage,
    icon: <Build />,
    menu: true,
  },
  {
    path: '/dashboard',
    name: 'Home',
    component: DashboardPage,
    icon: <Dashboard />,
    menu: EnableDeskSmart,
  },
  {
    path: '/home',
    name: 'Home',
    component: HomePage,
    icon: <Dashboard />,
    menu: true,
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: PrivacyPage,
    menu: false,
  },
  {
    path: '/components',
    name: 'Components',
    component: Components,
    menu: false,
  },
  {
    path: '/assessment',
    name: 'Assessment',
    component: AssessmentPage,
    menu: false,
  },
  {
    path: '/continue/assessment',
    name: 'Continue Assessment',
    component: ContinueAssessmentPage,
    icon: <EmojiPeople />,
    menu: false,
  },
  {
    path: '/analysis/:id',
    name: 'Analysis',
    component: AnalysisPage,
    icon: <Assignment />,
    menu: false,
  },
  {
    path: '/account',
    name: 'Account',
    component: AccountPage,
    icon: <AccountCircle />,
    menu: true,
  },
  {
    path: '/office-smart',
    name: 'Office Smart',
    master: false,
    component: FloorMapPage,
    icon: <EventSeat />,
    menu: EnableDeskSmart,
  },
  {
    path: '/activation',
    name: 'Activation',
    component: ActivationPage,
    menu: false,
  },
  {
    path: '/activate',
    name: 'Activation',
    component: ActivationPage,
    menu: false,
  },
  {
    path: '/assessments',
    name: 'Assessments',
    component: AssessmentsPage,
    icon: <EmojiPeople />,
    menu: true,
  },
  {
    path: '/equipmentmatrix',
    name: 'Equipment',
    component: EquipmentPage,
    icon: <Apps />,
    menu: true,
  },
  {
    path: '/signin',
    name: 'SignIn',
    component: EnabledSSO ? SSOPage : LoginPage,
    menu: false,
  },
  {
    path: '/signin-basic',
    name: 'SignIn',
    component: LoginPage,
    menu: false,
  },
  {
    path: '/completed',
    name: 'Closed',
    component: ClosedPage,
    menu: false,
  },
  {
    path: '/forgot',
    name: 'Forgot Password',
    component: ForgotPasswordPage,
    menu: false,
  },

  {
    path: '/reset',
    name: 'Reset Password',
    component: ResetPasswordPage,
    menu: false,
  },
  {
    path: '/signout',
    name: 'LogOut',
    component: LogoutPage,
    icon: <ExitToApp />,
    menu: false,
  },
  {
    path: '/auth-sso',
    name: 'SSO',
    component: SSOResultPage,
    menu: false,
  },
];

export default dashboardRoutes;
