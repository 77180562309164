import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getAdminDashboard } from 'services/user';
import StatsCard from 'components/Card/StatsCard';

import Layout from 'components/Layout/Layout';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';

import Chart from 'react-google-charts';

import Loading from 'components/Loading';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import Table from 'components/Table/Table.js';
import styles from 'assets/jss/material-kit-react/views/dashboardStyle.js';
import { makeStyles } from '@material-ui/core/styles';
import GraphCard from 'components/Card/GraphCard';

import {
  Dashboard,
  Comment,
  AssignmentTurnedIn,
  PeopleOutline,
  People,
  ReportProblem,
  Group,
  HowToReg,
} from '@material-ui/icons';

const useStyles = makeStyles(styles);
export default function DashboardPage(props) {
  const ssoActivated = process.env.REACT_APP_SSO_ONLY === 'True';
  const classes = useStyles();
  const { window } = props;
  const defaultStats = {
    Employees: '',
    NumUsers: '',
    UsersLoggedIn: '',
    ClosedAssessments: '',
    HighRisk: '',
    HomeConsultations: '',
    HomeConsultationsRecomended: '',
    Comments: '',
    AssessmentsDataPie: [],
    Equipment: [],
  };
  const [stats, setStats] = useState(defaultStats);
  const [loading, setLoading] = useState(true);

  const LoadData = () => {
    getAdminDashboard().then((res) => {
      var data = res.data;

      var closed = parseInt(data.ClosedAssessment, 10);
      var open = parseInt(data.OpenAssessment, 10);

      var _AssessmentsDataPie = [
        ['Status', 'Number of Assessments'],
        ['Open', open],
      ];
      if (closed > 0) {
        _AssessmentsDataPie.push(['Closed', closed]);
      }

      var _totalUsers =
        parseInt(data.UsersLoggedIn) + parseInt(data.UsersNotLoggedIn);
      var _numEmployees =
        data.NumEmployees > 0 ? data.NumEmployees : _totalUsers;

      var _Equipment = [];
      if (data.Equipment != null) {
        data.Equipment.forEach((element) => {
          _Equipment.push([
            element.equipment,
            element.required,
            element.optional,
          ]);
        });
      }
      var _closedTrend = [];
      var _activationTrend = [];
      var closedTrend = 0;
      var activationTrend = 0;
      _closedTrend.push(['Date', 'Closed Assessments', 'Total Assessments']);
      if (data.ClosedTrend != null) {
        if (data.ClosedTrend.length === 0) {
          _closedTrend.push([new Date(), 0, _totalUsers]);
        }

        data.ClosedTrend.forEach((element) => {
          closedTrend += parseInt(element.cnt);
          _closedTrend.push([element.dt, parseInt(closedTrend), _totalUsers]);
        });
      }

      if (data._activationTrend != null) {
        _activationTrend.push(['Date', 'Activated Users', 'Total Users']);
        if (data.ActivationTrend.length === 0) {
          _activationTrend.push([new Date(), 0, _totalUsers]);
        }

        data.ActivationTrend.forEach((element) => {
          activationTrend += parseInt(element.cnt);
          _activationTrend.push([element.dt, activationTrend, _totalUsers]);
        });
      }
      setStats({
        UsersLoggedIn: data.UsersLoggedIn,
        UsersNotLoggedIn: data.UsersNotLoggedIn,
        NumUsers:
          parseInt(data.UsersLoggedIn) + parseInt(data.UsersNotLoggedIn),
        Employees: _numEmployees,
        OpenAssessments: data.OpenAssessments,
        ClosedAssessments: data.ClosedAssessment,
        TermsCondition: data.TermsCondition,
        HighRisk: data.HighRisk,
        Comments: data.Comments,
        HomeConsultations: data.HomeConsultations,
        HomeConsultationsRecomended: data.HomeConsultationsRecomended,
        Compliant: data.Compliant,
        NonCompliant: data.NonCompliant,
        CompliantPhotos: data.CompliantPhotos,
        NonCompliantPhotos: data.NonCompliantPhotos,
        AssessmentsDataPie: _AssessmentsDataPie,
        Equipment: _Equipment,
        Recomendations: data.Recomendations,
        ClosedTrend: _closedTrend,
        ActivationTrend: _activationTrend,
      });

      setLoading(false);
    });
  };
  useEffect(() => {
    LoadData();
  }, []);
  return (
    <Layout window={window} title="Dashboard" icon={<Dashboard />}>
      <GridContainer>
        <GridItem lg={3} sm={6} md={3}>
          <StatsCard
            bigIcon={
              <Group
                style={{ color: '#2D9CDB' }}
                className={classes.BigIcon}
                alt="Users"
              />
            }
            loading={loading}
            color="primary"
            statsText="Number of users"
            statsValue={stats.NumUsers}
            statsIcon={<i className="fa fa-refresh" />}
            statsLink="assessments"
            statsIconText="View All"
          />
        </GridItem>
        {ssoActivated && (
          <>
            <GridItem lg={3} sm={6} md={3}>
              <StatsCard
                bigIcon={
                  <People
                    style={{ color: '#9B51E0' }}
                    className={classes.BigIcon}
                    alt="Active users"
                  />
                }
                loading={loading}
                color="rose"
                statsText="Users Logged In"
                statsValue={stats.UsersLoggedIn}
                statsIcon={<i className="fa fa-calendar-o" />}
                statsLink="assessments?activated=true"
                statsIconText="View All"
              />
            </GridItem>

            <GridItem lg={3} sm={6} md={3}>
              <StatsCard
                bigIcon={
                  <PeopleOutline
                    style={{ color: '#F2994A' }}
                    className={classes.BigIcon}
                    alt="Non activated Users"
                  />
                }
                loading={loading}
                color="warning"
                statsText="Non activated Users"
                statsValue={stats.NumUsers - stats.UsersLoggedIn}
                statsIcon={<i className="fa fa-calendar-o" />}
                statsLink="assessments?activated=false"
                statsIconText="View All"
              />
            </GridItem>
          </>
        )}
        <GridItem lg={3} sm={6} md={3}>
          <StatsCard
            bigIcon={
              <AssignmentTurnedIn
                style={{ color: '#6FCF97' }}
                className={classes.BigIcon}
                alt="Closed assessments"
              />
            }
            color="infoSuccess"
            loading={loading}
            statsText="Closed Assessments"
            statsValue={stats.ClosedAssessments}
            statsIcon={<i className="fa fa-refresh" />}
            statsLink="assessments?closed=true"
            statsIconText="View All"
          />
        </GridItem>
        <GridItem lg={3} sm={6} md={3}>
          <StatsCard
            bigIcon={
              <ReportProblem
                style={{ color: '#EB5757' }}
                className={classes.BigIcon}
                alt="High risk cases"
              />
            }
            loading={loading}
            color="alert"
            statsText="High risk cases"
            statsLink="assessments?highrisk=true"
            statsValue={stats.HighRisk}
            statsIcon={<i className="fa fa-refresh" />}
            statsIconText="View All"
          />
        </GridItem>
        <GridItem lg={3} sm={6} md={3}>
          <StatsCard
            bigIcon={
              <HowToReg
                style={{ color: '#F2C94C' }}
                className={classes.BigIcon}
                alt="Consultations Requested"
              />
            }
            loading={loading}
            color="requested"
            statsText="Consultations Requested"
            statsLink="assessments?consultationrequested=true"
            statsValue={stats.HomeConsultations}
            statsIcon={<i className="fa fa-refresh" />}
            statsIconText="View All"
          />
        </GridItem>
        <GridItem lg={3} sm={6} md={3}>
          <StatsCard
            bigIcon={
              <HowToReg
                style={{ color: '#27AE60' }}
                className={classes.BigIcon}
                alt="Consultations Recommended"
              />
            }
            loading={loading}
            color="recommended"
            statsText="Consultations Recomended"
            statsLink="assessments?consultationrecommended=true"
            statsValue={stats.HomeConsultationsRecomended}
            statsIcon={<i className="fa fa-refresh" />}
            statsIconText="View All"
          />
        </GridItem>
        <GridItem lg={3} sm={6} md={3}>
          <StatsCard
            bigIcon={
              <Comment
                style={{ color: '#1E3252' }}
                className={classes.BigIcon}
                alt="Employee Comments"
              />
            }
            color="comments"
            loading={loading}
            statsText="Employee Comments"
            statsLink="assessments?comments=true"
            statsValue={stats.Comments}
            statsIcon={<i className="fa fa-refresh" />}
            statsIconText="View All"
          />
        </GridItem>
        <GridItem lg={6} sm={12} align="center">
          <GraphCard title="Self Assessment Statistics">
            <Loading show={loading} />
            {!loading && (
              <Chart
                width={'90%'}
                height={'90%'}
                chartType="PieChart"
                loader={<Loading />}
                data={stats.AssessmentsDataPie}
                options={{
                  title: stats.AssessmentsDataPieTitle,
                  pieHole: 0.8,
                  //legend: 'bottom',
                  legend: {
                    textStyle: { fontSize: 18 },
                    position: 'labeled',
                  },
                  pieSliceText: 'none',
                  pieSliceTextStyle: {
                    textStyle: {
                      fontSize: 40,
                      marginLeft: '30px',
                      color: '#233343',
                    },
                  },
                  // pieHole: 0.4,
                  chartArea: {
                    width: '90%',
                    height: '90%',
                    textStyle: { fontSize: 20 },
                  },
                  pieStartAngle: 100,
                  slices: {
                    0: {
                      color: '#233343',

                      // textStyle: { fontSize: 40, marginLeft: '30px' },
                    },
                    1: {
                      color: '#C2A55C',
                    },
                  },
                }}
                rootProps={{ 'data-testid': '1' }}
              />
            )}
          </GraphCard>
        </GridItem>
        <GridItem lg={6} sm={12}>
          <Card>
            <CardHeader className={classes.GraphTitleGraph}>
              Equipment Stats
            </CardHeader>

            <CardBody align="center">
              <Loading show={loading} />
              {!loading && (
                <Table
                  tableHeaderColor="warning"
                  tableHead={['Equipment', 'Required', 'Optional']}
                  tableData={stats.Equipment}
                />
              )}
            </CardBody>
          </Card>
        </GridItem>
        <GridItem lg={6} sm={12}>
          <GraphCard title="Assessment Closing Trend">
            <Loading show={loading} />
            {!loading && (
              <Chart
                height={'300px'}
                chartType="LineChart"
                loader={<Loading />}
                data={stats.ClosedTrend}
                options={{
                  hAxis: {
                    title: 'Date',
                  },
                  vAxis: {
                    title: 'Closed Assessments',
                  },
                  series: {
                    1: { curveType: 'function' },
                  },
                }}
                rootProps={{ 'data-testid': '1' }}
              />
            )}
          </GraphCard>
        </GridItem>
        {ssoActivated && (
          <GridItem lg={6} sm={12}>
            <GraphCard title="Activation Trend">
              <Loading show={loading} />
              {!loading && (
                <Chart
                  height={'300px'}
                  chartType="LineChart"
                  loader={<Loading />}
                  data={stats.ActivationTrend}
                  options={{
                    hAxis: {
                      title: 'Date',
                    },
                    vAxis: {
                      title: 'Activations',
                    },
                    series: {
                      1: { curveType: 'function' },
                    },
                  }}
                  rootProps={{ 'data-testid': '1' }}
                />
              )}
            </GraphCard>
          </GridItem>
        )}
        {/* <GridItem lg={3} sm={6}>
          <Card
            statsIcon="fa fa-clock-o"
            title="Equipment"
            category=""
            stats={null}
            content={
              <Table striped hover>
                <thead>
                  <th></th>
                  <th>Recommended</th>
                  <th>Optional</th>
                </thead>
                <tbody>
                  {this.state.Equipment.map(
                    ({ equipment, required, optional }) => {
                      return (
                        <tr key={equipment}>
                          <td>{equipment}</td>
                          <td>{required}</td>
                          <td>{optional}</td>
                        </tr>
                      );
                    }
                  )}
                </tbody>
              </Table>
            }
          />
        </GridItem> */}
      </GridContainer>
    </Layout>
  );
}

DashboardPage.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};
