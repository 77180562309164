import React, { useState, useEffect } from 'react';
import Button from 'components/CustomButtons/Button.js';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { addHazard } from 'services/user';
import PropTypes from 'prop-types';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';

export default function HazardEdit(props) {
  var { callBack, cancelCallback } = props;
  const [loading, setLoading] = useState(false);
  var [id, setId] = useState();
  var [name, setName] = useState();
  var [risk, setRisk] = useState();
  var [likelihood, setLikelihood] = useState();
  var [severity, setSeverity] = useState();
  var [rating, setRating] = useState();
  var [controlMeasure, setControlMeasure] = useState();

  const handleClose = () => {
    cancelCallback();
  };

  useEffect(() => {
    setId(props.data.id);
    setName(props.data.name);
    setRisk(props.data.risk);
    setLikelihood(props.data.likelihood);
    setSeverity(props.data.severity);
    setRating(props.data.rating);
    setControlMeasure(props.data.controlMeasure);
  }, [props.data]);

  const AddHazard = () => {
    setLoading(true);
    var body = {
      id: id,
      name: name,
      risk: risk,
      likelihood: likelihood,
      severity: severity,
      rating: rating,
      measure: controlMeasure,
    };
    addHazard(body)
      .then(() => {
        setLoading(false);
        callBack();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <Dialog
      open={props.data.show}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Hazard</DialogTitle>
      <DialogContent>
        <GridContainer>
          <GridItem xs={12}>Hazard being used for assessments</GridItem>
          <GridItem xs={12}>
            <TextField
              name="name"
              label="Name"
              required
              multiline
              fullWidth
              onChange={(e) => setName(e.target.value)}
              defaultValue={name}
              placeholder="Name"
            />
          </GridItem>
          <GridItem xs={4}>
            <TextField
              name="likelihood"
              type="number"
              label="Likelihood"
              required
              fullWidth
              onChange={(e) => setLikelihood(e.target.value)}
              defaultValue={likelihood}
              placeholder="Likelihood"
            />
          </GridItem>
          <GridItem xs={4}>
            <TextField
              name="severity"
              type="number"
              label="Severity"
              required
              fullWidth
              onChange={(e) => setSeverity(e.target.value)}
              defaultValue={severity}
              placeholder="Severity"
            />{' '}
          </GridItem>
          <GridItem xs={4}>
            <TextField
              name="rating"
              type="number"
              label="Rating"
              required
              fullWidth
              onChange={(e) => setRating(e.target.value)}
              defaultValue={rating}
              placeholder="Rating"
            />
          </GridItem>
          <GridItem xs={12}>
            <TextField
              name="risk"
              label="Risk"
              required
              multiline
              fullWidth
              onChange={(e) => setRisk(e.target.value)}
              defaultValue={risk}
              placeholder="Risk"
            />
          </GridItem>
          <GridItem xs={12}>
            <TextField
              name="control"
              label="Control Measure"
              required
              multiline
              fullWidth
              onChange={(e) => setControlMeasure(e.target.value)}
              defaultValue={controlMeasure}
              placeholder="Control Measure"
            />
          </GridItem>
        </GridContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>{' '}
        <Button loading={loading} size="lg" onClick={AddHazard} color="primary">
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}

HazardEdit.propTypes = {
  callBack: PropTypes.func,
  data: PropTypes.object,
};
