import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { getHazards, UpdateHazards } from 'services/user';
import Button from 'components/CustomButtons/Button.js';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import RemoveIcon from '@material-ui/icons/Remove';
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
export default function Hazards({ data, callback }) {
  const classes = useStyles();
  const [addFree, setAddFree] = useState(false);
  const [saving, setSaving] = useState();
  const [hazards, setHazards] = useState([]);
  const [hazardName, setHazardName] = useState();
  const [hazardId, setHazardId] = useState('');
  const [likelihood, setLikelihood] = useState();
  const [severity, setSeverity] = useState();
  const [rating, setRating] = useState();
  const [risk, setRisk] = useState();
  const [controlMeasure, setControlMeasure] = useState();
  const [freeHazard, setFreeHazard] = useState();
  const LoadData = () => {
    getHazards(data.id)
      .then((response) => {
        return response.data;
      })
      .then((res) => {
        let hazards = res.hazards.map((hazard) => {
          return {
            id: hazard.id,
            name: hazard.name,
            risk: hazard.risk,
            control_measure: hazard.control_measure,
            rating: hazard.rating,
            likelihood: hazard.likelihood,
            severity: hazard.severity,
          };
        });

        hazards = [
          {
            id: '',
            name: '(Select hazard)',
            risk: '',
            rating: '',
            likelihood: '',
            severity: '',
            control_measure: '',
          },
        ].concat(hazards);
        setHazards(hazards);
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  useEffect(() => {
    LoadData();
  }, []);

  const addHazard = () => {
    setSaving(true);
    setAddFree(false);
    var body = {
      assessmentid: data.id,
      hazard_id: hazardId,
      control_measure: controlMeasure,
      risk: risk,
      name: hazardName,
      rating: rating,
      severity: severity,
      likelihood: likelihood,
    };

    UpdateHazards(data.id, body).then(() => {
      setSaving(false);
      setHazardId();
      setHazardName('');
      setRisk('');
      setControlMeasure('');
      setRating('');
      setSeverity('');
      setLikelihood('');
      callback();
    });
  };

  const handleChange = (event) => {
    let idx = event.target.value;
    setHazardId(idx);

    var dataset = hazards.filter((x) => x.id === idx)[0];

    setHazardName(dataset.name);
    setRisk(dataset.risk);
    setControlMeasure(dataset.control_measure);
    setRating(dataset.rating);
    setSeverity(dataset.severity);
    setLikelihood(dataset.likelihood);
  };

  return (
    <GridContainer style={{ paddingLeft: '10px' }}>
      {!addFree && (
        <>
          <GridItem xs={2} sm={2} md={2} style={{ paddingTop: '10px' }}>
            <InputLabel id="demo-simple-select-helper-label">Hazard</InputLabel>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
            <Select
              className={classes.formControl}
              display={!addFree}
              id="Hazard"
              label="Hazard"
              value={hazardId}
              minWidth="150"
              fullWidth
              onChange={handleChange}
              helperText="Please select hazard to add"
            >
              {hazards.map(
                (option) =>
                  option.id !== '' && (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  )
              )}
            </Select>
          </GridItem>
          {/* <IconButton aria-label="Add">
              <AddIcon
                onClick={() => {
                  setHazardId(null);
                  setAddFree(true);
                  setHazardName(null);
                  setRisk(null);
                  setControlMeasure(null);
                  setRating(null);
                  setSeverity(null);
                  setLikelihood(null);
                }}
              />
            </IconButton> */}
        </>
      )}
      {addFree && (
        <GridItem xs={12} sm={12} md={12}>
          <TextField
            id="hazard"
            label="Hazard"
            fullWidth
            display={addFree}
            value={freeHazard}
            onChange={(e) => setFreeHazard(e.target.value)}
          />
          <IconButton aria-label="Add">
            <RemoveIcon
              onClick={() => {
                setFreeHazard(null);
                setAddFree(false);
              }}
            />
          </IconButton>
        </GridItem>
      )}

      <GridItem xs={4} sm={4} md={4}>
        <TextField
          id="Likelihood"
          label="Likelihood"
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          value={likelihood}
          onChange={(e) => setLikelihood(e.target.value)}
        />
      </GridItem>
      <GridItem xs={4} sm={4} md={4}>
        <TextField
          id="Severity"
          label="Severity"
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          value={severity}
          onChange={(e) => setSeverity(e.target.value)}
        />
      </GridItem>
      <GridItem xs={4} sm={4} md={4}>
        <TextField
          id="rating"
          label="Risk Rating"
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          value={rating}
          onChange={(e) => setRating(e.target.value)}
        />
      </GridItem>

      <GridItem xs={11} sm={11} md={11}>
        <TextField
          id="risk"
          label="Risk"
          multiline
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          value={risk}
          onChange={(e) => setRisk(e.target.value)}
        />
      </GridItem>
      <GridItem xs={11} sm={11} md={11}>
        <TextField
          id="controlmeasure"
          label="Control Measure"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          multiline
          value={controlMeasure}
          onChange={(e) => setControlMeasure(e.target.value)}
        />
      </GridItem>
      <GridItem md={12}>
        <Button loading={saving} color="primary" onClick={addHazard}>
          Save
        </Button>
      </GridItem>
    </GridContainer>
  );
}

Hazards.propTypes = {
  callback: PropTypes.func,
  data: PropTypes.object,
};
