import { createTheme, colors } from '@material-ui/core';
import shadows from './shadows';
import typography from './typography';
import {
  primaryColor,
  dangerColor,
  roseColor,
  grayColor,
} from 'assets/jss/material-kit-react.js';
const theme = createTheme({
  palette: {
    primary: {
      contrastText: '#ffffff',
      main: primaryColor,
    },
    text: {
      primary: '#000',
      secondary: '#6b778c',
    },
  },
  shadows,
  typography,
});

export default theme;
