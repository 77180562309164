import React from 'react';
import PropTypes from 'prop-types';
import { ImpersonateCompany } from 'services/context';
import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function CompanyEdit(props) {
  const classes = useStyles();
  const { companies } = props;

  const history = useHistory();

  const impersonate = (id, name, location) => {
    ImpersonateCompany(id, name, location);
    history.push('/dashboard');
  };

  return (
    <div>
      <FormControl>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Company</TableCell>
                <TableCell>Dates</TableCell>
                <TableCell>Users</TableCell>
                <TableCell>To review</TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {companies.map((row) => (
                <TableRow key={row.id}>
                  <TableCell scope="row">
                    <b>{row.company}</b>
                    <br />
                    {row.location}
                  </TableCell>
                  <TableCell scope="row">
                    <b>Started:</b> {row.started_at}
                    <br />
                    <b>End Expected:</b> {row.endexpected_at}
                  </TableCell>
                  <TableCell scope="row">{row.users}</TableCell>
                  <TableCell scope="row">{row.users - row.reviewed}</TableCell>
                  <TableCell align="right">
                    <IconButton
                      aria-label="Select Company"
                      onClick={() =>
                        impersonate(row.id, row.company, row.location)
                      }
                    >
                      <ArrowForwardIosIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <FormHelperText>Impersonate Admin for company</FormHelperText>
      </FormControl>
    </div>
  );
}

CompanyEdit.propTypes = {
  callBack: PropTypes.func,
};
