import axios from 'axios';
import { saveStore, getUser, getProfile } from './context';
import variables from './variables';

export const Activation = (body) => {
  return axios
    .post(variables.api.url + 'account/activation', body)
    .then(_checkStatus)
    .then((response) => {
      return Promise.resolve(response.data);
    });
};
// export const SaveUserStore = (token, expiresIn) => {
//   var t = new Date();
//   t.setMinutes(expiresIn);

//   var _user = { access_token: token, expiresAt: t, token_type: 'bearer' };
//   saveStore('user', JSON.stringify(_user));
// };

export const SaveUserStore = (token, expiresIn, continuation) => {
  var t = new Date();
  t.setMinutes(expiresIn);

  var _user = {
    access_token: token,
    expiresAt: t,
    token_type: 'bearer',
    continue: continuation,
  };
  saveStore('user', JSON.stringify(_user));
};

export const GetMe = (token) => {
  return axios
    .get(variables.api.url + 'auth/me', {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
    .then(_checkStatus)
    .then((response) => {
      saveStore('user-profile', JSON.stringify(response.data.user));
      // if (response.data?.home === '/admin/CompanySwitch') {
      //   saveStore('user-profile', JSON.stringify(response.data));
      // } else if (response.data?.home === '/admin/dashboard') {
      //   saveStore('user-profile', JSON.stringify(response.data));
      // } else {
      //   saveStore('user-profile', JSON.stringify(response.data.home));
      // }
      return Promise.resolve(response.data);
    });
};

export const Login = (email, password) => {
  return axios
    .post(variables.api.url + 'account/login', {
      email,
      password,
    })
    .then(_checkStatus)
    .then((response) => {
      if (response.data.access_token) {
        SaveUserStore(
          response.data.access_token,
          response.data.expires_in,
          false
        );

        return GetMe(response.data.access_token);
      } else {
        throw new Error('Authentication failed');
      }
    });
};
export const ConfirmResetPassword = (email, key, password) => {
  return axios
    .post(variables.api.url + 'account/reset', {
      email,
      key,
      password,
    })
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      throw new Error(error.response.data.error);
    });
};

export const ResetPassword = (email) => {
  return axios
    .get(variables.api.url + 'account/reset/' + email)
    .then(_checkStatus)
    .then((response) => {
      return Promise.resolve(response.data);
    });
};
export const Logout = () => {
  saveStore('user', '');
  saveStore('user-profile', '');
  getCurrentUser();
};

export const getCurrentUser = () => {
  return getUser();
};
export const getCurrentProfile = () => {
  return getProfile();
};

export const isAuthenticated = () => {
  let userInfo = getUser();
  //console.log('is authenticated', userInfo);
  // Check whether the current time is past the
  // access token's expiry time

  if (!userInfo) {
    return false;
  }
  var expiryDate = new Date(userInfo.expiresAt).getTime();

  var now = new Date().getTime();
  return now < expiryDate;
};

const _checkStatus = (response) => {
  // raises an error in case response status is not a success
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else {
    var error = new Error(
      'Authentication',
      'Authentication failed, please confirm your username and password'
    );

    if (response.status === 451) {
      error = new Error('Permissions', 'Permissions failed');
    }

    //error.statusText = "Authentication failed, please confirm your username and password";
    throw error;
  }
};
