import React from 'react';
import PropTypes from 'prop-types';

import EmployeesList from 'components/Employees/List';
import Layout from 'components/Layout/Layout';
import { EmojiPeople } from '@material-ui/icons';
export default function EquipmentsListPage(props) {
  const { window } = props;

  return (
    <Layout window={window} title="Assessments" icon={<EmojiPeople />}>
      <EmployeesList />
    </Layout>
  );
}

EquipmentsListPage.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};
