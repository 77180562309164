import React, { useState } from 'react';
import Button from 'components/CustomButtons/Button.js';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { addEmployees } from 'services/user';
import PropTypes from 'prop-types';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Typography>{children}</Typography>}
    </div>
  );
}

export default function EmployeesAdd(props) {
  const { callBack, defaultEndExpected } = props;
  console.log('defaultEndExpected', defaultEndExpected);
  const [endExpected, setEndExpected] = useState(defaultEndExpected);
  const [loading, setLoading] = useState(false);
  const [showAddEmployeeResult, setShowAddEmployeeResult] = useState(false);
  const [resultEmployeesAdded, setResultEmployeesAdded] = useState([]);
  const [value, setValue] = useState(0);
  const defaultAddEmployee = { EmployeeEmailAdd: '', employeesFile: null };
  const [addEmployee, setAddEmployee] = useState(defaultAddEmployee);
  const [addEmployeeResultTotals, setAddEmployeeResultTotals] = useState({
    resultEmployeesTotal: 0,
    resultEmployeesTotalAdded: 0,
    resultEmployeesTotalInvalid: 0,
    resultEmployeesTotalPreviouslyAdded: 0,
  });

  const handleClose = () => {
    callBack();
  };

  const HandleEmployeeAdded = (res) => {
    var result = [];
    var totalEmployees = res.length;
    var totalAdded = 0;
    var totalInvalid = 0;
    var totalPreviouslyAdded = 0;
    for (let index = 0; index < res.length; index++) {
      const element = res[index];
      if (element.saved) totalAdded++;

      if (!element.valid) totalInvalid++;

      if (element.valid && !element.saved) totalPreviouslyAdded++;

      if (!element.saved) result.push(element);
    }
    setAddEmployee(defaultAddEmployee);
    setShowAddEmployeeResult(true);
    setResultEmployeesAdded(result);
    setAddEmployeeResultTotals({
      resultEmployeesTotal: totalEmployees,
      resultEmployeesTotalAdded: totalAdded,
      resultEmployeesTotalInvalid: totalInvalid,
      resultEmployeesTotalPreviouslyAdded: totalPreviouslyAdded,
    });
    setValue(2);
    setLoading(false);
  };

  const AddSingleEmployee = () => {
    setLoading(true);
    addEmployees(endExpected, addEmployee.EmployeeEmailAdd, null)
      .then((res) => {
        HandleEmployeeAdded(res);
      })
      .catch((err) => {
        setResultEmployeesAdded([]);
        setLoading(false);
      });
  };
  const AddMultipleEmployee = () => {
    setLoading(true);
    addEmployees(endExpected, null, addEmployee.employeesFile)
      .then((res) => {
        HandleEmployeeAdded(res);
      })
      .catch((err) => {
        setResultEmployeesAdded([]);
        setLoading(false);
      });
  };

  const AddUploadEmployee = (event) => {
    var file = event.target.files[0];

    setAddEmployee({
      EmployeeEmailAdd: '',
      employeesFile: file,
    });
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div>
      <Dialog
        open={props.data.show}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Add Employees</DialogTitle>
        <DialogContent>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
          >
            <Tab label="Single" {...a11yProps(0)} />
            <Tab label="Multiple" {...a11yProps(1)} />
            <Tab label="Result" {...a11yProps(1)} style={{ display: 'none' }} />
          </Tabs>
          <TabPanel value={value} index={0}>
            <DialogContentText>
              Add employee by providing his email
              <br />
              <TextField
                name="email"
                type="email"
                onChange={(e) =>
                  setAddEmployee({
                    EmployeeEmailAdd: e.target.value,
                    employeesFile: null,
                  })
                }
                defaultValue={addEmployee.EmployeeEmailAdd}
                placeholder="Email"
              />
              <br />
              <label>End Expected</label>
              <br />
              <TextField
                name="endexpected"
                type="date"
                onChange={(e) => setEndExpected(e.target.value)}
                defaultValue={endExpected}
                placeholder="End Expected"
              />
              <Button
                loading={loading}
                onClick={AddSingleEmployee}
                color="primary"
              >
                Add
              </Button>
            </DialogContentText>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <DialogContentText>
              Add employee by providing his a CSV File
              <input
                name="employeesFile"
                onChange={(ev) => AddUploadEmployee(ev)}
                type="file"
              />{' '}
              <br />
              <label>End Expected</label>
              <br />
              <TextField
                name="endexpected2"
                type="date"
                onChange={(e) => setEndExpected(e.target.value)}
                defaultValue={endExpected}
                placeholder="End Expected"
              />
              <br />
              <Button
                color="primary"
                loading={loading}
                onClick={() => AddMultipleEmployee()}
              >
                Upload
              </Button>
              <div>
                <br />
                <ContactSupportIcon /> Instruction :
                <br />
                <ul>
                  <li>
                    Download the{' '}
                    <a href="/templates/UploadEmployees.csv" target="_blank">
                      example file
                    </a>
                  </li>
                  <li>
                    Without chaning the structure of the file, add your employee
                    emails into that template and save the file locally
                  </li>
                  <li>Browse the file on the above field</li>
                  <li>Press upload</li>
                  <li>
                    You'll receive confirmation of the added users, and a
                    notification if any users failed to be added
                  </li>
                  <li>
                    The activation process will automatically start and your
                    employees will receive an activation email
                  </li>
                </ul>
              </div>
            </DialogContentText>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <DialogContentText>Result</DialogContentText>
            {showAddEmployeeResult && (
              <>
                <ul>
                  <li>
                    {addEmployeeResultTotals.resultEmployeesTotal} employees
                    provided
                  </li>
                  <li>
                    {addEmployeeResultTotals.resultEmployeesTotalAdded}{' '}
                    employees sucessfully added{' '}
                  </li>
                  <li>
                    {addEmployeeResultTotals.resultEmployeesTotalInvalid}{' '}
                    employees with invalid email address{' '}
                  </li>
                  <li>
                    {
                      addEmployeeResultTotals.resultEmployeesTotalPreviouslyAdded
                    }{' '}
                    employees that had previously been added{' '}
                  </li>
                </ul>
                {resultEmployeesAdded && resultEmployeesAdded.length > 0 && (
                  <div>
                    <h4>Employees not added</h4>
                    <table>
                      <thead>
                        <tr>
                          <th>Email</th>
                        </tr>
                      </thead>
                      <tbody>
                        {resultEmployeesAdded &&
                          resultEmployeesAdded.map((user) => (
                            <tr key={user.email}>
                              <td>{user.email}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </>
            )}
          </TabPanel>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

EmployeesAdd.propTypes = {
  callBack: PropTypes.func,
  defaultEndExpected: PropTypes.string,
};
