import React, { useState, useEffect } from 'react';
import Button from 'components/CustomButtons/Button.js';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import { AddUser } from 'services/user';
import { Checkbox } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
export default function UserEdit(props) {
  var { callBack, cancelCallback } = props;
  const [loading, setLoading] = useState(false);
  var [id, setId] = useState();
  var [iserror, setIsError] = useState(false);
  var [email, setEmail] = useState(null);
  var [enabled, setEnabled] = useState();
  var [administrator, setAdministrator] = useState();
  const handleClose = () => {
    setIsError(false);
    cancelCallback();
  };

  useEffect(() => {
    setId(props.data.id);
    setEmail(props.data.email);
    setEnabled(props.data.enabled);
    setAdministrator(props.data.administrator);
    setIsError(false);
  }, [props.data]);
  const validate = (email) => {
    var regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return regex.test(String(email).toLowerCase());
  };
  const AddUsers = () => {
    setIsError(false);
    if (email === '' || validate(email) === false) {
      setIsError(true);
      return;
    }

    setLoading(true);
    var body = {
      id: id,
      email: email,
      administrator: administrator,
      enabled: enabled,
    };
    AddUser(body)
      .then(() => {
        setLoading(false);
        callBack();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <Dialog
      open={props.data.show}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Supervisor</DialogTitle>
      <DialogContent>
        <GridContainer>
          <GridItem xs={12}>
            <TextField
              name="email"
              label="Email"
              required
              multiline
              fullWidth
              error={iserror}
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              placeholder="Email"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="administrator"
                  label="Review Assessments"
                  multiline
                  fullWidth
                  onChange={(e) => setAdministrator(!administrator)}
                  checked={administrator}
                  placeholder="Review Assessments"
                />
              }
              label="Review Assessments"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="enabled"
                  label="Enabled"
                  multiline
                  fullWidth
                  onChange={(e) => setEnabled(!enabled)}
                  checked={enabled}
                  placeholder="Enabled"
                />
              }
              label="Enabled"
            />
          </GridItem>
        </GridContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>{' '}
        <Button loading={loading} onClick={AddUsers} color="primary">
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}

UserEdit.propTypes = {
  callBack: PropTypes.func,
  data: PropTypes.object,
};
