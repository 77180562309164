import { grayColor } from 'assets/jss/material-kit-react.js';
const cardFooterStyle = {
  cardFooter: {
    display: 'flex',
    alignItems: 'right',
    backgroundColor: 'transparent',
    paddingTop: '0.9375rem',
    paddingBottom: '0.9375rem',

    padding: '0.9375rem 1.875rem',
  },
  cardFooter2: {
    // display: 'flex',
    // alignItems: 'right',
    // backgroundColor: 'transparent',
    //paddingTop: '0.9375rem',
    paddingBottom: '0.9375rem',

    //padding: '0.9375rem 1.875rem',
    marginTop: '20px',
    '& a': {
      color: grayColor,
    },
    '& svg': {
      position: 'relative',
      top: '7px',
    },
  },
};

export default cardFooterStyle;
