import React from 'react';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
// @material-ui/icons

// core components
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import styles from 'assets/jss/material-kit-react/views/dashboardStyle.js';

const useStyles = makeStyles(styles);

export default function GraphCard(props) {
  const classes = useStyles();
  const { children, title } = props;

  return (
    <Card>
      <CardHeader className={classes.GraphTitleGraph}>{title}</CardHeader>
      <CardBody>{children}</CardBody>
    </Card>
  );
}

GraphCard.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
};
