import React, { useContext, useState } from 'react';
import { Player, BigPlayButton } from 'video-react';

import { StepsContext } from './Context';

export default function VideoTraining() {
  const [context, setContext] = useContext(StepsContext);
  const [videoTraining, setVideoTraining] = useState(context[1].confirmVideo);

  const handleSave = (e) => {
    let confirm = e.target.checked;
    var newContext = context;
    newContext[1].confirmVideo = confirm;
    setVideoTraining(confirm);
    setContext(newContext);
  };

  return (
    <>
      <h2>Video</h2>
      <p>
        The following video will help you to set up your desk correctly in order
        to minimise the risk of injury.
      </p>

      <Player src="/video/capella.mp4">
        <BigPlayButton position="center" />
      </Player>
      <div>
        <br />
        <br />
        <div style={{ float: 'left' }}>
          {' '}
          <input
            type="checkbox"
            name="VideoConfirmation"
            id="VideoConfirmation"
            checked={videoTraining}
            required={true}
            className="checkbox-inline"
            onChange={(e) => handleSave(e)}
            style={{ width: '20px', height: '20px' }}
          />
        </div>
        <div>
          Please click here to confirm that you have watched and understood this
          training video before moving on with your assessment.
          <br />
          If you have any questions, please add them to the text box at the end
          of the questionnaire on the next page.
        </div>
      </div>
    </>
  );
}
