import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
export default function Question(props) {
  var _tooltip = '';
  //console.log(tooltip)
  if (!(props.tooltip === undefined)) {
    _tooltip = (
      <span>
        <Tooltip id={props.name} placement="top" title={props.tooltip}>
          <button data-tip data-for={props.name}>
            *
          </button>
        </Tooltip>
        {/* <ReactTooltip id={name} place="top" effect="solid">
          {tooltip}
        </ReactTooltip> */}
      </span>
    );
  }
  return (
    <>
      {props.question}
      {_tooltip}
    </>
  );
}
Question.propTypes = {
  question: PropTypes.string,
  tooltip: PropTypes.string,
  name: PropTypes.string,
};
