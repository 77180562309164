import React from 'react';
import PropTypes from 'prop-types';

import RemindersList from 'components/Reminders/List';
import Layout from 'components/Layout/Layout';
import { Event } from '@material-ui/icons';
export default function UsersListPage(props) {
  const { window } = props;

  return (
    <Layout window={window} title="Reminders" icon={<Event />}>
      <RemindersList />
    </Layout>
  );
}

UsersListPage.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};
