import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/CustomButtons/Button.js';
import Layout from 'components/Layout/Layout';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import PrimaryTypo from 'components/Typography/Primary';
import SaveIcon from '@material-ui/icons/Save';
import { UpdateProfile, getUserProfile } from 'services/user';
import Loading from 'components/Loading';
import { AccountCircle } from '@material-ui/icons';
import SnackbarContent from 'components/Snackbar/SnackbarContent.js';

export default function AccountPage(props) {
  const { window } = props;
  const defaultValues = {
    name: '',
    email: '',
    firstName: '',
    lastName: '',
    password: '',
    passwordConfirm: '',
    jobTitle: '',
    department: '',
    daysRemote: '',
  };
  const [formValues, setFormValues] = useState(defaultValues);
  const [errorMessage, setErrorMessage] = useState({ save: null });
  const [loading, setLoading] = useState(true);
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormValues({
      ...formValues,
      [name]: value,
    });
  };
  useEffect(() => {
    getUserProfile().then((response) => {
      var data = response.data;
      setFormValues({
        company: '',
        password: '',
        email: data.email,
        firstName: data.first_name,
        lastName: data.last_name,
        jobTitle: data.job_title,
        department: data.department,
        daysRemote: data.days_remote,
      });
      setLoading(false);
    });
  }, []);
  const save = () => {
    if (validate()) {
      //send data
      var updated = UpdateProfile({
        FirstName: formValues.firstName,
        LastName: formValues.lastName,
        Password: formValues.password,
      });
      let errorMsg = '';
      if (updated) {
        errorMsg = 'Profile updated successfully';
      } else {
        errorMsg =
          'A error has occoured while updating your profile, please try again later';
      }
      setErrorMessage({ save: <>{errorMsg}</> });
    }
  };

  const validate = () => {
    let errorMsg = {};
    var valid = true;

    if (formValues.password === '' || formValues.password === null) {
      errorMsg.password = 'Passwords required';
      valid = false;
    }
    if (formValues.password !== formValues.passwordConfirm) {
      errorMsg.password = "Passwords don't match";
      valid = false;
    }
    if (formValues.email === '' || formValues.email === null) {
      errorMsg.email = 'Email required';
      valid = false;
    }
    if (formValues.firstName === '' || formValues.firstName === null) {
      errorMsg.firstName = 'First Name required';
      valid = false;
    }
    if (formValues.lastName === '' || formValues.lastName === null) {
      errorMsg.lastName = 'Last Name required';
      valid = false;
    }
    errorMsg.save = null;
    if (valid === false) {
      setErrorMessage(errorMsg);
    }
    return valid;
  };
  return (
    <Layout window={window} title="My Account" icon={<AccountCircle />}>
      <PrimaryTypo>
        You can use this page to change your password or edit your account
        details{' '}
      </PrimaryTypo>
      <Loading show={loading} />
      {!loading && (
        <FormControl>
          <GridContainer spacing={3}>
            <GridItem md={12}>
              <TextField
                id="Email"
                name="email"
                label="Email address"
                variant="outlined"
                required
                fullWidth
                error={!!errorMessage.email}
                helperText={errorMessage.email && errorMessage.email}
                defaultValue={formValues.email}
                onChange={handleInputChange}
              />
            </GridItem>
            <GridItem md={6}>
              <TextField
                id="First_name"
                name="firstName"
                label="First Name"
                variant="outlined"
                required
                defaultValue={formValues.firstName}
                onChange={handleInputChange}
                fullWidth
                error={!!errorMessage.firstName}
                helperText={errorMessage.firstName && errorMessage.firstName}
              />
            </GridItem>
            <GridItem md={6}>
              <TextField
                id="Last_name"
                name="lastName"
                label="Last Name"
                variant="outlined"
                required
                fullWidth
                defaultValue={formValues.lastName}
                onChange={handleInputChange}
                error={!!errorMessage.lastName}
                helperText={errorMessage.lastName && errorMessage.lastName}
              />
            </GridItem>

            <GridItem md={6}>
              <TextField
                id="password"
                name="password"
                type="password"
                label="New Password"
                variant="outlined"
                required
                fullWidth
                defaultValue={formValues.password}
                onChange={handleInputChange}
                error={!!errorMessage.password}
                helperText={errorMessage.password && errorMessage.password}
              />
            </GridItem>
            <GridItem md={6}>
              <TextField
                id="passwordConfirm"
                name="passwordConfirm"
                type="password"
                label="Confirm New Password"
                variant="outlined"
                required
                fullWidth
                defaultValue={formValues.passwordConfirm}
                onChange={handleInputChange}
                error={!!errorMessage.passwordConfirm}
                helperText={
                  errorMessage.passwordConfirm && errorMessage.passwordConfirm
                }
              />
            </GridItem>
            <GridItem md={8}>
              <Button onClick={() => save()}>
                <SaveIcon />
                Update Details
              </Button>
            </GridItem>
            <GridItem md={8}>
              {errorMessage.save !== null && (
                <SnackbarContent
                  message={errorMessage.save}
                  close
                  color="info"
                  icon="info_outline"
                />
              )}
            </GridItem>
          </GridContainer>
        </FormControl>
      )}
    </Layout>
  );
}

AccountPage.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};
