import React, { useEffect, useState } from 'react';
import MUIDataTable from 'mui-datatables';
import Loading from 'components/Loading';
import { Equipments } from 'services/user';
import { Edit } from '@material-ui/icons';
import EquipmentAdd from './Add';
import DatatableCustomToolbar from 'components/Editor/DatatableCustomToolbar';
export default function EquipmentsList() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  var defaultData = {
    id: null,
    name: '',
    show: false,
  };
  const [equipmentData, setEquipmentData] = useState(defaultData);

  // const [questionaires, setQuestionaires] = useState(null);
  // const [departments, setDepartments] = useState(null);
  // const [addAction, showAddAction] = useState(false);

  const columns = [
    {
      label: 'ID',
      name: 'id',
      options: {
        filter: false,
        display: false,
      },
    },
    {
      name: 'Edit',
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Edit
              onClick={() => {
                setEquipmentData({
                  id: tableMeta.rowData[0],
                  name: tableMeta.rowData[2],
                  show: true,
                });
              }}
            >
              Edit
            </Edit>
          );
        },
      },
    },
    {
      label: 'Name',
      name: 'name',
      options: {
        filter: false,
      },
    },
  ];

  const options = {
    selectableRows: 'none',
    filterType: 'checkbox',
    rowsPerPage: 100,
    customToolbar: () => {
      return (
        <DatatableCustomToolbar
          onClick={() => {
            setEquipmentData({
              id: null,
              name: '',
              show: true,
            });
          }}
        />
      );
    },
  };
  const RefreshDataCloseModal = () => {
    setEquipmentData({
      id: null,
      name: '',
      show: false,
    });
    LoadData();
  };
  const LoadData = () => {
    Equipments().then((res) => {
      var _data = res.data;

      if (_data.length === 0) {
        _data = [];
      }

      setData(_data);
      setLoading(false);
    });
  };
  useEffect(() => {
    LoadData();
  }, []);
  return (
    <>
      <Loading show={loading} />
      <EquipmentAdd
        callBack={RefreshDataCloseModal}
        data={equipmentData}
        cancelCallback={() => setEquipmentData(defaultData)}
      />
      {!loading && (
        <MUIDataTable
          title="Equipment List"
          data={data}
          columns={columns}
          options={options}
        />
      )}
    </>
  );
}
