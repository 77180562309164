import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import EmailIcon from '@material-ui/icons/Email';
import { SendTargetedReminders } from 'services/user';
import { withStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import Loading from 'components/Loading';
const defaultToolbarSelectStyles = {
  iconButton: {},
  iconContainer: {
    marginRight: '24px',
  },
  inverseIcon: {
    transform: 'rotate(90deg)',
  },
};

class CustomToolbarSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = { loading: false };
  }

  handleClickInverseSelection = () => {
    const nextSelectedRows = this.props.displayData.reduce(
      (nextSelectedRows, _, index) => {
        if (
          !this.props.selectedRows.data.find(
            (selectedRow) => selectedRow.index === index
          )
        ) {
          nextSelectedRows.push(index);
        }

        return nextSelectedRows;
      },
      []
    );

    this.props.setSelectedRows(nextSelectedRows);
  };

  handleClickDeselectAll = () => {
    this.props.setSelectedRows([]);
  };

  handleClickBlockSelected = () => {
    //console.log(this.props.displayData[0].data);
    this.setState({
      loading: true,
    });
    var ids = this.props.selectedRows.data.map(
      (row) => this.props.displayData[row.dataIndex].data[0]
    );
    //console.log('ids to send ', ids);
    SendTargetedReminders(ids).then(() => {
      this.setState({
        loading: false,
      });
      this.props.callback();
    });
  };

  render() {
    const { classes } = this.props;

    if (this.state.loading === true) {
      return (
        <div className={classes.iconContainer}>
          <Loading show={true} />
        </div>
      );
    } else {
      return (
        <div className={classes.iconContainer}>
          <Tooltip title={'Deselect ALL'}>
            <IconButton
              className={classes.iconButton}
              onClick={this.handleClickDeselectAll}
            >
              <IndeterminateCheckBoxIcon className={classes.icon} />
            </IconButton>
          </Tooltip>
          <Tooltip title={'Inverse selection'}>
            <IconButton
              className={classes.iconButton}
              onClick={this.handleClickInverseSelection}
            >
              <CompareArrowsIcon
                className={[classes.icon, classes.inverseIcon].join(' ')}
              />
            </IconButton>
          </Tooltip>
          <Tooltip title={'Send Emails'}>
            <IconButton
              className={classes.iconButton}
              onClick={this.handleClickBlockSelected}
            >
              <EmailIcon className={classes.icon} />
            </IconButton>
          </Tooltip>
        </div>
      );
    }
  }
}

export default withStyles(defaultToolbarSelectStyles, {
  name: 'CustomToolbarSelect',
})(CustomToolbarSelect);
