import React, { useState, useEffect } from 'react';
import Button from 'components/CustomButtons/Button.js';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { EquipmentAdd } from 'services/user';
import PropTypes from 'prop-types';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';

export default function EquipmentEdit(props) {
  var { callBack, cancelCallback } = props;
  const [loading, setLoading] = useState(false);
  var [id, setId] = useState();
  var [name, setName] = useState();

  const handleClose = () => {
    cancelCallback();
  };

  useEffect(() => {
    setId(props.data.id);
    setName(props.data.name);
  }, [props.data]);

  const AddEquipment = () => {
    setLoading(true);
    var body = {
      id: id,
      name: name,
    };
    EquipmentAdd(body)
      .then(() => {
        setLoading(false);
        callBack();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <Dialog
      open={props.data.show}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Equipment</DialogTitle>
      <DialogContent>
        <GridContainer>
          <GridItem xs={12}>
            <TextField
              name="name"
              label="Name"
              required
              multiline
              fullWidth
              onChange={(e) => setName(e.target.value)}
              defaultValue={name}
              placeholder="Name"
            />
          </GridItem>
        </GridContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>{' '}
        <Button loading={loading} onClick={AddEquipment} color="primary">
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}

EquipmentEdit.propTypes = {
  callBack: PropTypes.func,
  data: PropTypes.object,
};
