import React, { useEffect } from 'react';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import Icon from '@material-ui/core/Icon';
// @material-ui/icons
import Email from '@material-ui/icons/Email';
import SnackbarContent from 'components/Snackbar/SnackbarContent.js';
import { Login } from '../../services/auth';
import { api } from 'services/variables';
// core components
import Header from 'components/Header/Header.js';
import Footer from 'components/Footer/Footer.js';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardFooter from 'components/Card/CardFooter.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import styles from 'assets/jss/material-kit-react/views/loginPage.js';

import image from 'assets/img/bg7.jpg';
import { Link, useHistory, useLocation } from 'react-router-dom';
const useStyles = makeStyles(styles);

export default function LoginPage(props) {
  const location = useLocation();
  const history = useHistory();
  const [cardAnimaton, setCardAnimation] = React.useState('cardHidden');
  const [loading, setLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');
  setTimeout(function () {
    setCardAnimation('');
  }, 700);
  const classes = useStyles();
  const { ...rest } = props;
  useEffect(() => {
    var params = new URLSearchParams(location.search);

    var email = params.get('email');

    setUsername(email);
  }, [location]);
  const handleSubmit = () => {
    setLoading(true);
    setErrorMessage(null);
    Login(username, password)
      .then((result) => {
        history.push(result.home);
        setLoading(false);
      })
      .catch((er) => {
        var resMessage = 'Failed to login please verify your credentials';
        if (er.response && er.response.status === 451) {
          resMessage =
            'User does not have permission to login into this application';
        }
        setLoading(false);
        setErrorMessage(resMessage);
      });
  };
  const handleKeypress = (e) => {
    //it triggers by pressing the enter key
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };
  return (
    <div>
      <Header absolute color="transparent" brand="Domus" {...rest} />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: 'url(' + image + ')',
          backgroundSize: 'cover',
          backgroundPosition: 'top center',
        }}
      >
        <div className={classes.container}>
          <GridContainer justifyContent="center">
            <GridItem xs={12} sm={12} md={4}>
              <Card className={classes[cardAnimaton]}>
                <form className={classes.form}>
                  <CardHeader color="primary" className={classes.cardHeader}>
                    <h4>Login</h4>
                  </CardHeader>
                  <CardBody style={{ margin: '10px' }}>
                    <CustomInput
                      labelText="Username"
                      id="username"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: 'text',
                        value: username,
                        onKeyPress: (e) => handleKeypress(e),
                        onChange: (e) => setUsername(e.target.value),
                        endAdornment: (
                          <InputAdornment position="end">
                            <Email className={classes.inputIconsColor} />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <CustomInput
                      labelText="Password"
                      id="pass"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: 'password',
                        onChange: (e) => setPassword(e.target.value),
                        onKeyPress: (e) => handleKeypress(e),
                        endAdornment: (
                          <InputAdornment position="end">
                            <Icon className={classes.inputIconsColor}>
                              lock_outline
                            </Icon>
                          </InputAdornment>
                        ),
                        autoComplete: 'off',
                      }}
                    />
                  </CardBody>
                  <CardFooter className={classes.cardFooter}>
                    <Button
                      simple
                      color="primary"
                      loading={loading}
                      size="lg"
                      onClick={() => handleSubmit()}
                    >
                      Login
                    </Button>
                  </CardFooter>

                  {errorMessage !== null && (
                    <SnackbarContent
                      message={errorMessage}
                      close
                      color="danger"
                      icon="info_outline"
                    />
                  )}
                  <CardFooter className={classes.cardFooter}>
                    <Link to="forgot">Having trouble signing in?</Link>
                  </CardFooter>
                </form>
                <br />
                <a
                  component="button"
                  variant="outlined"
                  color="primary"
                  size="lg"
                  style={{ marginLeft: '3%', width: '90%', paddingTop: '20px' }}
                  href={api.url + 'sso/o365'}
                  name="microsoft"
                  className={classes.sso}
                >
                  <div data-name="microsoft" className="sso-provider">
                    <div className="icon-wrapper">
                      <div className="icon-small">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          version="1.1"
                          viewBox="0 0 150 150"
                        >
                          <g>
                            <title>Layer 1</title>{' '}
                            <path
                              fill="#F0511C"
                              d="m17.17982,74.67l55.94,0c0,-18.67 0,-37.31 0,-55.94l-55.94,0l0,55.94z"
                              id="svg_1"
                            ></path>{' '}
                            <path
                              fill="#81CC2C"
                              d="m79.18018,74.471802l55.93,0c0,-18.67 0,-37.31 0,-55.94l-55.93,0c0,18.65 0,37.29 0,55.94z"
                              id="svg_2"
                            ></path>{' '}
                            <path
                              fill="#1EAEEF"
                              d="m16.99964,134.32l55.94,0c0,-18.67 0,-37.31 0,-55.94l-55.94,0l0,55.94z"
                              id="svg_3"
                            ></path>{' '}
                            <path
                              fill="#FBBC13"
                              d="m79,134.401081l55.94,0l0,-55.93l-55.94,0c0,18.64 0,37.28 0,55.93z"
                              id="svg_4"
                            ></path>
                          </g>
                        </svg>
                      </div>
                      <span>Continue with Microsoft</span>
                    </div>
                  </div>
                </a>

                <br />

                <a
                  component="button"
                  variant="outlined"
                  color="primary"
                  href={api.url + 'sso/google'}
                  name="google"
                  size="lg"
                  style={{ marginLeft: '3%', width: '90%', paddingTop: '20px' }}
                  className={classes.sso}
                >
                  <div data-name="google" className="sso-provider">
                    <div className="icon-wrapper">
                      <div className="icon-small">
                        <svg
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 48 48"
                          className="abcRioButtonSvg"
                        >
                          <g>
                            <path
                              fill="#EA4335"
                              d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
                            ></path>
                            <path
                              fill="#4285F4"
                              d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
                            ></path>
                            <path
                              fill="#FBBC05"
                              d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
                            ></path>
                            <path
                              fill="#34A853"
                              d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
                            ></path>
                            <path fill="none" d="M0 0h48v48H0z"></path>
                          </g>
                        </svg>
                      </div>
                      <span>Continue with Google</span>
                    </div>
                  </div>
                </a>
                <br />
              </Card>
            </GridItem>
          </GridContainer>
        </div>
        <Footer whiteFont />
      </div>
    </div>
  );
}
