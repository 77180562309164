/*eslint-disable*/
import React from 'react';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// nodejs library that concatenates classes
import classNames from 'classnames';
// material-ui core components
import { List, ListItem, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { isAuthenticated } from 'services/context';
// @material-ui/icons
import Favorite from '@material-ui/icons/Favorite';
import CookieConsent from 'react-cookie-consent';

import styles from 'assets/jss/material-kit-react/components/footerStyle.js';

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont,
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont,
  });

  return (
    <>
      <footer className={footerClasses}>
        <CookieConsent
          style={{ background: '#ccc', position: 'absolute', bottom: '80px' }}
          location="none"
          buttonText="I accept"
        >
          This website uses cookies for security purposes only. For more
          information please review our <a href="/privacy">privacy policy</a>
        </CookieConsent>
        <div className={classes.container}>
          <div className={classes.left}>
            <List className={classes.list}>
              {isAuthenticated() && (
                <ListItem className={classes.inlineBlock}>
                  <a href="/signout" className={classes.block}>
                    Logout
                  </a>
                </ListItem>
              )}
              {!isAuthenticated() && (
                <ListItem className={classes.inlineBlock}>
                  <a href="/signin" className={classes.block}>
                    Login
                  </a>
                </ListItem>
              )}
              <ListItem className={classes.inlineBlock}>
                <a href="/privacy" className={classes.block}>
                  Privacy
                </a>
              </ListItem>
              <ListItem className={classes.inlineBlock}>
                <a
                  href="https://capella.ie/blog-2"
                  className={classes.block}
                  target="_blank"
                >
                  Blog
                </a>
              </ListItem>
            </List>
          </div>

          <Box
            className={classes.right}
            component="div"
            display="inline"
            p={1}
            m={1}
          >
            &copy; {1900 + new Date().getYear()}
          </Box>
          <Box
            className={classes.right}
            component="div"
            display="inline"
            p={1}
            m={1}
            style={{ marginTop: '-7px' }}
          >
            <svg
              version="1"
              viewBox="100 0 944.21 420.94"
              fill="#fff"
              height="40"
            >
              <g>
                <path d="M438.27,160.65c-11.13-5.32-22.54-5.74-33.97-0.76c-2.67,1.16-4.91,3-7.13,4.85c-0.5,0.41-0.93,1.26-1.65,0.97   c-0.87-0.36-0.5-1.33-0.52-2.05c-0.03-1.44-0.12-2.9,0.03-4.32c0.21-2.09-0.49-2.83-2.68-2.68c-9.23,0.66-18.47,1.19-27.71,1.76   c-7.03,0.44-14.06,0.92-21.1,1.28c-1.83,0.09,11.65,3.23,11.65,21.84c0.05,34.53,0.05,69.05,0.08,103.58   c0,21.32-13.54,21.33-11.7,21.32c23.19-0.04,46.38-0.03,69.57-0.03c0.34,0-16.12,0-16.12-22.09c0.01-17.64,0-35.29,0-52.93   c-0.01,0-0.01,0-0.02,0c0-18.49,0.04-36.99-0.06-55.48c-0.01-2.21,0.78-3.32,2.75-3.85c8.35-2.25,21.33,5.03,21.33,42.98   c0,44.56-18.8,46.16-18.9,47.3c-0.11,1.28,7.9,5.28,15.6,5.28c17.88,0,31.17-10.86,39.55-29.87c7.68-17.43,7.68-35.24,1.68-53.07   C455.36,173.99,448.61,165.59,438.27,160.65z"></path>
                <path d="M347.99,248.66c-0.85,0.37-1.74,0.71-2.64,0.92c-5.78,1.31-10.44,0.78-10.12-7.42c0.28-7.2,0.06-14.41,0.06-23.2   c-0.3-10.18,1-22.03-1.51-33.67c-2.69-12.5-9.5-21.77-21.94-26.2c-8.8-3.13-17.82-3.21-26.86-1.44   c-19.05,3.72-32.58,15.08-42.68,31.09c-0.33,0.52,10.64-10.69,30.43-10.69c8.78,0,18,3.99,19.81,10.91   c1.13,4.31,0.78,8.75,0.95,13.15c0.08,2.07-0.66,2.91-2.63,3.54c-10.08,3.23-20.38,5.93-29.92,10.63   c-26.32,12.97-42.2,44.32-69.07,44.32c-10.65,0-21.05-5.32-26.74-15.3c-5.86-10.28-8.19-21.37-9.18-32.72   c-1.48-16.85-1.48-33.7,2.01-50.41c1.66-7.94,4.05-15.65,8.53-22.68c3.22-5.04,7.22-9.45,13.21-11.88   c11.3-4.59,28.67-1.56,37.65,7.44c7.89,7.92,19.26,29.09,19.25,27.67c-0.04-5.68-0.02-11.36-0.02-17.05   c0-5.43-0.16-10.86,0.07-16.28c0.11-2.61-1.01-3.83-3.53-4.65c-18.16-5.88-36.81-7.5-55.87-5.44   c-13.43,1.45-26.07,5.07-37.49,11.93c-10.72,6.44-18.54,15.1-23.89,25.75c-5.71,11.37-7.95,23.37-7.98,35.79   c-0.04,13.68,1.72,27.04,8.5,39.48c6.26,11.48,15.38,20.43,27.9,26.24c13.72,6.37,28.08,9.19,42.85,9.19   c24.44,0,40.84-12.54,48.51-20.7c0.43,7.37,4.21,14.6,12.5,18.19c9.02,3.9,18.24,3.39,27.25-0.18c7.59-3.01,13.59-8.2,18.67-14.51   c0.38-0.48,0.63-1.4,1.46-1.12c0.62,0.21,0.48,1,0.6,1.57c2.06,9.78,9.13,15.99,19.18,16.67   C329.62,268.56,349.1,248.18,347.99,248.66z M291.24,243.98c-7.33,4.51-15.14,1.13-16.54-7.34c-2.18-13.19,5.4-20.95,16.93-24.02   c1.65-0.44,1.84,0.42,1.82,1.75c-0.04,4.33-0.02,8.65-0.02,12.98c0,4.24-0.08,8.49,0.03,12.72   C293.52,241.95,292.75,243.06,291.24,243.98z"></path>
                <path d="M553.8,164.48c-8.16-5.74-17.49-7.89-27.41-7.89c-20.21-0.01-35.67,8.37-45.81,25.93c-6.03,10.44-7.86,21.6-7.86,33.15   c0,27.5,24.5,50.71,49.46,50.71c36,0,50.29-36.18,48.98-37.02c-1.5-0.96-12.05,14.05-29.11,14.05   c-11.08,0.07-20.2-3.81-26.16-13.65c-4.09-6.76-5.64-14.24-6.44-21.97c-0.19-1.81,0.28-2.32,2.11-2.3   c9.59,0.08,19.19,0.04,28.78,0.04c9.34,0,18.68-0.1,28.02,0.07c2.48,0.05,3.15-0.72,3.24-3.06c0.1-2.56,0-5.08-0.24-7.61   C570.15,182.11,564.31,171.86,553.8,164.48z M533.96,191.57c-1.17,4.32-4.61,6.07-8.69,6.78c-4.8,0.83-9.66,0.43-14.49,0.51   c-1.19,0.02-1.68-0.48-1.65-1.67c0.05-1.86,0.01-3.73,0.01-5.48c0.46-6.54,0.92-12.96,3.03-19.13c0.61-1.77,1.35-3.47,2.42-5.01   c4.4-6.29,12.85-6.25,16.65,0.4C535.48,175.35,536.19,183.38,533.96,191.57z"></path>
                <path d="M831.75,248.66c-0.85,0.37-1.74,0.71-2.64,0.92c-5.78,1.31-10.44,0.78-10.12-7.42c0.28-7.2,0.06-14.41,0.06-23.2   c-0.3-10.18,1-22.03-1.51-33.67c-2.69-12.5-9.5-21.77-21.94-26.2c-8.8-3.13-17.82-3.21-26.86-1.44   c-19.05,3.72-32.58,15.08-42.69,31.09c-0.33,0.52,10.64-10.69,30.43-10.69c8.78,0,18.01,3.99,19.81,10.91   c1.13,4.31,0.78,8.75,0.95,13.15c0.08,2.07-0.66,2.91-2.63,3.54c-10.08,3.23-20.53,5.64-29.92,10.63   c-26.48,14.08-35.29,38.15-43.7,38.15c-6.33,0-7.95-6.14-7.95-6.14c-0.39-2.79-0.47-5.57-0.47-8.37c-0.01-19.51,0-35.64,0-55.14   c0,0-0.01,0-0.01,0c0-21.12-0.01-44.53,0.01-65.65c0-1.43,0.17-2.57-1.99-2.55c-17.32,0.08-34.65,0.06-51.97,0.02   c-1.39,0,9.59,1.67,11.29,12.48c0.75,3.69,0.83,7.41,0.84,11.14c0.03,33.76,0.05,61.65,0.04,95.41c0,1.74,0.1,3.37,0.28,4.92   c-4.99,6.7-11.59,13.88-16.9,13.88c-6.33,0-7.95-6.14-7.95-6.14c-0.39-2.79-0.47-5.57-0.47-8.37c-0.01-19.51,0-35.64,0-55.14   c0,0-0.01,0-0.01,0c0-21.12-0.01-44.53,0.01-65.65c0-1.43,0.17-2.57-1.99-2.55c-17.32,0.08-34.65,0.06-51.98,0.02   c-1.39,0,9.59,1.67,11.29,12.48c0.75,3.69,0.83,7.41,0.84,11.14c0.03,33.76,0.05,61.65,0.04,95.41c0,24.94,19.12,30.76,34.4,30.76   c17.45,0,27.81-10.94,33.63-20.95c4.38,16.71,20.19,20.95,33.22,20.95c18.08,0,28.54-11.74,34.24-22.02   c-0.4,8.23,3.24,16.8,12.51,20.81c9.02,3.9,18.24,3.39,27.25-0.18c7.59-3.01,13.59-8.2,18.67-14.51c0.38-0.48,0.63-1.4,1.46-1.12   c0.62,0.21,0.48,1,0.6,1.57c2.06,9.78,9.13,15.99,19.18,16.67C813.38,268.56,832.86,248.18,831.75,248.66z M775,243.98   c-7.33,4.51-15.14,1.13-16.54-7.34c-2.18-13.19,5.4-20.95,16.93-24.02c1.65-0.44,1.84,0.42,1.82,1.75   c-0.04,4.33-0.02,8.65-0.02,12.98c0,4.24-0.08,8.49,0.03,12.72C777.28,241.95,776.51,243.06,775,243.98z"></path>
              </g>
            </svg>
          </Box>
        </div>
      </footer>
    </>
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool,
};
