import React, { useState } from 'react';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
// nodejs library that concatenates classes
import Button from 'components/CustomButtons/Button.js';
import InputLabel from '@material-ui/core/InputLabel';
import { addAssessmentApproval } from 'services/user';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function Progress({ data, callback }) {
  const classes = useStyles();
  const [saving, setSaving] = useState(false);
  const [deskHeight, setDeskHeight] = useState(data.deskheightapproved ?? '');
  const [deskWidth, setDeskWidth] = useState(data.deskwidthapproved ?? '');
  const [monitorDistance, setMonitorDistance] = useState(
    data.monitordistanceapproved ?? ''
  );
  const [monitorHeight, setMonitorHeight] = useState(
    data.monitorheightapproved ?? ''
  );

  const savePhotosAproval = () => {
    var body = {
      assessmentid: data.id,
      deskheightapproved: deskHeight,
      deskwidthapproved: deskWidth,
      monitordistanceapproved: monitorDistance,
      monitorheightapproved: monitorHeight,
      type: 'PhotosAproval',
    };
    setSaving(true);
    addAssessmentApproval(body).then(() => {
      setSaving(false);
      callback();
    });
    //save
  };
  return (
    <GridContainer style={{ paddingTop: '20px' }}>
      <GridItem xs={5} sm={5} md={5}>
        <InputLabel id="demo-simple-select-helper-label">
          Desk Height Compliant
        </InputLabel>
        <Select
          className={classes.formControl}
          id="deskHeight"
          label="Desk Height Compliant"
          minWidth="150"
          fullWidth
          value={deskHeight}
          onChange={(e) => setDeskHeight(e.target.value)}
          helperText="Please select compliance for desk height"
        >
          <MenuItem key="na" value="">
            N/A
          </MenuItem>
          <MenuItem key="approved" value="1">
            Approved
          </MenuItem>
          <MenuItem key="notapproved" value="0">
            Not Approved
          </MenuItem>
        </Select>
      </GridItem>
      <GridItem xs={5} sm={5} md={5}>
        <InputLabel id="demo-simple-select-helper-label">
          Desk Width Compliant
        </InputLabel>
        <Select
          className={classes.formControl}
          id="deskwidth"
          label="Desk Width Compliant"
          minWidth="150"
          fullWidth
          value={deskWidth}
          onChange={(e) => setDeskWidth(e.target.value)}
          helperText="Please select compliance for desk width"
        >
          <MenuItem key="na" value="">
            N/A
          </MenuItem>
          <MenuItem key="approved" value="1">
            Approved
          </MenuItem>
          <MenuItem key="notapproved" value="0">
            Not Approved
          </MenuItem>
        </Select>
      </GridItem>
      <GridItem xs={5} sm={5} md={5}>
        <InputLabel id="demo-simple-select-helper-label">
          Monitor Distance Compliant
        </InputLabel>
        <Select
          className={classes.formControl}
          id="monitorDistance"
          label="Monitor Distance Compliant"
          minWidth="150"
          fullWidth
          value={monitorDistance}
          onChange={(e) => setMonitorDistance(e.target.value)}
          helperText="Please select compliance for monitor distance"
        >
          <MenuItem key="na" value="">
            N/A
          </MenuItem>
          <MenuItem key="approved" value="1">
            Approved
          </MenuItem>
          <MenuItem key="notapproved" value="0">
            Not Approved
          </MenuItem>
        </Select>
      </GridItem>
      <GridItem xs={5} sm={5} md={5}>
        <InputLabel id="demo-simple-select-helper-label">
          Monitor Height Compliant
        </InputLabel>
        <Select
          className={classes.formControl}
          id="monitorHeight"
          label="Monitor Height Compliant"
          minWidth="150"
          fullWidth
          value={monitorHeight}
          onChange={(e) => setMonitorHeight(e.target.value)}
          helperText="Please select compliance for monitor height"
        >
          <MenuItem key="na" value="">
            N/A
          </MenuItem>
          <MenuItem key="approved" value="1">
            Approved
          </MenuItem>
          <MenuItem key="notapproved" value="0">
            Not Approved
          </MenuItem>
        </Select>
      </GridItem>
      <GridItem md={12}>
        <Button
          variant="outlined"
          loading={saving}
          color="primary"
          onClick={savePhotosAproval}
        >
          Save
        </Button>
      </GridItem>
    </GridContainer>
  );
}

Progress.propTypes = {
  data: PropTypes.object,
  callback: PropTypes.func,
};
