import React from 'react';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
// @material-ui/icons

// core components
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Loading from 'components/Loading';
import Card from 'components/Card/Card.js';
import CardFooterStats from 'components/Card/CardFooterStats.js';
import styles from 'assets/jss/material-kit-react/views/dashboardStyle.js';

import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
const useStyles = makeStyles(styles);

export default function StatsCard(props) {
  const classes = useStyles();
  const {
    statsValue,
    bigIcon,
    statsLink,
    statsText,
    loading,
    statsIconText,
    color,
  } = props;

  // <CardHeader stats icon>
  return (
    <Card color={color}>
      <GridContainer>
        <GridItem md={3} sm={2} className={classes.CardIcon}>
          {bigIcon}
        </GridItem>
        <GridItem md={9} sm={10} className={classes.CardContent}>
          <GridItem md={12} className={classes.GraphTitle}>
            {statsText}
          </GridItem>

          <Loading show={loading} size={25} />
          <GridItem md={12} className={classes.cardTitle}>
            {' '}
            {statsValue}
          </GridItem>
        </GridItem>
      </GridContainer>
      <CardFooterStats className="" stats>
        <div className={classes.stats}>
          <a href={statsLink}>
            {statsIconText} <ChevronRightIcon />
          </a>
        </div>
      </CardFooterStats>
    </Card>
  );
}

StatsCard.propTypes = {
  statsIcon: PropTypes.node,
  statsIconText: PropTypes.string,
  statsLink: PropTypes.string,
  statsText: PropTypes.string,
  statsValue: PropTypes.string,
  loading: PropTypes.bool,
  icon: PropTypes.node,
  color: PropTypes.string,
};
