import React, { useState, useEffect } from 'react';
import Card from 'components/Card/Card';
import Button from 'components/CustomButtons/Button.js';
import CardHeader from 'components/Card/CardHeader';
import {
  getAssessment,
  getAssessmentPhotos,
  getHazards,
  getEquipments,
  RemoveHazard,
  RemoveEquipment,
  ConvertEquipmentRequired,
} from 'services/user';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ApprovalDetail from './ApprovalDetail';
import RiskTable from 'assets/img/risktable.png';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Loading from 'components/Loading';
import { canEditAssessment } from 'services/context';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DisplayQuestionaire from 'components/Questionaire/Display';
import DisplayPhotos from 'components/Photos/Display';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  noPrint: {
    '@media print': {
      display: 'none',
    },
  },
}));

export default function ShowAssessment({ id }) {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [showDetail, setShowDetail] = useState(false);

  const [assessmentInfo, setAssessmentInfo] = useState(null);
  const [hazards, setHazards] = useState([]);
  const [equipments, setEquipments] = useState([]);
  const showAnalysis = () => {
    if (
      assessmentInfo.reviewed === 1 ||
      assessmentInfo.reviewed === '1' ||
      canEditAssessment()
    ) {
      return '';
    } else {
      return 'none';
    }
  };
  const getDescription = (value) => {
    if (value === '' || value === null) {
      return 'N/A';
    }

    return value === '1' || value === 1 ? 'Approved' : 'Not Approved';
  };
  const LoadInfo = () => {
    Promise.all([
      getAssessment(id),
      getAssessmentPhotos(id),
      getHazards(id),
      getEquipments(id),
    ]).then((values) => {
      let assessmentData = values[0].data[0];
      let photosInfo = values[1].data;
      let hazardInfo = values[2].data;
      let equipInfo = values[3].data;

      let _questions = JSON.parse(assessmentData.questions);
      let _reviewed = assessmentData.reviewed;
      let _recomendation = assessmentData.recomendation;
      let _autorecomendation = assessmentData.auto_recomendation;
      let _consultation_recomended = assessmentData.consultation_recomended;
      let _high_risk = assessmentData.high_risk;
      let _quest = assessmentData.step_2;
      try {
        if (_quest !== null && _quest !== '') {
          _quest = JSON.parse(_quest)[0];
        }
      } catch (error) {
        _quest = null;
      }
      setAssessmentInfo({
        id: id,
        questions: _questions,
        firstname: assessmentData.first_name,
        jobTitle: assessmentData.job_title,
        name: assessmentData.first_name + ' ' + assessmentData.last_name,
        department: assessmentData.department,
        daysremote: assessmentData.days_remote,
        dtClosed: assessmentData.closed_at,
        selfdeclarationcompliant: assessmentData.self_declaration_compliant,
        photocompliant: assessmentData.photo_compliant,
        frontimage: photosInfo.front,
        sideimage: photosInfo.side,
        questionaire: _quest,
        assessmentid: id,
        deskheightapproved: assessmentData.desk_height_approved,
        deskwidthapproved: assessmentData.desk_width_approved,
        monitordistanceapproved: assessmentData.monitor_distance_approved,
        monitorheightapproved: assessmentData.monitor_height_approved,
        reviewed: _reviewed,
        recomendation: _recomendation,
        autorecomendation: _autorecomendation,
        consultation_recomended: _consultation_recomended,
        high_risk: _high_risk,
        equipments: equipInfo.equipments,
        risks: hazardInfo.risks,
      });
      setEquipments(equipInfo.assessmentEquipment);
      setHazards(hazardInfo.assessmentHazard);

      setLoading(false);
    });
  };
  const LoadData = () => {
    setLoading(true);

    LoadInfo();
    setShowDetail(canEditAssessment());
  };

  useEffect(() => {
    LoadData();
  }, []);

  const getHazardLevel = (total, hazardId) => {
    var likeRating = 'Low';
    if (total > 6 && total < 13) {
      likeRating = 'Medium';
    } else if (total >= 13) {
      likeRating = 'High';
    }

    if (canEditAssessment()) {
      return (
        <>
          <span>{likeRating}</span>
          <Tooltip title="Delete">
            <IconButton aria-label="delete">
              <DeleteIcon onClick={() => removeHazard(hazardId)} />
            </IconButton>
          </Tooltip>
        </>
      );
    } else {
      return likeRating;
    }
  };
  const getHazardBackground = (total) => {
    var likeRating_fill = '#91D050';
    if (total > 6 && total < 13) {
      likeRating_fill = '#4472C4';
    } else if (total >= 13) {
      likeRating_fill = '#FF0001';
    }
    return likeRating_fill;
  };
  const removeHazard = (hazard_id) => {
    var body = {
      hazard_id: hazard_id,
    };
    RemoveHazard(id, body).then(() => {
      setHazards(hazards.filter((item) => item.id !== hazard_id));
    });
  };
  const removeEquip = (equipId) => {
    RemoveEquipment(assessmentInfo.id, equipId).then(() => {
      setEquipments(equipments.filter((item) => item.equipment_id !== equipId));
    });
  };
  const getEquipmentsDescription = (id) => {
    if (equipments === null || equipments === undefined) return <></>;

    var item = assessmentInfo.equipments.find((c) => id === c.id);
    if (canEditAssessment()) {
      return (
        <>
          <span>{item.name}</span>
          <Tooltip title="Delete">
            <IconButton aria-label="delete">
              <DeleteIcon onClick={() => removeEquip(item.id)} />
            </IconButton>
          </Tooltip>
        </>
      );
    } else {
      return item.name;
    }
  };
  return (
    <GridContainer style={{ width: '100%' }}>
      <GridItem>
        <Loading show={loading} />
      </GridItem>
      {assessmentInfo !== null && !loading && (
        <>
          <GridItem md={10}>
            <span
              style={{
                fontSize: '30px',
                marginLeft: '20px',
              }}
            >
              Risk-Assessment : {assessmentInfo.name}
            </span>
          </GridItem>
          <GridItem md={2}>
            <Button className={classes.noPrint} onClick={() => window.print()}>
              Print
            </Button>
          </GridItem>

          <GridItem md={6}>
            <p style={{ fontSize: '20px' }}>
              <b>Department: </b>
              {assessmentInfo.department}
            </p>
            <p style={{ fontSize: '20px' }}>
              <b>Consultation Completed:</b>
              <span>{assessmentInfo.dtClosed}</span>
            </p>
          </GridItem>
          <GridItem md={6}>
            <p style={{ fontSize: '20px' }}>
              <b>Days Remote per Week:</b>
              <span>{assessmentInfo.daysremote}</span>
            </p>
            <p style={{ fontSize: '20px' }}>
              <b>Job Title: </b>
              {assessmentInfo.jobTitle}
            </p>
            {/* <Row>
                    {this.getHighRiskDetailIcons()}
                    {this.getConsultRecDetailIcons()}
                    {this.getConsultReqDetailIcons()}
                  </Row> */}
          </GridItem>
          <GridItem md={4}>
            <Card>
              <CardHeader color="assessment">Office Measurements</CardHeader>
              <TableContainer>
                <Table
                  style={{ maxHeight: '400px' }}
                  stickyHeader
                  aria-label="Office Measurements"
                >
                  <TableBody>
                    <TableRow>
                      <TableCell>DESK HEIGHT:</TableCell>
                      <TableCell align="right">
                        {getDescription(assessmentInfo.deskheightapproved)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>DESK WIDTH:</TableCell>
                      <TableCell align="right">
                        {getDescription(assessmentInfo.deskwidthapproved)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>MONITOR DISTANCE:</TableCell>
                      <TableCell align="right">
                        {getDescription(assessmentInfo.monitordistanceapproved)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>MONITOR HEIGHT:</TableCell>
                      <TableCell align="right">
                        {getDescription(assessmentInfo.monitorheightapproved)}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>
          </GridItem>
          <GridItem md={8}>
            <Card>
              <CardHeader color="assessment">Photos</CardHeader>
              <DisplayPhotos
                show={true}
                front={assessmentInfo.frontimage}
                side={assessmentInfo.sideimage}
                showMeasure={false}
              />
            </Card>
          </GridItem>
          <GridItem md={12}>
            <Card>
              <CardHeader color="assessment">Self Assessment</CardHeader>
              <DisplayQuestionaire data={assessmentInfo} show={true} />
            </Card>
          </GridItem>
          <GridItem md={12} style={{ display: showAnalysis() }}>
            <Card>
              <CardHeader color="assessment">
                Individual Risk Assessment
              </CardHeader>

              <TableContainer>
                <Table
                  style={{ maxHeight: '400px' }}
                  stickyHeader
                  aria-label="Hazards"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>Hazards</TableCell>
                      <TableCell>Control Measure</TableCell>
                      <TableCell>Current Risk Assessment</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {hazards.map((hazard) => (
                      <TableRow key={hazard.id}>
                        <TableCell>{hazard.name}</TableCell>
                        <TableCell>{hazard.control_measure}</TableCell>
                        <TableCell
                          style={{
                            borderTop: 'none !important',
                            color: '#fff',
                            backgroundColor: getHazardBackground(hazard.rating),
                          }}
                        >
                          {getHazardLevel(hazard.rating, hazard.id)}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <GridContainer>
                <GridItem md={6}>
                  <TableContainer>
                    <Table
                      style={{ maxHeight: '400px' }}
                      stickyHeader
                      aria-label="Hazards"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>Risks</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key="hazards">
                          <TableCell>
                            <ul>
                              {hazards.map((risk) => (
                                <li key={risk.id}>{risk.risk}</li>
                              ))}
                            </ul>

                            <ul>
                              <li>
                                <b>Who is at risk:</b> Employee
                              </li>
                            </ul>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </GridItem>
                <GridItem md={6}>
                  <img
                    src={RiskTable}
                    alt="Risk table"
                    style={{ maxWidth: '100%' }}
                  />
                </GridItem>
              </GridContainer>
            </Card>
          </GridItem>
          <GridItem md={12}>
            <Card>
              <CardHeader color="assessment">
                Individual Recommendations
              </CardHeader>

              <GridContainer>
                <GridItem md={8}>
                  <TableContainer>
                    <Table
                      style={{ maxHeight: '400px' }}
                      stickyHeader
                      aria-label="Recommendations"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>Recommendations</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key="Recommendations">
                          <TableCell>
                            {assessmentInfo.autorecomendation}
                            <br />
                            {assessmentInfo.recomendation}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </GridItem>
                <GridItem md={4}>
                  <TableContainer>
                    <Table
                      style={{ maxHeight: '400px' }}
                      stickyHeader
                      aria-label="Equipments"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>Equipment</TableCell>
                          <TableCell>Priority</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {equipments.map((assEquip) => (
                          <TableRow key={assEquip.equipment_id}>
                            <TableCell>
                              {getEquipmentsDescription(assEquip.equipment_id)}
                            </TableCell>
                            <TableCell>
                              {ConvertEquipmentRequired(assEquip.required)}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </GridItem>
              </GridContainer>
            </Card>
          </GridItem>

          <GridItem md={12} className="only-print">
            <Card>
              <CardHeader color="assessment">Employeer Notes</CardHeader>
              <p style={{ height: '200px' }}></p>
            </Card>
          </GridItem>

          <GridItem className="only-print">
            <span>
              By signing below I acknowledge that I have fully read and
              understand the information set forth in this document. I also
              understand that if I have any questions or concerns about this
              report it is my responsibility to discuss these with my manager.
            </span>
            <table>
              <tbody>
                <tr>
                  <td>AUTHORISED BY:</td>
                  <td>SIGNED: _________________________________</td>
                </tr>
                <tr>
                  <td>EMPLOYEE: {assessmentInfo.name}</td>
                  <td>SIGNED: _________________________________</td>
                </tr>
              </tbody>
            </table>
          </GridItem>

          <ApprovalDetail
            show={showDetail}
            data={assessmentInfo}
            callback={LoadInfo}
          />
        </>
      )}
    </GridContainer>
  );
}
