import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Loading from 'components/Loading';
import Button from 'components/CustomButtons/Button.js';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import PrimaryTypo from 'components/Typography/Primary';
import { makeStyles } from '@material-ui/core/styles';
import { CenterFocusStrong } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      textAlign: 'center',
      align: CenterFocusStrong,
      margin: theme.spacing(1),
      width: theme.spacing(16),
      height: theme.spacing(16),
    },
  },
  btn: {
    fontSize: '20px',
  },
}));
export default function OfficeSelection(props) {
  const [loading, setLoading] = useState(false);
  const { callback, data } = props;
  const classes = useStyles();

  const SelectOffice = (id, location) => {
    setLoading(true);
    callback({ id: id, location: location });
  };
  useEffect(() => {}, []);
  return (
    <>
      <Loading show={loading} />
      {!loading && (
        <GridContainer>
          <GridItem md={12}>
            <PrimaryTypo>Select location</PrimaryTypo>
          </GridItem>
          <GridItem md={10} className={classes.root}>
            {data.map(({ id, location }) => (
              <Button
                key={id}
                loading={loading}
                onClick={() => SelectOffice(id, location)}
                color="primary"
                className={classes.btn}
              >
                {location}
              </Button>
            ))}
          </GridItem>
        </GridContainer>
      )}
    </>
  );
}
OfficeSelection.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  callback: PropTypes.func,
  data: PropTypes.array,
};
