/*eslint-disable*/
import React from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
// react components for routing our app without refresh
import { Link } from 'react-router-dom';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Tooltip from '@material-ui/core/Tooltip';

// @material-ui/icons
import { isAuthenticated, getUserProfileName } from 'services/context';

// core components
import Button from 'components/CustomButtons/Button.js';

import styles from 'assets/jss/material-kit-react/components/headerLinksStyle.js';
import { ExitToApp } from '@material-ui/icons';
const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const classes = useStyles();
  if (isAuthenticated()) {
    return (
      <List className={classes.list}>
        <ListItem className={classes.listItem}>
          <Button color="transparent" className={classes.userNameNavLink}>
            {getUserProfileName()}
          </Button>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Tooltip
            id="login-tooltip"
            title="Logout from DOMUS"
            placement={window.innerWidth > 959 ? 'top' : 'left'}
            classes={{ tooltip: classes.tooltip }}
          >
            <Button
              color="transparent"
              href="/signout"
              className={classes.navLink}
            >
              <ExitToApp />
              Logout
            </Button>
          </Tooltip>
        </ListItem>
      </List>
    );
  }
  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="login-tooltip"
          title="Login into your DOMUS"
          placement={window.innerWidth > 959 ? 'top' : 'left'}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="/signin"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + ' fab fa-signin'} />
            Login
          </Button>
        </Tooltip>
      </ListItem>
      {/* <ListItem className={classes.listItem}>
        <Tooltip
          id="signup-tooltip"
          title="Sign up for DOMUS"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="/signup"
            className={classes.navLink}
          >
            Signup
          </Button>
        </Tooltip>
      </ListItem> */}
    </List>
  );
}
