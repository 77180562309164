import React from 'react';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
// core components
import Header from 'components/Header/Header.js';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';
import HeaderLinks from 'components/Header/HeaderLinks.js';
import styles from 'assets/jss/material-kit-react/views/loginPage.js';
import Footer from 'components/Footer/Footer.js';

import image from 'assets/img/bg7.jpg';
const useStyles = makeStyles(styles);

export default function LoginPage(props) {
  const [cardAnimaton, setCardAnimation] = React.useState('cardHidden');

  setTimeout(function () {
    setCardAnimation('');
  }, 700);
  const classes = useStyles();
  const { ...rest } = props;

  return (
    <div>
      <Header
        absolute
        color="transparent"
        brand="Material Kit React"
        rightLinks={<HeaderLinks />}
        {...rest}
      />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: 'url(' + image + ')',
          backgroundSize: 'cover',
          backgroundPosition: 'top center',
        }}
      >
        <div className={classes.container}>
          <GridContainer justifyContent="center">
            <GridItem xs={12} sm={12} md={12}>
              <Card className={classes[cardAnimaton]}>
                <CardHeader color="primary" className={classes.cardHeader}>
                  <h4>Privacy</h4>
                </CardHeader>
                <CardBody style={{ margin: '20px' }}>
                  <h1>Privacy notice</h1>
                  <p>
                    Capella takes your privacy seriously. This Privacy Notice
                    describes Capella’s privacy practices in relation to
                    information that we collect through our website,
                    applications and via other services provided by us offline.
                    This Privacy Notice outlines:
                  </p>
                  <ol>
                    <li>Who we are</li>
                    <li>What information we collect about you</li>
                    <li>How we collect the information</li>
                    <li>How we use that information</li>
                    <li>How we share information that we collect</li>
                    <li>How we store and secure information that we collect</li>
                    <li>Your rights as a data subject</li>
                    <li>How you can access and control your information</li>
                    <li>Other important details</li>
                  </ol>
                  <p>
                    This Privacy Notice does not apply to the practices of third
                    parties that Capella does not own or control.
                  </p>
                  <h2>1. Who we are</h2>
                  <p>
                    Capella was established in 2020 to assist organisations by
                    developing spatial plans to accommodate a safe return to
                    work for their employees. Taking into account the current
                    protocols, Capella delivers considered solutions and a full
                    suite of complementary templates and check lists to help
                    employers get their staff safely back to work. In addition,
                    Capella has developed an innovative solution to support
                    organisations in meeting their safety, health and welfare at
                    work obligations for staff who are working from home.
                  </p>
                  <h2>2. What information we collect about you</h2>
                  <p>Capella collects the following information:</p>
                  <ul>
                    <li>
                      Personal Data. “Personal Data” is information that,
                      directly or indirectly, identifies you or another
                      individual and which may include: name, title, company
                      name, job function, postal address, telephone number,
                      email address, 3D scan of home working environment,
                      ergonomics assessment, browser and device information
                      (including IP Address), and information collected through
                      cookies and other similar technologies. If you submit any
                      Personal Data relating to other people to us or to our
                      service providers, you represent that you have the
                      authority to do so and to permit us to use the information
                      in accordance with this Privacy Notice.
                    </li>
                    <li>
                      Special categories of Personal Data (sensitive data).
                      While Capella does not, in general, collect or process
                      many types of special categories of personal data, it may
                      be necessary to collect this information as part of
                      conducting an organisation’s ergonomic assessment of its
                      employees working at home environment.
                    </li>
                    <li>
                      Other Information. “Other Information” is any information
                      that does not and cannot be used to reveal your identity
                      or that of another individual, such as information which
                      has been fully and permanently anonymised. We use this
                      information for other purposes as described below.
                    </li>
                  </ul>
                  <h2>3. How we collect the information</h2>
                  <p>
                    Capella collect Personal Data and Other Information in a
                    number of ways:
                  </p>
                  <h3>Purpose of collection</h3>
                  <p>
                    <strong>Information from other sources:</strong>
                  </p>
                  <p>
                    In order to enhance our ability to provide services to you,
                    we obtain information about you from other sources, such as
                    your employer (our customer), our partners, suppliers, as
                    well as from other third parties.
                  </p>

                  <p>
                    <strong>Offline:</strong>
                  </p>
                  <p>
                    We may collect information from you offline, such as, during
                    phone calls with our Capella team or when you contact us.
                  </p>

                  <p>
                    <strong>From you:</strong>
                  </p>
                  <p>
                    Capella collects information such as your contact details,
                    location, workspace arrangements and other information as
                    necessary in order to ergonomically assess your (or your
                    employees’) work location.
                  </p>

                  <p>
                    <strong>Via your browser or device:</strong>
                  </p>
                  <p>
                    Certain information is collected by most browsers or
                    automatically through your device, such as your Media Access
                    Control (MAC) address, computer type (Windows or Macintosh),
                    screen resolution, operating system name and version, device
                    manufacturer and model, language, Internet browser type and
                    version, and the name and version of the site you are using.
                    Your IP Address is a number that is automatically assigned
                    to the computer that you are using by your Internet Service
                    Provider (ISP). An IP Address may be identified and logged
                    automatically in our server log files whenever you access
                    the website, along with the time of the visit and the
                    page(s) that you visited. Collecting IP Addresses is
                    standard practice and is done automatically by many
                    websites, applications and other services, such as Google
                    Analytics. Capella may use IP Addresses to calculate usage
                    levels of the web site, to administer the site and to
                    diagnose problems with servers.
                  </p>

                  <p>
                    <strong>Via cookies and other similar technologies:</strong>
                  </p>
                  <p>
                    “Cookies” include commonly used pieces of information in the
                    form of small files that are placed on an individual’s
                    device to enable the individual to more easily communicate
                    and interact with the website. When you visit a website, it
                    can send one or more cookies to your device. These cookies
                    enable us to store information about your device which helps
                    us, amongst other things, to provide you with a good
                    experience when you browse and enhance the level of services
                    and functions provided. Please review our Cookies Policy
                    (which can be found on our website) for more information.
                  </p>
                  <p>
                    <strong>Via recruitment and employment processes:</strong>
                  </p>
                  <p>
                    We will process certain personal information about our
                    employees, that we collect during the job interview process,
                    at the start of employment and in the course of employment.
                    We process this personal data to the extent permitted or
                    required under applicable law, for purposes connected with
                    employment, such as human resources, payroll management and
                    administration.
                  </p>

                  <h2>4. How we use that information</h2>
                  <p>We use Personal Data:</p>
                  <ul>
                    <li>
                      To send information and materials regarding our products
                      and services. To send administrative information such as
                      changes to our terms, conditions, and policies.
                    </li>
                    <li>
                      To send you marketing communications, including via email
                      in compliance with applicable laws and in accordance with
                      your preferences, that we believe may be of interest to
                      you.
                    </li>
                    <li>
                      To personalise your experience by presenting products and
                      offers tailored to you.
                    </li>
                    <li>
                      For our business purposes, such as data analysis, audits,
                      developing new products, enhancing, improving or modifying
                      our website, applications and services, identifying usage
                      trends, determining the effectiveness of our operations
                      and expanding our business activities.
                    </li>
                    <li>
                      For recruitment and employment purposes, such as staff
                      management, performance review, training records,
                      appointments, removals, personal development and
                      administration.
                    </li>
                    <li>
                      As we believe to be necessary or appropriate: (a) under
                      applicable law, including laws outside your country of
                      residence; (b) to comply with legal process; (c) to
                      respond to requests from public and government authorities
                      including public and government authorities outside your
                      country of residence; (d) to enforce our terms and
                      conditions; (e) to protect our operations or those of any
                      of our Members; (f) to protect our rights, privacy, safety
                      or property, and/or that of our Members, you or others;
                      and (g) to allow us to pursue available remedies or limit
                      the damages that we may sustain.
                    </li>
                  </ul>
                  <p>
                    Our legal basis for collecting and using the Personal Data
                    described above will depend on the Personal Data concerned
                    and the specific context in which we collect it. We will
                    collect and use your Personal Data where the processing is
                    in our legitimate business interests, such as for direct
                    marketing and sales of our services to prospective
                    Business-to-Business customers.
                  </p>
                  <p>
                    Our legal basis for processing Personal Data in certain
                    circumstances will be in the instances where we need the
                    Personal Data to perform a contract with you or in order to
                    enter into a contract with you. In some cases, we may also
                    have a legal obligation to collect Personal Data and Special
                    Categories of Personal Data. If we ask you to provide
                    Personal Data to comply with a legal requirement or to enter
                    into a contract with you, we will make this clear at the
                    relevant time and advise you whether the provision of your
                    Personal Data is mandatory or not (as well as of the
                    possible consequences if you do not provide your Personal
                    Data).
                  </p>
                  <p>
                    In terms of your data being processed for marketing
                    purposes, your additional consent in advance of this
                    processing would be required.
                  </p>
                  <h2>5. How we share information that we collect</h2>
                  <p>
                    We may pass your personal data on to third-party service
                    providers contracted to Capella in the course of dealing
                    with you. Any third parties that we may share your data with
                    are obliged to keep your details securely, and to use them
                    only for the specific purpose for which the data was
                    gathered. When they no longer need your data to fulfil this
                    service, they will dispose of the details in line with
                    Capella’s disposal procedures.
                  </p>
                  <p>
                    Capella may transfer your personal data to our partners.
                    Where we do so, Capella ensures the privacy and integrity of
                    your Personal Data by putting appropriate organisational and
                    technical measures in place to safeguard your information.
                    If you would like more information about the safeguards that
                    are in place in connection with these transfers of Personal
                    Data, please contact us using the contact details in Section
                    1.9.
                  </p>
                  <p>
                    Capella will not transfer your personal data outside of the
                    European Economic Area. We may release Personal Data as we
                    believe necessary and appropriate to law enforcement, tax,
                    fraud prevention, credit risk agencies and other companies
                    and organisations for the reasons given under Section 1.4
                    above.
                  </p>
                  <p>
                    We use and disclose Other Information for any purpose,
                    except where we are required to do otherwise under
                    applicable law. If we are required to treat Other
                    Information as Personal Data under applicable law, then we
                    would use or disclose it in the same way that we use and
                    disclose Personal Data. In some instances, we may combine
                    Other Information with Personal Data (such as combining your
                    name with the name of your organisation). If that
                    combination permits you to be identified, we will treat the
                    combined information as Personal Data for as long as it is
                    combined.
                  </p>
                  <h2>
                    6. How we store and secure information that we collect
                  </h2>
                  <p>
                    We have implemented appropriate organisational, technical,
                    and administrative measures to protect Personal Data within
                    our organisation, including security controls to prevent
                    unauthorised access to our systems. While we take reasonable
                    steps to secure your Personal Data from loss, misuse,
                    interference and unauthorised access, modification and
                    disclosure, you should be aware no security procedures or
                    protocols are ever guaranteed to be 100 percent secure from
                    intrusion or hacking, and there is therefore always some
                    risk assumed by sharing Personal Data online. If you have
                    reason to believe that your interaction with us is no longer
                    secure, please immediately notify us in accordance with the
                    Contact Us section below.
                  </p>
                  <h2>7. Your rights as a data subject</h2>
                  <p>
                    At any point while we are in possession of or processing
                    your personal data, you, the data subject, have the
                    following rights:
                  </p>
                  <ul>
                    <li>
                      Right of access – you have the right to request a copy of
                      the information that we hold about you.
                    </li>
                    <li>
                      Right of rectification – you have a right to correct data
                      that we hold about you that is inaccurate or incomplete.
                    </li>
                    <li>
                      Right to be forgotten – in certain circumstances you can
                      ask for the data we hold about you to be erased from our
                      records.
                    </li>
                    <li>
                      Right to restriction of processing – where certain
                      conditions apply, you have a right to restrict the
                      processing.
                    </li>
                    <li>
                      Right of portability – you have the right to have the data
                      we hold about you transferred to another organisation.
                    </li>
                    <li>
                      Right to object – you have the right to object to certain
                      types of processing such as direct marketing.
                    </li>
                    <li>
                      Right to object to automated processing, including
                      profiling – you also have the right not to be subject to
                      the legal effects of automated processing or profiling.
                    </li>
                    <li>
                      Right to complain and judicial review: in the event that
                      Capella refuses your request under rights of access, we
                      will provide you with a reason as to why. You have the
                      right to complain as outlined in Section 1.9 below.
                    </li>
                  </ul>
                  <h2>8. How you can access and control your information</h2>
                  <p>
                    If you would like to access, correct, amend, remove, object
                    or limit the use or disclosure of any Personal Data about
                    you that has been collected and stored by Capella, or have
                    it transferred to another organisation, please notify us at
                    criona@capella.ie so that we may consider and respond to
                    your request in accordance with applicable law.
                  </p>
                  <p>
                    You can opt-out of receiving marketing messages from Capella
                    or our business partners by unsubscribing through the
                    unsubscribe or opt-out link in an email or by sending an
                    email to criona@capella.ie. Please note that if you opt-out
                    of receiving marketing-related emails from us, we may still
                    send you important administrative messages. For your
                    protection, we only implement requests with respect to the
                    Personal Data associated with the particular email address
                    that you use to send us your request, and we need to verify
                    your identity before implementing your request. We will
                    action your request within one month.
                  </p>
                  <p>
                    Please note that we need to retain certain information for
                    recordkeeping purposes and/or to complete any transactions
                    that you began prior to requesting such access, change or
                    deletion.
                  </p>
                  <h2>9. Other important details</h2>
                  <b>How long Capella will retain your Personal Data</b>
                  <p>
                    Generally, we will not keep your personal data for longer
                    than is needed for the purpose the data was originally
                    collected, including to pursue our legitimate business
                    interests, comply with our legal obligations, resolve
                    disputes and enforce applicable agreements. Capella
                    maintains a data retention schedule, the details of which
                    (relating to your personal data), can be provided upon
                    request from criona@capella.ie.
                  </p>
                  <b>Third Party Sites</b>
                  <p>
                    Some websites, social media and applications permit you to
                    link to other websites on the Internet through direct links
                    or through applications such as “share” or “like” buttons,
                    and other websites likewise may contain links to our sites.
                    The information practices or content of such other websites
                    is governed by the privacy statements of those websites and
                    not by this Privacy Notice. We encourage you to review the
                    privacy policies found on such other websites, services and
                    applications to understand how your information is collected
                    and used by them. Similarly, please note that we are not
                    responsible for the collection, use and disclosure policies
                    and practices (including the data security practices) of
                    other organisations, such as Apple, Facebook, Google,
                    LinkedIn, Microsoft, RIM, Twitter or any other app
                    developer, app provider, social media platform provider,
                    operating system provider, wireless service provider, or
                    device manufacturer, including any Personal Data you
                    disclose to other organisations through or in connection
                    with the Apps or the Social Media Pages.
                  </p>
                  <b>Personal data of Children</b>
                  <p>
                    Capella does not knowingly or specifically collect
                    information about minors under the age of 18 and believes
                    that children of any age should get their parents’ or legal
                    guardians’ consent before providing any Personal Data. If
                    you believe that we have mistakenly or unintentionally
                    collected such information, please notify us at
                    criona@capella.ie so that we can delete the information from
                    our servers.
                  </p>
                  <b>
                    Sensitive (i.e. Special Categories of Personal Data)
                    Information
                  </b>
                  <p>
                    We ask that you not send us or disclose any sensitive
                    Personal Data (e.g. RSI numbers, information related to
                    racial or ethnic origin, sexual orientation, political
                    opinions, religion or other beliefs, health, biometrics or
                    genetic characteristics, criminal background, or trade union
                    membership) on or through the website, applications or via
                    other means.
                  </p>
                  <b>Changes to this Privacy Notice</b>
                  <p>
                    Capella may amend this Privacy Notice from time to time. The
                    “date amended” appears at the bottom of this privacy notice
                    and this date indicates when the Privacy Notice was last
                    revised.
                  </p>
                  <b>Contact Us</b>
                  <p>
                    Your privacy is important to us. If you have any questions,
                    concerns, or complaints regarding the way we collect and
                    handle your information, please contact:
                    <br />
                    Email: criona@capella.ie
                    <br />
                    Phone: +353 86 853 6408
                    <br />
                  </p>
                  <p>
                    Because email communications are not always secure, please
                    do not include bank account information or other sensitive
                    (special categories of personal data) information in your
                    emails to us.
                  </p>
                  <b>Your right to make a complaint</b>
                  <p>
                    Capella will take any privacy complaint seriously and any
                    complaint will be assessed by an appropriate person with the
                    aim of resolving any issue in a timely and efficient manner.
                    We request that you cooperate with us during this process
                    and provide us with any relevant information that we may
                    need.
                  </p>
                  <p>
                    You also have the right to complain to a data protection
                    authority about our collection and use of your Personal
                    Data. Their contact details are as follows: Data Protection
                    Commission
                    <br />
                    21 Fitzwilliam Square South
                    <br />
                    Dublin 2<br />
                    D02 RD28
                    <br />
                    Ireland
                    <br />
                    +353 578 684 800
                    <br />
                    +353 761 104 800
                    <br />
                    www.dataprotection.ie
                    <br />
                  </p>
                  <p>Privacy Notice – Date created: 8 June 2020</p>
                  <p>&nbsp;</p>
                  <p>&nbsp;</p>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
        <Footer whiteFont />
      </div>
    </div>
  );
}
