import React, { useContext } from 'react';
import { StepsContext } from './Context';
import { makeStyles } from '@material-ui/core/styles';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import QuestionAnswer from '../../../components/Questionaire/QuestionAnswer';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function Questionaire({ questions }) {
  const [context, setContext] = useContext(StepsContext);
  const classes = useStyles();

  const SaveContext = (name, value) => {
    let questionaire = context[2].questionaire;

    if (questionaire != null) {
      //remove previous
      questionaire = questionaire.filter((item) => item.key !== name);
    }

    questionaire.push({ key: name, value: value });

    context[2].questionaire = questionaire;

    setContext(context);
  };

  const handleSaveArray = (name, valueOptions) => {
    let questionaire = context[2].questionaire;
    let questionaireItemValue = 'no';
    if (questionaire != null) {
      questionaireItemValue = questionaire.filter((item) => item.key === name);
      questionaire = questionaire.filter((item) => item.key !== name);

      if (questionaireItemValue != null && questionaireItemValue.length > 0) {
        questionaireItemValue = questionaireItemValue[0].value;
      }
    }

    questionaire.push({
      key: name,
      value: questionaireItemValue,
      valueOptions: valueOptions,
    });

    context[2].questionaire = questionaire;
    setContext(context);
  };

  const handleSave = (event) => {
    const { name, value } = event.target;

    SaveContext(name, value, null);
  };

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table}>
        <TableBody>
          {questions &&
            questions.map(
              ({ type, name, title, tooltip, options, isRequired }) => {
                var val = context[2].questionaire.find((e) => e.key === name);
                var _valOptions = null;
                var _valValue = null;
                if (val) {
                  _valValue = val['value'];
                  _valOptions = val['valueOptions'];
                }

                return (
                  <QuestionAnswer
                    key={name}
                    name={name}
                    isRequired={isRequired}
                    tooltip={tooltip}
                    type={type}
                    title={title}
                    value={_valValue}
                    valueOptions={_valOptions}
                    options={options}
                    handleChange={handleSave}
                    handleChangeArray={handleSaveArray}
                  />
                );
              }
            )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

Questionaire.propTypes = {
  questions: PropTypes.array,
};
