import React, { useState, useEffect } from 'react';
import { GetReminders } from 'services/user';
import Chart from 'react-google-charts';
import PropTypes from 'prop-types';
import Loading from 'components/Loading';
export default function RemindersGraph({ data }) {
  const [loading, setLoading] = useState(true);
  const [showGraph, setShowGraph] = useState(false);
  const [graphData, setGraphData] = useState([]);

  useEffect(() => {
    setLoading(true);
    GetReminders().then((res) => {
      var graphData = [['Date', 'Not Activated', 'Not Completed']];
      data.forEach((element) => {
        graphData.push([
          element.dt,
          parseInt(element.NotActivated),
          parseInt(element.NotCompleted),
        ]);
      });
      setShowGraph(graphData.length > 1);
      setGraphData(graphData);
      setLoading(false);
    });
  }, [data]);
  return (
    <>
      <Loading show={loading} />
      {!loading && showGraph && (
        <Chart
          height={'400px'}
          chartType="Bar"
          loader={<div>Loading Chart</div>}
          data={graphData}
          options={{
            chart: {
              title: 'Reminders sent',
              hAxis: {
                title: 'Date',
              },
              vAxis: {
                title: 'Reminders Sent',
                minValue: 0,
              },
            },
          }}
        />
      )}
    </>
  );
}
RemindersGraph.propTypes = {
  data: PropTypes.array,
};
