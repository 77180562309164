import React from 'react';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
// @material-ui/icons
import Email from '@material-ui/icons/Email';
import SnackbarContent from 'components/Snackbar/SnackbarContent.js';
import { ResetPassword } from '../../services/auth';
// core components
import Header from 'components/Header/Header.js';
import HeaderLinks from 'components/Header/HeaderLinks.js';
import Footer from 'components/Footer/Footer.js';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardFooter from 'components/Card/CardFooter.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import styles from 'assets/jss/material-kit-react/views/loginPage.js';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import image from 'assets/img/bg7.jpg';
import { useHistory } from 'react-router-dom';
const useStyles = makeStyles(styles);

export default function ResetPage(props) {
  const history = useHistory();
  const [cardAnimaton, setCardAnimation] = React.useState('cardHidden');
  const [loading, setLoading] = React.useState(false);
  const [showMessage, setShowMessage] = React.useState(false);
  const [message, setMessage] = React.useState(null);
  const [email, setEmail] = React.useState('');
  setTimeout(function () {
    setCardAnimation('');
  }, 700);
  const classes = useStyles();
  const { ...rest } = props;

  const handleSubmit = () => {
    setLoading(true);
    setShowMessage(false);

    ResetPassword(email)
      .then((result) => {
        setMessage(
          <>
            Look for an email in your inbox. An email with instructions has been
            sent to: <br />
            {email}
          </>
        );
        setShowMessage(true);
        setLoading(false);
      })
      .catch(() => {
        setMessage(
          <>
            Look for an email in your inbox. An email with instructions has been
            sent to: <br />
            {email}
          </>
        );
        setShowMessage(true);
        setLoading(false);
      });
  };
  return (
    <div>
      <Header
        absolute
        color="transparent"
        brand="Material Kit React"
        rightLinks={<HeaderLinks />}
        {...rest}
      />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: 'url(' + image + ')',
          backgroundSize: 'cover',
          backgroundPosition: 'top center',
        }}
      >
        <div className={classes.container}>
          <GridContainer justifyContent="center">
            <GridItem xs={12} sm={12} md={4}>
              <Card className={classes[cardAnimaton]}>
                <form className={classes.form}>
                  <CardHeader color="primary" className={classes.cardHeader}>
                    <h4>Forgot Password</h4>
                  </CardHeader>
                  <CardBody style={{ margin: '10px' }}>
                    <CustomInput
                      labelText="email"
                      id="email"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: 'text',
                        onChange: (e) => setEmail(e.target.value),
                        endAdornment: (
                          <InputAdornment position="end">
                            <Email className={classes.inputIconsColor} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </CardBody>
                  <CardFooter className={classes.cardFooter}>
                    <Button
                      simple
                      color="primary"
                      loading={loading}
                      size="lg"
                      onClick={() => handleSubmit()}
                    >
                      Continue
                    </Button>
                  </CardFooter>

                  {showMessage && (
                    <SnackbarContent
                      color="info"
                      icon="info_outline"
                      message={message}
                    />
                  )}
                  <CardFooter className={classes.cardFooter}>
                    <Button
                      simple
                      color="primary"
                      size="sm"
                      onClick={() => history.push('/')}
                    >
                      <ArrowBackIosIcon /> Back to login
                    </Button>
                  </CardFooter>
                </form>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
        <Footer whiteFont />
      </div>
    </div>
  );
}
