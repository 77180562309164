import React from 'react';
import PropTypes from 'prop-types';

import List from 'components/Equipments/List';
import Layout from 'components/Layout/Layout';
import { Build } from '@material-ui/icons';
export default function EquipmentsManagePage(props) {
  const { window } = props;

  return (
    <Layout window={window} title="Manage Equipment" icon={<Build />}>
      <List />
    </Layout>
  );
}

EquipmentsManagePage.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};
