import React, { useEffect } from 'react';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
// @material-ui/icons
import Email from '@material-ui/icons/Email';
import SnackbarContent from 'components/Snackbar/SnackbarContent.js';
import { Activation, Login } from 'services/auth';
// core components
import Header from 'components/Header/Header.js';
import HeaderLinks from 'components/Header/HeaderLinks.js';
import Footer from 'components/Footer/Footer.js';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardFooter from 'components/Card/CardFooter.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import styles from 'assets/jss/material-kit-react/views/loginPage.js';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import image from 'assets/img/bg7.jpg';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import LockIcon from '@material-ui/icons/Lock';
import WorkIcon from '@material-ui/icons/Work';
import BusinessIcon from '@material-ui/icons/Business';
import { useHistory, useLocation } from 'react-router-dom';
const useStyles = makeStyles(styles);

export default function LoginPage(props) {
  const history = useHistory();
  const [cardAnimaton, setCardAnimation] = React.useState('cardHidden');
  const [loading, setLoading] = React.useState(false);
  const [previoulyActivated, setPrevioulyActivated] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [email, setEmail] = React.useState('');
  const [activationKey, setActivationKey] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [jobTitle, setJobTitle] = React.useState('');
  const [daysRemote, setDaysRemote] = React.useState('Flexible');
  const [department, setDepartment] = React.useState('');
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [showDaysRemote, setShowDaysRemote] = React.useState(true);

  const [errorFields, setErrorFields] = React.useState({
    email: false,
    activationKey: false,
    password: false,
    confirmPassword: false,
    jobTitle: false,
    daysRemote: false,
    firstName: false,
    lastName: false,
  });
  const location = useLocation();
  setTimeout(function () {
    setCardAnimation('');
  }, 700);
  const classes = useStyles();
  const { ...rest } = props;
  useEffect(() => {
    if (
      location.pathname.indexOf(
        process.env.REACT_APP_ACTIVATION_FIELDS_HIDDEN
      ) > 0
    ) {
      setShowDaysRemote(false);
      setDaysRemote(null);
    }

    var params = new URLSearchParams(location.search);

    var email = params.get('email');
    var key = params.get('Key');
    setEmail(email);
    setActivationKey(key);
  }, [location, showDaysRemote]);

  const validate = () => {
    var _error = '';
    var _required = false;
    var _errorFields = {
      email: false,
      activationKey: false,
      password: false,
      confirmPassword: false,
      jobTitle: false,
      daysRemote: false,
      firstName: false,
      lastName: false,
    };
    if (activationKey === '') {
      _error = 'Activation Key required';
      _required = true;
      _errorFields.activationKey = true;
    }

    if (firstName === '') {
      _required = true;
      _error = 'First Name required';
      _errorFields.firstName = true;
    }
    if (lastName === '') {
      _required = true;
      _error = 'Last Name required';
      _errorFields.lastName = true;
    }
    if (email === '') {
      _required = true;
      _error = 'Email required';
      _errorFields.email = true;
    }
    if (password === '') {
      _required = true;
      _error = 'Password required';
      _errorFields.password = true;
    }
    if (confirmPassword === '') {
      _required = true;
      _error = 'Password confirmation required';
      _errorFields.password = true;
    }

    if (jobTitle === '') {
      _required = true;
      _error = 'Job Title required';
      _errorFields.jobTitle = true;
    }

    if (department === '') {
      _required = true;
      _error = 'Department required';
      _errorFields.department = true;
    }

    if (!(password === confirmPassword)) {
      _error = 'Your password and confirmation password do not match';
      setErrorMessage(_error);
      _errorFields.password = true;
      _errorFields.confirmPassword = true;
    }

    if (_required) {
      setErrorMessage('Please fill in all required fields');
    }

    setErrorFields(_errorFields);
    return _error !== '';
  };
  const handleSubmit = () => {
    setLoading(true);
    setErrorMessage(null);
    var body = {
      email: email,
      activationKey: activationKey,
      password: password,
      jobTitle: jobTitle,
      daysRemote: daysRemote,
      department: department,
      firstname: firstName,
      lastname: lastName,
    };

    var _failed = validate();
    if (_failed) {
      setLoading(false);
    } else {
      Activation(body)
        .then((result) => {
          if (result.status === 'activated') {
            setPrevioulyActivated(true);
          } else {
            //history.push(result.home + '?email=' + email);
            Login(email, password)
              .then((resultLogin) => {
                history.push(resultLogin.home);
                setLoading(false);
              })
              .catch(() => {
                history.push(result.home + '?email=' + email);
              });
          }

          setLoading(false);
        })
        .catch(() => {
          const resMessage = 'Failed to activate your account';
          setLoading(false);
          setErrorMessage(resMessage);
        });
    }
  };
  return (
    <div>
      <Header
        absolute
        color="transparent"
        brand="Material Kit React"
        rightLinks={<HeaderLinks />}
        {...rest}
      />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: 'url(' + image + ')',
          backgroundSize: 'cover',
          backgroundPosition: 'top center',
        }}
      >
        <div className={classes.container}>
          <GridContainer justifyContent="center">
            <GridItem xs={12} sm={12} md={4}>
              <Card className={classes[cardAnimaton]}>
                <form className={classes.form}>
                  <CardHeader color="primary" className={classes.cardHeader}>
                    <h4>Activation</h4>
                  </CardHeader>

                  <CardBody style={{ margin: '10px' }}>
                    <CustomInput
                      labelText="Email"
                      id="email"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: 'text',
                        value: email,
                        required: true,
                        onChange: (e) => setEmail(e.target.value),
                        endAdornment: (
                          <InputAdornment position="end">
                            <Email className={classes.inputIconsColor} />
                          </InputAdornment>
                        ),
                      }}
                    />

                    <CustomInput
                      labelText="Activation Key"
                      id="activationKey"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: 'text',
                        required: true,
                        value: activationKey,
                        onChange: (e) => setActivationKey(e.target.value),
                        endAdornment: (
                          <InputAdornment position="end">
                            <VpnKeyIcon className={classes.inputIconsColor} />
                          </InputAdornment>
                        ),
                      }}
                    />

                    <CustomInput
                      labelText="First Name"
                      id="firstName"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: 'text',
                        value: firstName,
                        required: true,
                        error: errorFields.firstName,
                        onChange: (e) => setFirstName(e.target.value),
                        endAdornment: (
                          <InputAdornment position="end">
                            <TextFieldsIcon
                              className={classes.inputIconsColor}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <CustomInput
                      labelText="Last Name"
                      id="lastName"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: 'text',
                        value: lastName,
                        error: errorFields.lastName,
                        onChange: (e) => setLastName(e.target.value),
                        endAdornment: (
                          <InputAdornment position="end">
                            <TextFieldsIcon
                              className={classes.inputIconsColor}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <CustomInput
                      labelText="Password"
                      id="password"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: 'password',
                        value: password,
                        error: errorFields.password,
                        onChange: (e) => setPassword(e.target.value),
                        endAdornment: (
                          <InputAdornment position="end">
                            <LockIcon className={classes.inputIconsColor} />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <CustomInput
                      labelText="Confirm Password"
                      id="confirmpassword"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: 'password',
                        value: confirmPassword,
                        error: errorFields.confirmPassword,
                        onChange: (e) => setConfirmPassword(e.target.value),
                        endAdornment: (
                          <InputAdornment position="end">
                            <LockIcon className={classes.inputIconsColor} />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <CustomInput
                      labelText="Job Title"
                      id="jobTitle"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: 'text',
                        value: jobTitle,
                        error: errorFields.jobTitle,
                        onChange: (e) => setJobTitle(e.target.value),
                        endAdornment: (
                          <InputAdornment position="end">
                            <WorkIcon className={classes.inputIconsColor} />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <CustomInput
                      labelText="Department"
                      id="department"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: 'text',
                        value: department,
                        error: errorFields.department,
                        onChange: (e) => setDepartment(e.target.value),
                        endAdornment: (
                          <InputAdornment position="end">
                            <BusinessIcon className={classes.inputIconsColor} />
                          </InputAdornment>
                        ),
                      }}
                    />
                    {showDaysRemote && (
                      <>
                        <InputLabel id="demo-simple-select-helper-label">
                          Days Remote
                        </InputLabel>
                        <Select
                          className={classes.formControl}
                          id="daysRemote"
                          label="Days Remote"
                          fullWidth
                          value={daysRemote}
                          onChange={(e) => setDaysRemote(e.target.value)}
                        >
                          <MenuItem key="flexible" value="Flexible">
                            Flexible
                          </MenuItem>
                          <MenuItem key="1" value="1">
                            1
                          </MenuItem>
                          <MenuItem key="2" value="2">
                            2
                          </MenuItem>
                          <MenuItem key="3" value="3">
                            3
                          </MenuItem>
                          <MenuItem key="4" value="4">
                            4
                          </MenuItem>
                          <MenuItem key="5" value="5">
                            5
                          </MenuItem>
                        </Select>
                      </>
                    )}
                  </CardBody>
                  <CardFooter className={classes.cardFooter}>
                    <Button
                      simple
                      color="primary"
                      loading={loading}
                      size="lg"
                      onClick={() => handleSubmit()}
                    >
                      Activate
                    </Button>
                  </CardFooter>
                  {errorMessage !== null && (
                    <SnackbarContent
                      message={errorMessage}
                      close
                      color="warning"
                      icon="info_outline"
                    />
                  )}
                  {previoulyActivated && (
                    <>
                      <SnackbarContent
                        message={
                          <div>
                            Your account was previouly activated.
                            <br />
                            <a
                              href="/forgot"
                              style={{ textDecoration: 'none' }}
                            >
                              Click here if you're having problems signing in
                            </a>
                          </div>
                        }
                        close
                        color="warning"
                        icon="info_outline"
                      />
                    </>
                  )}
                </form>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
        <Footer whiteFont />
      </div>
    </div>
  );
}
