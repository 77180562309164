import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import Layout from 'components/Layout/Layout';
import { getAdminOverview } from 'services/user';
import Loading from 'components/Loading';
import { addDays } from 'services/variables';
import CompanyImpersonate from 'components/Company/Impersonate';
import { TrackChanges } from '@material-ui/icons';
export default function CompanyOverviewPage(props) {
  const { window } = props;
  const [companies, setCompanies] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const LoadData = () => {
    setLoading(true);
    getAdminOverview().then((res) => {
      let _companies = res.data.info;

      setCompanies(_companies);

      //set data

      let _timelineInfo = [];
      _timelineInfo.push([
        { type: 'string', id: 'Company' },
        { type: 'string', id: 'Name' },
        { type: 'date', id: 'Start' },
        { type: 'date', id: 'End' },
      ]);

      _companies.forEach((element) => {
        if (element.started_at != null && element.endexpected_at != null) {
          let _start = new Date(element.started_at);
          let _end = new Date(element.endexpected_at);

          _timelineInfo.push([element.company, 'Assessments', _start, _end]);
          let _endReview = addDays(14, _end);

          _timelineInfo.push([element.company, 'Review', _end, _endReview]);
        }
      });

      setLoading(false);
    });
  };

  useEffect(() => {
    // code to run on component mount
    LoadData();
  }, []);
  return (
    <Layout window={window} title="Company Select" icon={<TrackChanges />}>
      <Loading show={loading} />
      {!loading && <CompanyImpersonate companies={companies} />}
    </Layout>
  );
}

CompanyOverviewPage.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};
