/**
 * Creates new cookie or removes cookie with negative expiration
 * @param  key       The key or identifier for the store
 * @param  value     Contents of the store
 * @param  exp       Expiration - creation defaults to 30 days
 */
// const readCookie = (key) => {
//   var nameEQ = key + '=';
//   var ca = document.cookie.split(';');
//   for (var i = 0, max = ca.length; i < max; i++) {
//     var c = ca[i];
//     while (c.charAt(0) === ' ') c = c.substring(1, c.length);
//     if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
//   }
//   return null;
// };
// const createCookie = (key, value, exp) => {
//   var date = new Date();
//   date.setTime(date.getTime() + exp * 24 * 60 * 60 * 1000);
//   var expires = '; expires=' + date.toGMTString();
//   document.cookie = key + '=' + value + expires + '; path=/';
// };
const GetStore = (key) => {
  var lsSupport = false;
  if (localStorage) {
    lsSupport = true;
  }
  var data;
  // Set the store
  if (lsSupport) {
    // Native support
    data = localStorage.getItem(key);
  } else {
    // Use Cookie
    data = this.readCookie(key);
  }
  // Try to parse JSON...
  try {
    data = JSON.parse(data);
  } catch (e) {
    //console.log("failed to parse json", data);
  }

  return data;
};
export const saveStore = (key, value) => {
  var lsSupport = false;
  if (localStorage) {
    lsSupport = true;
  }
  // Set the store
  if (lsSupport) {
    // Native support
    localStorage.setItem(key, value);
  } else {
    // Use Cookie
    this.createCookie(key, value, 30);
  }
};
export const getUser = () => {
  return GetStore('user');
};

export const getProfile = () => {
  return GetStore('user-profile');
};

export const isCompanySelected = () => {
  var profile = getProfile();
  return profile?.companyid !== null;
};

const FeatureEnabled = (featureName) => {
  var profile = getProfile();

  var enabled = profile?.capabilities?.some((v) => v === featureName);

  return enabled;
};

export const isCapellaAdmin = () => {
  return FeatureEnabled('AddUser');
};

export const isReviewerAdmin = () => {
  return FeatureEnabled('AddUser');
};
export const IsCompanyViewer = () => {
  return FeatureEnabled('Manage');
};
export const canEditAssessment = () => {
  return isCapellaAdmin() || isReviewerAdmin();
};

export const canSwitchCompany = () => {
  return (
    (isCapellaAdmin() || isReviewerAdmin()) && FeatureEnabled('SwitchCompany')
  );
};

export const getCompanyName = () => {
  var profile = getProfile();
  if (profile && profile.company) {
    return profile.company;
  } else {
    return '';
  }
};

export const getCompanyLocation = () => {
  var profile = getProfile();

  if (profile.company !== undefined && profile.company.location !== null) {
    return profile.company.location;
  }
  return '';
};

export const getUserProfileName = () => {
  var profile = getProfile();

  if (
    profile &&
    profile.data &&
    profile.data.first_name &&
    profile.data.last_name
  ) {
    return profile.data.first_name + ' ' + profile.data.last_name;
  } else {
    return '';
  }
};

export const ImpersonateCompany = (id, name, location) => {
  var profile = getProfile();
  profile.companyid = id;
  profile.company = name;
  profile.companylocation = location;
  // profile.company.id = id;
  // profile.company.name = name;
  // profile.company.location = location;
  //localStorage.setItem("user-profile", JSON.stringify(profile));
  saveStore('user-profile', JSON.stringify(profile));
};

// // Check whether the current time is past the
// // access token's expiry time
// const WhenWillExpire = () => {
//   let userInfo = this.GetStore('user');
//   if (!userInfo) {
//     return false;
//   }
//   var expiryDate = new Date(userInfo.expiresAt).getTime() / 1000;
//   var now = new Date().getTime() / 1000;
//   return parseInt((expiryDate - now) / 60);
// };

export const isAuthenticated = () => {
  // Check whether the current time is past the
  // access token's expiry time
  //let userInfo = JSON.parse(localStorage.getItem('user'));
  let userInfo = GetStore('user');
  if (!userInfo) {
    return false;
  }
  var expiryDate = new Date(userInfo.expiresAt).getTime();

  var now = new Date().getTime();
  return now < expiryDate;
};

/*
export default {
  // isAuthenticated,
  // WhenWillExpire,
  // ImpersonateCompany,
  // isCapellaAdmin,
  // isReviewerAdmin,
  getProfile,
  getUser,
  saveStore,
};*/
