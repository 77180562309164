import React, { useEffect, useState } from 'react';
import MUIDataTable from 'mui-datatables';
import Loading from 'components/Loading';
import { GetEmailTemplates } from 'services/user';
import DatatableCustomToolbar from 'components/Editor/DatatableCustomToolbar';
import { isCapellaAdmin } from 'services/context';
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';
import Button from 'components/CustomButtons/Button.js';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { SaveEmailTemplate } from 'services/user';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import {
  EditorState,
  convertToRaw,
  convertFromHTML,
  ContentState,
  Modifier,
} from 'draft-js';
import './react-draft-wysiwyg.css';

class CustomOption extends React.Component {
  static propTypes = {
    onChange: PropTypes.func,
    editorState: PropTypes.object,
  };

  addContent = (content) => {
    const { editorState, onChange } = this.props;
    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      content,
      editorState.getCurrentInlineStyle()
    );
    onChange(EditorState.push(editorState, contentState, 'insert-characters'));
  };

  render() {
    return (
      <>
        <div onClick={() => this.addContent('{{firstname}}')}>First Name</div>
        <div onClick={() => this.addContent('{{lastname}}')}>Last Name</div>
        <div onClick={() => this.addContent('{{activationlink}}')}>
          Activation Link
        </div>
      </>
    );
  }
}

export default function EmailTemplateList() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [editorSt, setEditorSt] = useState(EditorState.createEmpty());
  const [emailTemplate, setEmailTemplate] = useState('');
  const [emailTemplateType, setEmailTemplateType] = useState('');
  const [emailTemplateId, setEmailTemplateId] = useState('');
  const [error, setError] = useState('');

  const [showModal, setShowModal] = useState(false);
  const displayEditAdd = (id, templateEmail, templateType) => {
    var editor = EditorState.createEmpty();
    if (id === null) {
      setEmailTemplate(null);
      setEmailTemplateType('');
      setEmailTemplateId(null);

      const contentState = ContentState.createFromBlockArray(
        convertFromHTML('')
      );
      const editorState = EditorState.createWithContent(contentState);
      setEditorSt(editorState);
      setShowModal(true);
    } else {
      const contentState = ContentState.createFromBlockArray(
        convertFromHTML(templateEmail)
      );
      const editorState = EditorState.createWithContent(contentState);
      setEditorSt(editorState);
      setEmailTemplate(templateEmail);
      setEmailTemplateType(templateType.toString());
      setEmailTemplateId(id);
      setShowModal(true);
    }
  };

  const columns = [
    {
      label: 'ID',
      name: 'id',
      options: {
        filter: false,
        display: false,
      },
    },

    {
      label: 'Created',
      name: 'created_at',
      options: {
        filter: false,
      },
    },
    {
      label: 'Updated',
      name: 'updated_at',
      options: {
        filter: false,
      },
    },
    {
      label: 'Type',
      name: 'email_type',
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          if (value === undefined) {
            return '';
          }
          if (value === -1 || value === '-1') {
            return 'Activation Email';
          }
          if (value === 0 || value === '0') {
            return 'Reminder Activation';
          }
          if (value === 1 || value === '1') {
            return 'Reminder Not Completed';
          }
        },
      },
    },
    {
      label: 'Email',
      name: 'content',
      options: {
        filter: false,
        display: false,
      },
    },
    {
      label: 'Actions',
      name: 'id',
      options: {
        filter: false,
        display: true,
        print: false,
        download: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          var _templateContent = tableMeta.rowData[4].toString();
          var _templateType = tableMeta.rowData[3].toString();

          return (
            <Tooltip title="Edit Email Template">
              <EditIcon
                onClick={() =>
                  displayEditAdd(value, _templateContent, _templateType)
                }
              />
            </Tooltip>
          );
        },
      },
    },
  ];

  const options = {
    selectableRows: 'none',
    filterType: 'checkbox',
    rowsPerPage: 100,
    customToolbar: () => {
      if (!isCapellaAdmin()) {
        return <></>;
      }
      return (
        <DatatableCustomToolbar
          onClick={() => {
            displayEditAdd(null, null, null);
          }}
        />
      );
    },
  };

  const getTypeDescription = () => {
    if (emailTemplateType === '') {
      return '';
    }
    if (emailTemplateType === '-1') {
      return 'Activation Email';
    }
    if (emailTemplateType === '0') {
      return 'Reminder Activation';
    }
    if (emailTemplateType === '1') {
      return 'Reminder Not Completed';
    }
  };

  const SaveTemplate = () => {
    setLoading(true);

    var body = {
      id: emailTemplateId,
      content: draftToHtml(convertToRaw(editorSt.getCurrentContent())),
      emailType: emailTemplateType,
    };
    SaveEmailTemplate(body).then((res) => {
      if (res) {
        setLoading(false);
        RefreshDataCloseModal();
      } else {
        setLoading(false);
        setError('Failed to save email template');
      }
    });
  };
  const RefreshDataCloseModal = () => {
    LoadData();
    setShowModal(false);
  };
  const onEditorStateChange = (editorState) => {
    setEditorSt(editorState);
  };
  const onContentStateChange = (contentState) => {
    setEmailTemplate(contentState);
  };
  const LoadData = () => {
    GetEmailTemplates().then((res) => {
      var _data = res.data;

      if (_data.length === 0) {
        _data = [];
      }

      setData(_data);
      setLoading(false);
    });
  };
  useEffect(() => {
    LoadData();
  }, []);
  return (
    <>
      <Loading show={loading} />

      {!loading && (
        <MUIDataTable
          title="Email Templates"
          data={data}
          columns={columns}
          options={options}
        />
      )}

      <Dialog
        open={showModal}
        onClose={RefreshDataCloseModal}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {emailTemplateType === '' ? 'Add template' : 'Edit Template'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {emailTemplateType === '' && (
              <>
                <InputLabel id="demo-simple-select-label">
                  Email Template Type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={emailTemplateType}
                  onChange={(e) => setEmailTemplateType(e.target.value)}
                >
                  <MenuItem value={-1}>Activation</MenuItem>
                  <MenuItem value={0}>Reminder Activation</MenuItem>
                  <MenuItem value={1}>Reminder Non Complete</MenuItem>
                </Select>

                <hr />
              </>
            )}
            {emailTemplateType !== '' && getTypeDescription()}
            <Editor
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              toolbarCustomButtons={[<CustomOption />]}
              editorState={editorSt}
              onEditorStateChange={onEditorStateChange}
              onContentStateChange={onContentStateChange}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {error}
          <Button onClick={RefreshDataCloseModal} color="primary">
            Cancel
          </Button>
          <Button loading={loading} onClick={SaveTemplate} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
