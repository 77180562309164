import React from 'react';
import Button from 'components/CustomButtons/Button';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer';
export default function Confirmation() {
  return (
    <GridContainer justifyContent="center">
      <GridItem
        xs={12}
        sm={12}
        md={12}
        style={{ marginBottom: '20px', textAlign: 'center' }}
      >
        <h2>Confirmation</h2>
        <div className="row">
          <div className="col">
            <h3>Thank you</h3>
            <p>You have completed the process.</p>
            <p>
              All the information you have provided will now be assessed in
              order to help ensure that you are operating in a safe working
              environment.
            </p>
            <p>
              If you need to change any of your answers, please contact{' '}
              <a className="noleft" href="mailto:support@capella-ws.com">
                support@capella-ws.com
              </a>
              .
            </p>
            <p>
              <Button href="/logout">Logout</Button>
            </p>
          </div>
        </div>
      </GridItem>
    </GridContainer>
  );
}
