import React from 'react';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
// core components
import Logo from 'assets/img/Domuslogo.png';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
  },
  footerLogo: {
    maxWidth: '80%',
  },
}));
export default function HeaderLogo() {
  const classes = useStyles();
  return <img src={Logo} className={classes.footerLogo} alt="DOMUS" />;
}
