import React from 'react';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
// @material-ui/core components
import IconDone from 'assets/icons/step-done.png';
import IconNotDone from 'assets/icons/step-not-done.png';

export default function Progress({ currentProgress }) {
  const getImage = (num, description) => {
    if (currentProgress >= num) {
      return (
        <Tooltip title={description + ' done'}>
          <img src={IconDone} alt={description + ' done'} />
        </Tooltip>
      );
    } else {
      return (
        <Tooltip title={description + ' pending'}>
          <img src={IconNotDone} alt={description + ' pending'} />
        </Tooltip>
      );
    }
  };
  return (
    <span>
      {getImage(1, 'Terms and conditions')}
      {'    '}
      {getImage(2, 'Video Training')}
      {'    '}
      {getImage(3, 'Self assessment')}
      {'    '}
      {getImage(4, 'Photos')}
      {getImage(5, 'Closed')}
    </span>
  );
}

Progress.propTypes = {
  currentProgress: PropTypes.number,
};
