import axios from 'axios';
import authHeader from './auth-header';
import variables from './variables';

export const getPublicContent = () => {
  return axios.get(variables.api.url + 'all');
};

export const GetUsers = () => {
  return axios.get(variables.api.url + 'user', {
    headers: authHeader(),
  });
};
export const GetUserAssessments = () => {
  return axios.get(variables.api.url + 'reminders/assessments', {
    headers: authHeader(),
  });
};
export const GetEmailTemplates = () => {
  return axios.get(variables.api.url + 'emailtemplates', {
    headers: authHeader(),
  });
};

export const SaveEmailTemplate = (body) => {
  return axios
    .post(variables.api.url + 'emailtemplates', body, { headers: authHeader() })
    .then(_checkStatus)
    .then(() => {
      return true;
    })
    .catch(() => {
      return false;
    });
};

export const GetReminders = () => {
  return axios.get(variables.api.url + 'reminders', {
    headers: authHeader(),
  });
};

export const GetRemindersHistory = () => {
  return axios.get(variables.api.url + 'reminders/full', {
    headers: authHeader(),
  });
};

export const SendReminders = () => {
  return axios
    .post(variables.api.url + 'reminders', null, { headers: authHeader() })
    .then(_checkStatus)
    .then(() => {
      return true;
    })
    .catch(() => {
      return false;
    });
};

export const SendTargetedReminders = (ids) => {
  var body = {
    ids: ids,
  };
  return axios
    .post(variables.api.url + 'reminders/targeted', body, {
      headers: authHeader(),
    })
    .then(_checkStatus)
    .then(() => {
      return true;
    })
    .catch(() => {
      return false;
    });
};
export const AddUser = (body) => {
  return axios
    .post(variables.api.url + 'user', body, { headers: authHeader() })
    .then(_checkStatus)
    .then(() => {
      return true;
    })
    .catch(() => {
      return false;
    });
};

export const getEmployees = () => {
  return axios.get(variables.api.url + 'employees', {
    headers: authHeader(),
  });
};

export const addLocation = (body) => {
  return axios
    .post(variables.api.url + 'floormap/location', body, {
      headers: authHeader(),
    })
    .then(_checkStatus)
    .then(() => {
      return true;
    })
    .catch(() => {
      return false;
    });
};

export const getFloorMaps = () => {
  return axios.get(variables.api.url + 'floormap', {
    headers: authHeader(),
  });
};

export const getEquipmentMatrix = () => {
  return axios.get(variables.api.url + 'assessment/matrix/equipments', {
    headers: authHeader(),
  });
};
export const addEmployees = (endExpected, email, csv) => {
  let formData = new FormData();
  formData.append('endexpected', endExpected);
  formData.append('employeesFile', csv);
  formData.append('email', email);

  return axios
    .post(variables.api.url + 'employees', formData, {
      headers: authHeader(),
    })
    .then(_checkStatus)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
};
export const getEmployeesSummary = () => {
  return axios.get(variables.api.url + 'employees/summary', {
    headers: authHeader(),
  });
};
export const getCompanyConfig = () => {
  return axios.get(variables.api.url + 'companies/detail', {
    headers: authHeader(),
  });
};
export const getCompanies = () => {
  return axios.post(variables.api.url + 'companies', {
    headers: authHeader(),
  });
};
export const addCompany = (body) => {
  return axios
    .post(variables.api.url + 'companies', body, { headers: authHeader() })
    .then(_checkStatus)
    .then(() => {
      return true;
    })
    .catch(() => {
      return false;
    });
};
export const getAdminDashboard = () => {
  return axios.get(variables.api.url + 'dashboard', {
    headers: authHeader(),
  });
};
export const getAdminOverview = () => {
  return axios.get(variables.api.url + 'dashboard/overview', {
    headers: authHeader(),
  });
};
export const getAssessment = (id) => {
  return axios.get(variables.api.url + 'assessment/' + id, {
    headers: authHeader(),
  });
};
export const getActiveAssessment = () => {
  return axios.get(variables.api.url + 'assessment', {
    headers: authHeader(),
  });
};
export const getAssessmentPhotos = (id) => {
  return axios.get(variables.api.url + 'assessment/image/' + id, {
    headers: authHeader(),
  });
};
export const getAssessmentRecommendation = (id) => {
  return axios.get(variables.api.url + 'assessment/recommendation/' + id, {
    headers: authHeader(),
  });
};
export const getHazards = (id) => {
  return axios.get(variables.api.url + 'assessment/hazards/' + id, {
    headers: authHeader(),
  });
};
export const addHazard = (body) => {
  return axios
    .post(variables.api.url + 'hazard', body, { headers: authHeader() })
    .then(_checkStatus)
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.log(error);
      return false;
    });
};

export const Hazards = () => {
  return axios.get(variables.api.url + 'hazard', {
    headers: authHeader(),
  });
};

export const EquipmentAdd = (body) => {
  return axios
    .post(variables.api.url + 'equipment', body, { headers: authHeader() })
    .then(_checkStatus)
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.log(error);
      return false;
    });
};

export const Equipments = () => {
  return axios.get(variables.api.url + 'equipment', {
    headers: authHeader(),
  });
};
export const getEquipments = (id) => {
  return axios.get(variables.api.url + 'assessment/equipments/' + id, {
    headers: authHeader(),
  });
};
export const getUserProfile = () => {
  return axios.get(variables.api.url + 'auth/me', { headers: authHeader() });
};

export const UpdateProfile = (body) => {
  return axios
    .post(variables.api.url + 'auth/update', body, { headers: authHeader() })
    .then(_checkStatus)
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.log(error);
      return false;
    });
};
export const RemoveHazard = (id, body) => {
  return axios
    .post(variables.api.url + 'assessment/removehazard/' + id, body, {
      headers: authHeader(),
    })
    .then(_checkStatus)
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.log(error);
      return false;
    });
};
export const UpdateHazards = (id, body) => {
  return axios
    .post(variables.api.url + 'assessment/hazards/' + id, body, {
      headers: authHeader(),
    })
    .then(_checkStatus)
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.log(error);
      return false;
    });
};

export const addEquipment = (data) => {
  return axios
    .post(variables.api.url + 'assessment/equipment/', data, {
      headers: authHeader(),
    })
    .then(_checkStatus)
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.log(error);
      return false;
    });
};
export const UpdateEquipments = (id, equipment) => {
  const data = { equipments: equipment };
  return axios
    .post(variables.api.url + 'assessment/equipments/' + id, data, {
      headers: authHeader(),
    })
    .then(_checkStatus)
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.log(error);
      return false;
    });
};
export const RemoveEquipment = (id, equipmentId) => {
  const data = { equipmentid: equipmentId, assessmentid: id };
  return axios
    .delete(variables.api.url + 'assessment/equipment/', {
      headers: authHeader(),
      data: data,
    })
    .then(_checkStatus)
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.log(error);
      return false;
    });
  /*  return axios
    .delete(variables.api.url + 'assessment/equipment/', data, {
      headers: authHeader(),
    })
    .then(_checkStatus)
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.log(error);
      return false;
    });*/
};
export const UpdateReview = (id, body) => {
  return axios
    .post(variables.api.url + 'assessment/review/' + id, body, {
      headers: authHeader(),
    })
    .then(_checkStatus)
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.log(error);
      return false;
    });
};
export const addAssessmentPhotos = (frontPhoto, sidePhoto, step) => {
  let formData = new FormData();

  formData.append('FrontPhoto', frontPhoto);
  formData.append('SidePhoto', sidePhoto);
  formData.append('step', step);

  return axios
    .post(variables.api.url + 'assessment', formData, {
      headers: authHeader(true),
    })
    .then(_checkStatus)
    .then(() => {});
};
export const addAssessment = (body) => {
  return axios
    .post(variables.api.url + 'assessment', body, { headers: authHeader() })
    .then(_checkStatus)
    .then(() => {
      // console.log(response);
    });
};
export const ConvertEquipmentRequired = (tp) => {
  if (tp === 1 || tp === '1') {
    return 'Recommended';
  }
  if (tp === 2 || tp === '2') {
    return 'Requested';
  }

  return 'Optional';
};
export const addAssessmentApproval = (body) => {
  return axios
    .post(variables.api.url + 'assessment/approve', body, {
      headers: authHeader(),
    })
    .then(_checkStatus)
    .then(() => {
      // console.log(response);
    });
};

export const rotateImage = (assessmentid, imagetype, degrees) => {
  var body = {
    assessmentid: assessmentid,
    imagetype: imagetype,
    degrees: degrees,
  };
  return axios
    .post(variables.api.url + 'image/rotate', body, {
      headers: authHeader(),
    })
    .then(_checkStatus)
    .then(() => {
      // console.log(response);
    });
};

/////////////////HotDesk////////////////
export const GetOfficeLocations = () => {
  return axios.get(variables.api.url + 'floormaps/', {
    headers: authHeader(),
  });
};

export const SaveBookableMap = (body) => {
  return axios
    .post(variables.api.url + 'floormaps/bookable', body, {
      headers: authHeader(),
    })
    .then(_checkStatus)
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.log(error);
      return false;
    });
};

export const GetBookableMap = (id) => {
  return axios.get(variables.api.url + 'floormaps/' + id, {
    headers: authHeader(),
  });
};
export const GetBookableMapURI = (id) => {
  return variables.api.url + 'floormaps/' + id + '/image';
};

export const getMaps = () => {
  return axios.get(variables.api.url + 'floormaps', { headers: authHeader() });
};

const _checkStatus = (response) => {
  // raises an error in case response status is not a success
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else {
    var error = new Error(
      'Authentication',
      'Authentication failed, please confirm your username and password'
    );

    //error.statusText = "Authentication failed, please confirm your username and password";
    throw error;
  }
};
