import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import OfficeSelection from 'components/OfficeSelection';
import FloorMap from 'components/FloorMap';
import FloorMapBooking from 'components/FloorMap/booking';
import { GetOfficeLocations } from 'services/user';
import Loading from 'components/Loading';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import PrimaryTypo from 'components/Typography/Primary';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
export default function DeskBooking(props) {
  const { Admin } = props;
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [office, SetOffice] = useState(null);
  var today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  const [selectedDate, handleDateChange] = useState(tomorrow);
  useEffect(() => {
    GetOfficeLocations().then((res) => {
      var _data = res.data;

      if (_data.length === 0) {
        _data = [];
      }

      setData(_data);
      setLoading(false);
    });
  }, []);
  return (
    <>
      <Loading show={loading} />
      {!loading && office === null && (
        <>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <GridContainer>
              <GridItem md={12}>
                <PrimaryTypo>Select date</PrimaryTypo>
              </GridItem>
              <GridItem md={10}>
                <KeyboardDatePicker
                  margin="normal"
                  id="date-picker-dialog"
                  label="Date picker dialog"
                  format="dd/MM/yyyy"
                  value={selectedDate}
                  onChange={handleDateChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </GridItem>
            </GridContainer>
          </MuiPickersUtilsProvider>
          <OfficeSelection data={data} callback={SetOffice} />
        </>
      )}
      {!loading && office !== null && Admin && (
        <FloorMap
          Admin={Admin}
          date={selectedDate}
          id={office.id}
          location={office.location}
        />
      )}
      {!loading && office !== null && !Admin && (
        <FloorMapBooking
          date={selectedDate}
          id={office.id}
          location={office.location}
        />
      )}
    </>
  );
}
DeskBooking.propTypes = {
  Admin: PropTypes.bool,
};
