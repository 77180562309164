import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { isArray } from 'services/variables';
export default function DisplayQuestionaire({ data, show }) {
  // getModalStyle is not a pure function, we roll the style only on the first render

  return (
    <TableContainer>
      <Table
        style={{ maxHeight: '400px' }}
        stickyHeader
        aria-label="simple table"
      >
        <TableHead>
          <TableRow>
            <TableCell>Question</TableCell>
            <TableCell align="right">Answer</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {show &&
            data.questions.map(({ type, name, title, isRequired }) => {
              var questionaireItem = undefined;
              if (data.questionaire && data.questionaire.length > 0) {
                questionaireItem = data.questionaire.find(
                  (e) => e.key === name
                );
                var val = undefined;
                if (questionaireItem) {
                  val = questionaireItem['value'];
                  var options = questionaireItem['valueOptions'];
                  if (options != null && isArray(options)) {
                    val = options.join(', ');
                  }
                }
              }
              if (val === undefined) {
                val = '--------';
              }
              return (
                <TableRow key={name}>
                  <TableCell>{title}</TableCell>
                  <TableCell align="right">{val}</TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
