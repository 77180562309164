import { successColor, grayColor } from 'assets/jss/material-kit-react.js';

const dashboardStyle = {
  BigIcon: {
    fontSize: '3rem',
    marginLeft: '20px',
  },
  warningCardHeader: { '&svg': { color: '#F2994A' } },
  alertCardHeader: { '&svg': { color: '#EB5757' } },
  requestedCardHeader: { '&svg': { color: '#F2C94C' } },
  recommendedCardHeader: { '&svg': { color: '#27AE60' } },
  commentsCardHeader: { '&svg': { color: '#1E3252' } },
  infoSuccessCardHeader: { '&svg': { color: '#6FCF97' } },
  dangerCardHeader: {},
  infoCardHeader: { '&svg': { color: '#CDA96C' } },
  primaryCardHeader: {
    '&svg': { color: '#2D9CDB' },
  },
  roseCardHeader: { '&svg': { color: '#9B51E0' } },
  successText: {
    color: successColor[0],
  },
  upArrowCardCategory: {
    width: '16px',
    height: '16px',
  },
  CardContent: {
    paddingTop: '20px',
    display: 'flex',
    flexFlow: 'row wrap',
  },
  GraphContent: {
    // display: 'flex',
    // flex: '1',
    // minHeight: 'auto',
    //justifyContent: 'right',
  },
  CardIcon: {
    paddingTop: '20px',
  },
  GraphTitleGraph: {
    color: grayColor,
    display: 'flex',
    flex: '1',
    minHeight: 'auto',
    fontWeight: '800',
    fontSize: '1.1rem',
  },
  GraphTitle: {
    color: grayColor,
    textAlign: 'right',
    // display: 'flex',
    // flex: '1',
    // minHeight: 'auto',
    fontWeight: '800',
    // justifyContent: 'flex-end',
    fontSize: '1.1rem',
  },
  stats: {
    color: '#ccc !important',
    textAlign: 'right',
    // justifyContent: 'flex-end',
    // display: 'flex',
    // flex: '1',
    // fontSize: '12px',
    // lineHeight: '22px',
    // '& a': {
    //   textDecoration: 'none',
    // },
    // '& svg': {
    //   top: '4px',

    //   position: 'relative',
    //   marginRight: '3px',
    //   marginLeft: '3px',
    // },
    // '& .fab,& .fas,& .far,& .fal,& .material-icons': {
    //   top: '4px',
    //   fontSize: '16px',
    //   position: 'relative',
    //   marginRight: '3px',
    //   marginLeft: '3px',
    // },
  },
  cardCategory: {
    // float: 'right',
    // margin: '0',
    // fontSize: '1.3rem',
    // marginTop: '-60px',
    // marginRight: '20px',
    // paddingTop: '20px',
    // marginBottom: '0',
    // color: '#999',
  },

  cardTitle: {
    textAlign: 'right',
    color: grayColor[2],
    // float: 'right',
    // marginTop: '-20px',
    // marginRight: '20px',
    // minHeight: 'auto',
    // fontWeight: '800',
    fontSize: '3rem',
    // marginBottom: '3px',
    // textDecoration: 'none',
    // '& small': {
    //   color: grayColor[1],
    //   fontWeight: '400',
    //   lineHeight: '1',
    // },
  },
};

export default dashboardStyle;
