import { getUser, canSwitchCompany, getProfile } from './context.jsx';
export default function authHeader(withMultipart = false) {
  const user = getUser();
  if (canSwitchCompany()) {
    const profile = getProfile();

    if (user && user.access_token) {
      if (withMultipart) {
        return {
          Authorization: 'Bearer ' + user.access_token,
          ImpersonateCompany: profile.companyid,
          'Content-Type': 'multipart/form-data',
        };
      }

      return {
        Authorization: 'Bearer ' + user.access_token,
        ImpersonateCompany: profile.companyid,
      };
    }
  }

  if (user && user.access_token) {
    //console.log('token', user.access_token)
    if (withMultipart) {
      return {
        Authorization: 'Bearer ' + user.access_token,
        'Content-Type': 'multipart/form-data',
      };
    }
    return {
      Authorization: 'Bearer ' + user.access_token,
    };
  } else {
    return {};
  }
}
