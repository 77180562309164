import React from 'react';
import PropTypes from 'prop-types';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import { Link, useLocation } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import HeaderLogo from 'components/Header/HeaderLogo';
import HeaderLinks from 'components/Header/HeaderLinks';

import SvgIcon from '@material-ui/core/SvgIcon';
import {
  isCapellaAdmin,
  isReviewerAdmin,
  getCompanyName,
  getCompanyLocation,
} from '../../services/context';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import Routes from '../../routes';
const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    backgroundColor: '#F2F2F2',
    backgroundSize: 'cover',
    backgroundPosition: 'top center',

    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    '@media print': {
      display: 'none',
    },
  },
  navButton: {
    color: '#fff',
    '&active': {
      backgroundColor: 'red !important',
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  sidebarWrapper: {
    position: 'relative',
    height: 'calc(100vh - 10px)',
    overflow: 'auto',
    width: '240px',
    right: 0,
    zIndex: 4,
    opacity: 0.95,
    paddingBottom: '30px',
  },
  sidebarBackground: {
    // backgroundImage: `url(${SidebarImage})`,
    // opacity: 0.25,
    backgroundColor: '#101B2C',
    position: 'absolute',
    zIndex: 1,
    height: '100%',
    width: '100%',
    display: 'block',
    top: 0,
    left: 0,
    right: 0,
    backgroundSize: 'cover',
    backgroundPosition: '50%',
  },
  separator: {
    backgroundColor: 'white',
  },
  sidebar: {
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    width: '240px',
    display: 'block',
    zIndex: 1,
    color: 'white',
    fontWeight: 200,
    backgroundSize: 'cover',
    backgroundPosition: '50%',
  },
  toolbarBackground: {
    backgroundColor: '#151723',
  },
  navigation: {},
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    marginTop: theme.spacing(5),
    padding: theme.spacing(3),
  },
  companyName: {
    color: 'white',
    fontSize: '2rem',
    fontWeight: 600,
    padding: theme.spacing(3),
  },
  pageTitle: {
    color: '#101B2C',
    fontSize: '2rem',
    fontWeight: 600,
    padding: theme.spacing(1),
  },
  activeMenuOption: {
    backgroundColor: '#fafafa',
    borderLeft: '9px solid #CDA96C',
    color: '#101B2C',
    '&:hover': {
      backgroundColor: '#f2f2f2',
    },
    '& svg': {
      color: '#CDA96C',
    },
  },
  menuOption: {
    borderLeft: '9px solid transparent',
    '&:hover': {
      color: '#CDA96C',
      borderLeft: '9px solid #CDA96C',
      '& svg': {
        color: '#CDA96C',
      },
    },
  },
}));

export default function Layout(props) {
  const { window, title, children, icon } = props;
  const theme = useTheme();
  const location = useLocation();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const activeClass = (path) => {
    if (location.pathname === path) {
      return classes.activeMenuOption;
    }
    return classes.menuOption;
  };

  // const getIcon = () => {
  //   Routes.filter(function (option) {
  //     return option.path === location.pathname;
  //   }).map((r) => {
  //     return <SvgIcon>{r.icon}</SvgIcon>;
  //   });
  // };

  const classes = useStyles();
  const drawer = (
    <div className={classes.sidebar}>
      <div className={classes.sidebarBackground}></div>
      <div className={classes.sidebarWrapper}>
        <div className={classes.toolbar + ' ' + classes.toolbarBackground}>
          <Typography className={classes.companyName}>
            {getCompanyName()}
            {getCompanyLocation() !== '' && (
              <>
                <br />
                <small style={{ color: '#fff' }}>{getCompanyLocation()}</small>
              </>
            )}
          </Typography>
        </div>
        <Divider />

        <div className={classes.navigation}>
          {(isCapellaAdmin() || isReviewerAdmin()) && (
            <>
              <List>
                {Routes.filter(function (option) {
                  return option.menu && option.master;
                }).map((r) => {
                  return (
                    <ListItem
                      button
                      key={r.path}
                      component={Link}
                      to={r.path}
                      className={activeClass(r.path)}
                    >
                      <ListItemIcon className={classes.navButton}>
                        {r.icon}
                      </ListItemIcon>
                      <ListItemText primary={r.name} />
                    </ListItem>
                  );
                })}
              </List>
              <Divider className={classes.separator} />
            </>
          )}
          {(isReviewerAdmin() || isCapellaAdmin()) && (
            <>
              <List>
                {Routes.filter(function (option) {
                  return option.menu && !option.master && option.admin;
                }).map((r) => {
                  return (
                    <ListItem
                      button
                      key={r.path}
                      component={Link}
                      to={r.path}
                      className={activeClass(r.path)}
                    >
                      <ListItemIcon className={classes.navButton}>
                        {r.icon}
                      </ListItemIcon>
                      <ListItemText primary={r.name} />
                    </ListItem>
                  );
                })}
              </List>
              <Divider className={classes.separator} />
            </>
          )}

          <List>
            {Routes.filter(function (option) {
              return option.menu && !option.master && !option.admin;
            }).map((r) => {
              return (
                <ListItem
                  button
                  key={r.path}
                  component={Link}
                  to={r.path}
                  className={activeClass(r.path)}
                >
                  <ListItemIcon className={classes.navButton}>
                    {r.icon}
                  </ListItemIcon>
                  <ListItemText primary={r.name} />
                </ListItem>
              );
            })}
          </List>
        </div>
        <HeaderLogo />
      </div>
    </div>
  );
  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            type="title"
            color="inherit"
            className={classes.pageTitle + ' no-print'}
            style={{ flex: 1, fontSize: '1.8rem' }}
          >
            <SvgIcon
              style={{
                fontSize: '3rem',
                color: '#CDA96C',
                position: 'relative',
                top: '10px',
              }}
            >
              {icon}
            </SvgIcon>{' '}
            {title}
          </Typography>
          <HeaderLinks />
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </main>
    </div>
  );
}
Layout.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
  title: PropTypes.string,
  icon: PropTypes.object,
  children: PropTypes.node.isRequired,
};
