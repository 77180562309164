import React from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/Layout/Layout';
import ShowAssessment from 'components/Assessment';
import { useParams } from 'react-router-dom';
import { Assignment } from '@material-ui/icons';
export default function AnalysisPage(props) {
  const { window } = props;
  const { id } = useParams();

  return (
    <Layout
      window={window}
      title="Individual Risk Assessments/Recommendations"
      icon={<Assignment />}
    >
      <ShowAssessment id={id} />
    </Layout>
  );
}
AnalysisPage.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */

  window: PropTypes.func,
};
