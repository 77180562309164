import React from 'react';
import PropTypes from 'prop-types';

import List from 'components/Hazards/List';
import Layout from 'components/Layout/Layout';
import { RateReview } from '@material-ui/icons';
export default function HazardsManagePage(props) {
  const { window } = props;

  return (
    <Layout
      window={window}
      title="Manage Hazards / Risks"
      icon={<RateReview />}
    >
      <List />
    </Layout>
  );
}

HazardsManagePage.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};
