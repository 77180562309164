import React, { useState, useContext } from 'react';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Button from 'components/CustomButtons/Button';
import { Carousel } from 'react-responsive-carousel';
import { getUser } from 'services/context.jsx';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import photoFront1 from '../../../assets/img/assessment/example-front-1.jpg';
import photoFront2 from '../../../assets/img/assessment/example-front-2.jpg';
import photoFront3 from '../../../assets/img/assessment/example-front-3.jpg';
import photoSide1 from '../../../assets/img/assessment/example-side-1.jpg';
import photoSide2 from '../../../assets/img/assessment/example-side-2.jpg';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import useInterval from 'components/Utils/useInterval';
import Fade from '@material-ui/core/Fade';
import Loading from 'components/Loading';
import { makeStyles } from '@material-ui/core/styles';
import { StepsContext } from './Context';
import { getQRCodeContinueUrl } from 'services/variables';
import { getActiveAssessment } from 'services/user';
import { useHistory } from 'react-router-dom';
import { mdiConsoleLine } from '@mdi/js';
const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  ModalSucess: {
    textAlign: 'center',
    backgroundColor: 'white',
    borderRadius: '20px',
  },
}));
export default function UploadPhotos() {
  const classes = useStyles();
  const history = useHistory();
  const [context, setContext] = useContext(StepsContext);
  const [sidePhoto, setSidePhoto] = useState(context[3].SidePhoto);
  const [sidePhotoUrl, setSidePhotoUrl] = useState(context[3].SidePhotoUrl);
  const [frontPhoto, setFrontPhoto] = useState(context[3].FrontPhoto);
  const [frontPhotoUrl, setFrontPhotoUrl] = useState(context[3].FrontPhotoUrl);
  const [canContinue] = useState(context[3].canContinue);
  const [showQRCode, SetShowQRCode] = useState(false);
  const [QRCodeLink, SetQRCodeLink] = useState(null);
  const [loadingQRCode, SetLoadingQRCode] = useState(false);
  const handleUploadSide = (event) => {
    var photo = event.target.files[0];
    var url = URL.createObjectURL(photo);
    const _newContext = context;
    _newContext[3].SidePhoto = photo;
    _newContext[3].SidePhotoUrl = url;
    setSidePhoto(photo);
    setSidePhotoUrl(url);
    setContext(_newContext);
  };
  useInterval(() => {
    // put your interval code here.
    if (QRCodeLink !== null) {
      getActiveAssessment().then((response) => {
        var data = response.data;
        if (data.closed === '1') {
          history.push('/completed');
        }
      });
    }
  }, 1000 * 10);
  const handleImageLoaded = () => {
    SetLoadingQRCode(false);
  };
  const ClaimQRCode = () => {
    SetShowQRCode(true);
    if (QRCodeLink === null) {
      SetLoadingQRCode(true);
      const user = getUser();
      // console.log('user', user);
      var link = getQRCodeContinueUrl(
        btoa(user.access_token),
        btoa(user.expiresAt)
      );
      //console.log('link', link);
      SetQRCodeLink(link);
    }
  };
  const handleUploadFront = (event) => {
    var photo = event.target.files[0];
    var url = URL.createObjectURL(photo);
    const _newContext = context;
    _newContext[3].FrontPhoto = photo;
    _newContext[3].FrontPhotoUrl = url;
    setFrontPhoto(photo);
    setFrontPhotoUrl(url);
    setContext(_newContext);
  };
  const GetUploadedPhoto = (photo, alt) => {
    if (photo === undefined || photo === '') {
      return <></>;
    }
    return (
      <img
        src={photo}
        alt={alt}
        style={{ maxWidth: '160px', maxHeight: '160px' }}
      />
    );
  };
  return (
    <>
      <h2>Upload photos</h2>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={showQRCode}
        onClose={() => SetShowQRCode(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <GridContainer className={classes.ModalSucess}>
          <GridItem>
            <h2 id="transition-modal-title">Continue on your mobile device</h2>
            <p id="transition-modal-description">
              Scan this QR Code with your phone or tablet to upload your photos
            </p>
          </GridItem>
          <GridItem>
            <Loading show={loadingQRCode} />
          </GridItem>
          <GridItem style={{ textAlign: 'center' }}>
            <img
              src={QRCodeLink}
              onLoad={handleImageLoaded.bind(this)}
              style={{
                display: loadingQRCode ? 'none' : 'block',
                margin: '0 auto',
                maxWidth: '100%',
              }}
              alt="continue on another device"
            />
          </GridItem>
        </GridContainer>
      </Modal>
      <GridContainer>
        <GridItem md={12}>
          <p>
            Please try to follow the instructions that you saw in the training
            video earlier in this process, and take two photographs.
            <br />
            Don't worry, we've seen home offices of every shape and size!
          </p>
        </GridItem>
        {canContinue && (
          <GridItem md={12}>
            <Button color="secundary" onClick={() => ClaimQRCode()}>
              I'd prefer to use my phone or tablet to upload my photos
            </Button>
          </GridItem>
        )}
        <GridItem xs={6} sm={6} md={6} style={{ marginBottom: '20px' }}>
          <h2>Front Photo</h2>
          <p>
            Please remove the chair and take a picture of the full desk
            (including the edges), clearly showing all your equipment.
            <br />
            You should stand behind where the chair would normally be in order
            to take this photograph.
          </p>

          <label htmlFor="btn-upload-front">
            <input
              id="btn-upload-front"
              name="btn-upload-front"
              style={{ display: 'none' }}
              type="file"
              onChange={handleUploadFront}
            />
            <Button className="btn-choose" variant="outlined" component="span">
              Upload Front Photo
            </Button>
          </label>

          <br />

          {frontPhoto && GetUploadedPhoto(frontPhotoUrl, 'uploaded front')}
        </GridItem>
        <GridItem xs={6} sm={6} md={6} style={{ marginBottom: '20px' }}>
          <Carousel
            dynamicHeight={false}
            infiniteLoop={true}
            showThumbs={false}
            height="100px"
            autoPlay={true}
          >
            <div>
              <img src={photoFront1} alt="Front Example 1" />
            </div>
            <div>
              <img src={photoFront2} alt="Front Example 2" />
            </div>
            <div>
              <img src={photoFront3} alt="Front Example 3" />
            </div>
          </Carousel>
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem xs={6} sm={6} md={6} style={{ marginBottom: '20px' }}>
          <h2>Side Photo</h2>
          <p>
            Please now return the chair to where it would normally be, and take
            a side profile picture of the desk and chair.
            <br />
            Please make sure that the chair is clearly visible and not tucked
            under the desk.
          </p>
          <label htmlFor="btn-upload-side">
            <input
              id="btn-upload-side"
              name="btn-upload-side"
              style={{ display: 'none' }}
              type="file"
              onChange={handleUploadSide}
            />
            <Button className="btn-choose" variant="outlined" component="span">
              Upload Side Photo
            </Button>
          </label>

          <br />
          {sidePhoto && GetUploadedPhoto(sidePhotoUrl, 'uploaded side')}
        </GridItem>
        <GridItem xs={6} sm={6} md={6} style={{ marginBottom: '20px' }}>
          <Carousel
            dynamicHeight={false}
            infiniteLoop={true}
            showThumbs={false}
            height="100px"
            autoPlay={true}
          >
            <div>
              <img src={photoSide1} alt="Side Example 1" />
            </div>
            <div>
              <img src={photoSide2} alt="Side Example 2" />
            </div>
          </Carousel>
        </GridItem>
      </GridContainer>
    </>
  );
}
