import React, { useState, useEffect } from 'react';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
// nodejs library that concatenates classes
import Button from 'components/CustomButtons/Button.js';
import TextField from '@material-ui/core/TextField';
import { addEquipment, getEquipments } from 'services/user';
import Select from '@material-ui/core/Select';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import RemoveIcon from '@material-ui/icons/Remove';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function EquipmentValidate({ data, callback }) {
  const classes = useStyles();
  const [saving, setSaving] = useState(false);
  const [addFree, setAddFree] = useState(false);
  const [equipmentList, setEquipmentList] = useState([]);
  const [equipment, setEquipment] = useState('');
  const [freeEquipment, setFreeEquipment] = useState('');
  const [priority, setPriority] = useState();

  const LoadData = () => {
    getEquipments()
      .then((response) => {
        setEquipmentList(response.data.equipments);
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  useEffect(() => {
    LoadData();
  }, []);
  const addEquipments = () => {
    var body = {
      assessmentid: data.id,
      equipment: equipment,
      priority: priority,
    };
    setSaving(true);
    addEquipment(body).then(() => {
      setSaving(false);
      callback();
    });
    //save
  };
  return (
    <GridContainer style={{ paddingLeft: '10px' }}>
      {!addFree && (
        <>
          <GridItem md={2} style={{ paddingTop: '10px' }}>
            <InputLabel id="demo-simple-select-helper-label">
              Equipment
            </InputLabel>
          </GridItem>
          <GridItem md={9}>
            <Select
              className={classes.formControl}
              display={!addFree}
              id="Equipment"
              label="Equipment"
              minWidth="150"
              fullWidth
              value={equipment}
              onChange={(e) => setEquipment(e.target.value)}
              helperText="Please select hazard to add"
            >
              {equipmentList.map(
                (option) =>
                  option.id !== '' && (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  )
              )}
            </Select>
          </GridItem>
          {/* <GridItem md={1}>
            <IconButton aria-label="Add">
              <AddIcon
                onClick={() => {
                  setAddFree(true);
                  setEquipment(null);
                  setPriority(null);
                }}
              />
            </IconButton>
          </GridItem> */}
        </>
      )}
      {addFree && (
        <>
          <GridItem md={11}>
            <TextField
              id="Equipment"
              label="Equipment"
              fullWidth
              display={addFree}
              value={freeEquipment}
              onChange={(e) => setFreeEquipment(e.target.value)}
            />
          </GridItem>
          <GridItem md={1}>
            <IconButton aria-label="Add">
              <RemoveIcon
                onClick={() => {
                  setFreeEquipment(null);
                  setAddFree(false);
                }}
              />
            </IconButton>
          </GridItem>
        </>
      )}
      <GridItem md={2} style={{ paddingTop: '10px' }}>
        <InputLabel id="demo-simple-select-helper-label">Priority</InputLabel>
      </GridItem>
      <GridItem md={9}>
        <Select
          className={classes.formControl}
          display={!addFree}
          id="priority"
          label="Priority"
          minWidth="150"
          fullWidth
          value={priority}
          onChange={(e) => setPriority(e.target.value)}
          helperText="Please select priority"
        >
          <MenuItem key="recommended" value="1">
            Recommended
          </MenuItem>
          <MenuItem key="optional" value="0">
            Optional
          </MenuItem>
        </Select>
      </GridItem>

      <GridItem md={12}>
        <Button
          variant="outlined"
          loading={saving}
          color="primary"
          onClick={addEquipments}
        >
          Save
        </Button>
      </GridItem>
    </GridContainer>
  );
}

EquipmentValidate.propTypes = {
  data: PropTypes.object,
  callback: PropTypes.func,
};
