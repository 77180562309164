import React from 'react';
import PropTypes from 'prop-types';

import EquipmentsMatrix from 'components/Equipments/Matrix';
import Layout from 'components/Layout/Layout';
import { Apps } from '@material-ui/icons';
export default function EmployeesListPage(props) {
  const { window } = props;

  return (
    <Layout window={window} title="Assessments / Equipment" icon={<Apps />}>
      <EquipmentsMatrix />
    </Layout>
  );
}

EmployeesListPage.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};
