//https://github.com/slutske22/react-leaflet-editable-popup/blob/master/example/src/components/Map.js
import Drawer from '@material-ui/core/Drawer';

import React, { useState, useEffect } from 'react';
import Switch from '@material-ui/core/Switch';
import PropTypes from 'prop-types';
import {
  MapContainer,
  ImageOverlay,
  FeatureGroup,
  TileLayer,
  useMapEvents,
  Marker,
  MapConsumer,
} from 'react-leaflet';

import 'leaflet-draw/dist/leaflet.draw.css';
import { Input, SwipeableDrawer } from '@material-ui/core';
import L from 'leaflet';
import * as IconMarker from './icons';
import { makeStyles } from '@material-ui/core/styles';
import 'leaflet/dist/leaflet.css';
import Popup from 'react-leaflet-editable-popup';
import InputLabel from '@material-ui/core/InputLabel';
import Button from 'components/CustomButtons/Button.js';
import styles from 'assets/jss/material-kit-react/views/componentsSections/basicsStyle.js';

import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';

import MenuItem from '@material-ui/core/MenuItem';
import {
  SaveBookableMap,
  GetBookableMapURI,
  GetBookableMap,
} from '../../services/user';
import { v4 as uuidv4 } from 'uuid';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import PrimaryTypo from 'components/Typography/Primary';
import InfoTypo from 'components/Typography/Info';
import FormControlLabel from '@material-ui/core/FormControlLabel';
// const styles = (theme) => ({
//   fadeIn: {
//     opacity: 1,
//     height: '100vh',
//     width: '80vw',
//     transition: 'width 0.5s, height 0.5s, opacity 0.5s 0.5s',
//   },
//   fadeOut: {
//     opacity: 0,
//     width: 0,
//     height: 0,
//     transition: 'width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s',
//   },
//   speedDial: {
//     position: 'absolute',
//     bottom: theme.spacing(2),
//     right: theme.spacing(2),
//   },
// });

// const useStyles = makeStyles(styles);

const bounds = [
  [0, 0],
  [800, 1800],
];
const useStyles = makeStyles(styles);
//const style = { height: '100vh', width: '80vw' };

export default function FloorMap(props) {
  const classes = useStyles();
  const { Admin, id, location, date } = props;
  const [openDepartments, setOpenDepartments] = React.useState(false);
  const [floormapVisual, setFloormapVisual] = useState(null);
  const [map, setMap] = useState(null);
  const [markers, setMarkers] = useState([]);
  const [showDrawer, setShowDrawer] = useState(false);
  const [loading, setLoading] = useState(true);
  const imageOverlayRef = React.createRef();
  const [departments, setDepartments] = useState([]);
  const [swap, setSwap] = React.useState(false);

  // const SetAddMarker = (enable) => {
  //   let _map = map;
  //   if (enable) {
  //     _map.on('click', function (e) {
  //       if (!editing) {
  //         return;
  //       }
  //       const { lat, lng } = e.latlng;
  //       L.marker([lat, lng], { icon }).addTo(_map.target);
  //     });
  //   }
  //   setMap(_map);
  // };
  var group = L.layerGroup();

  const _setMapRef = (map) => {
    L.tileLayer('', {
      attribution:
        '&copy; <a href="http://capella-ws.com" target="_new">Capella Workplace Solutions</a>',
    }).addTo(map);
    map.addLayer(group);

    const image = L.imageOverlay(GetBookableMapURI(id), bounds).addTo(map);
    map.fitBounds(image.getBounds());
    setMap(map);
  };
  const MapPlaceholder = () => {
    return (
      <p>
        Floor Map.{' '}
        <noscript>You need to enable JavaScript to see this map.</noscript>
      </p>
    );
  };
  const [showAll, setShowAll] = useState(false);
  const [editing, SetEditing] = useState(false);
  const style = {
    top: '0px',
    left: '0px',
    zIndex: '0',
    height: '100vh',
    width: '80vw',
    backgroundColor: 'transparent',
  };

  const EnableEdit = () => {
    // SetAddMarker(!editing);

    SetEditing(!editing);
  };

  function MyComponent() {
    const map = useMapEvents({
      click: (e) => {
        if (editing) {
          const { lat, lng } = e.latlng;
          var _id = uuidv4();
          const newMarker = L.marker([lat, lng], {
            icon: IconMarker.iconForge('aa'),
          })
            //.setIcon(thisIcon)
            .openPopup()
            .addTo(map);

          // group.addLayer(newMarker);
          var _markers = markers;

          var _type = 'table';
          _markers.push({
            id: newMarker._leaflet_id,
            uid: _id,
            type: _type,
            position: [e.latlng.lat, e.latlng.lng],
          });

          setMarkers(_markers);
        }
      },
    });
    return null;
  }
  var interval = null;
  const rotateImage = (image, angle) => {
    var originalTransformValue = image.style.transform;

    image.style.transform = `${originalTransformValue} rotateZ(${angle}deg)`;
  };
  const tilt = () => {
    const imageLayer = imageOverlayRef.current._image;

    setSwap(true);
    // var opacity = imageLayer.options.opacity;
    // console.log('opacity', imageLayer);
    // var _newOpacity = opacity;
    // interval = setInterval(() => {
    //   if (_newOpacity <= 0) {
    //     //lets start the new one

    //     clearInterval(interval);
    //   } else {
    //     var newOpacity = opacityImage - 0.1;
    //     setOpacityImage(newOpacity);
    //     L.DomUtil.setOpacity(imageLayer, newOpacity);

    //     imageLayer.setAttribute('opacity', newOpacity);
    //   }
    // }, 500);

    // const image = L.imageOverlay(
    //   'https://i.imgur.com/Ion6X7C.jpg',
    //   bounds
    // ).addTo(map);
    // image._url =
    //   'https://qiita-image-store.s3.ap-northeast-1.amazonaws.com/0/60444/7c104a3e-0b14-3b7f-7d9e-2953ed24efbd.gif';
    // rotateImage(image, 30);
  };
  useEffect(() => {
    setLoading(true);

    GetBookableMap(id).then((res) => {
      let data = res.data;
      var bookableItems = data.floormap;
      if (bookableItems !== null) {
        setMarkers(JSON.parse(bookableItems.replace(/\\"/g, '"')));
      }
      if (data.departments != null) {
        setDepartments(JSON.parse(data.departments.replace(/\\"/g, '"')));
      }

      setLoading(false);
    });
  }, []);

  const setMarkDepartment = (index, department) => {};
  const removeMarker = (map, index) => {
    const newMarkers = [...markers];
    const item = newMarkers[index];

    newMarkers.splice(index, 1);

    //map.removeLayer(item.id);
    setMarkers(newMarkers);
    // this.setState({
    // 	randomMarkers: newRandomMarkers,
    // });
  };
  const updateMarker = (content, index) => {
    console.log('updated index', index, ' with content ', content);
  };
  const SaveBookingMap = () => {
    setLoading(true);
    var body = {
      floormap: markers,
      id: id,
    };
    SaveBookableMap(body).then(() => {
      setLoading(false);
    });
  };

  const onClosePopup = (index) => {
    const newRandomMarkers = markers.map((marker, i) => {
      if (i === index) {
        return {
          ...marker,
          open: false,
        };
      } else {
        return { ...marker };
      }
    });
    setMarkers(newRandomMarkers);
  };
  const onOpenPopup = (index) => {
    console.log('markers', markers);

    const newRandomMarkers = markers.map((marker, i) => {
      if (i === index) {
        var markInfo = {
          ...marker,
          open: true,
        };
        console.log('selected markInfo', markInfo);

        return markInfo;
      } else {
        return { ...marker };
      }
    });
    setMarkers(newRandomMarkers);
    // Desk Identifier:{' '}
    // <Input placeholder="Identifier" value={mark.uid} />
    // Department:{' '}
    // <Select
    //   style={{ minWidth: '300px' }}
    //   display={true}
    //   id="Department"
    //   label="Department"
    //   minWidth={300}
    //   value={mark.department}
    //   // value={equipment}
    //   onChange={(e) =>
    //     setMarkDepartment(index, e.target.value)
    //   }
    // >
    //   {departments.map((option) => (
    //     <MenuItem key={option} value={option}>
    //       {option}
    //     </MenuItem>
    //   ))}
    // </Select>
  };
  return (
    <>
      <GridContainer>
        <GridItem md={6}>
          <PrimaryTypo>Selected location {location} </PrimaryTypo>
        </GridItem>

        {/* <GridItem md={12}>
          Department{' '}
          <Select
            style={{ minWidth: '300px' }}
            display={true}
            id="Department"
            label="Department"
            minWidth={300}
            // value={equipment}
            // onChange={(e) => setEquipment(e.target.value)}
          >
            {departments.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </GridItem> */}
        <GridItem md={12}>
          {Admin && (
            <>
              <Button color="primary" onClick={() => EnableEdit()}>
                {editing ? 'Save and Stop Adding' : 'Add Booking tables'}
              </Button>

              <Button
                color="primary"
                loading={loading}
                onClick={() => SaveBookingMap()}
              >
                Save and publish map
              </Button>
            </>
          )}
        </GridItem>
        <GridItem md={12}>
          <MapContainer
            crs={L.CRS.Simple}
            minZoom={0}
            maxZoom={3}
            doubleClickZoom={false}
            maxBounds={bounds}
            bounds={bounds}
            boundsOptions={{ padding: [50, 50] }}
            whenCreated={_setMapRef}
            style={style}
            placeholder={<MapPlaceholder />}
          >
            <MyComponent />

            {markers.map((mark, index) => (
              <Marker
                key={'u' + mark.id}
                icon={IconMarker.iconForge('+', false)}
                position={mark.position} //
              >
                {/* show place's title on hover the marker */}
                {Admin && (
                  <Popup
                    removable
                    editable
                    // open={mark.open}
                    sourceKey={index}
                    removalCallback={() => {
                      removeMarker(map, index);
                    }}
                    saveContentCallback={(content) => {
                      updateMarker(content, index);
                    }}
                    // onOpen={() => onOpenPopup(index)}
                    // onClose={() => onClosePopup(index)}
                  >
                    {mark.uid}
                  </Popup>
                )}
              </Marker>
            ))}
          </MapContainer>
        </GridItem>
      </GridContainer>

      <SwipeableDrawer
        anchor="right"
        open={showDrawer}
        onClose={() => setShowDrawer(false)}
        onOpen={() => setShowDrawer(true)}
      >
        adasdasd
      </SwipeableDrawer>
    </>
  );
}
FloorMap.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  id: PropTypes.string,
  location: PropTypes.string,
  date: PropTypes.instanceOf(Date),
};
