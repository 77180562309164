import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Icon from '@material-ui/core/Icon';
import Layout from 'components/Layout/Layout';
import { getAdminOverview } from 'services/user';
import Loading from 'components/Loading';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import PrimaryTypo from 'components/Typography/Primary';
import CustomInput from 'components/CustomInput/CustomInput.js';
import InputAdornment from '@material-ui/core/InputAdornment';
import {
  SettingsInputComponent,
  PermIdentitySharp,
  FingerprintSharp,
} from '@material-ui/icons';
import styles from 'assets/jss/material-kit-react/views/loginPage.js';
const useStyles = makeStyles(styles);
export default function CompanyOverviewPage(props) {
  const { window } = props;
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);

  const LoadData = () => {
    setLoading(true);
    getAdminOverview().then((res) => {
      //let _companies = res.data;
      //setIntegrationsData(_companies);

      setLoading(false);
    });
  };

  useEffect(() => {
    // code to run on component mount
    LoadData();
  }, []);
  return (
    <Layout
      window={window}
      title="Integrations - Personio"
      icon={<SettingsInputComponent />}
    >
      <PrimaryTypo>Edit Personio Integration</PrimaryTypo>
      <GridContainer>
        <GridItem md={6}>
          <Loading show={loading} />
          {!loading && (
            <>
              <CustomInput
                labelText="Company Id"
                id="companyId"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <PermIdentitySharp />
                    </InputAdornment>
                  ),
                  autoComplete: 'off',
                }}
              />

              <CustomInput
                labelText="Client Id"
                id="clientId"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <FingerprintSharp />
                    </InputAdornment>
                  ),
                  autoComplete: 'off',
                }}
              />

              <CustomInput
                labelText="Client Secret"
                id="pass"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  type: 'password',

                  endAdornment: (
                    <InputAdornment position="end">
                      <Icon className={classes.inputIconsColor}>
                        lock_outline
                      </Icon>
                    </InputAdornment>
                  ),
                  autoComplete: 'off',
                }}
              />
            </>
          )}
        </GridItem>
      </GridContainer>
    </Layout>
  );
}

CompanyOverviewPage.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};
