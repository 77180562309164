import React, { useEffect, useState } from 'react';
import Loading from 'components/Loading';
import Button from 'components/CustomButtons/Button.js';
import { GetReminders, SendReminders } from 'services/user';
import MUIDataTable from 'mui-datatables';
import { GetUserAssessments } from 'services/user';
import { Edit, Done } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import PrimaryTypo from 'components/Typography/Primary';
import InfoTypo from 'components/Typography/Info';
import Graph from './Graph';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { CenterFocusStrong } from '@material-ui/icons';
import CustomToolbarSelect from './SelectToolbar';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      textAlign: 'center',
      align: CenterFocusStrong,
      margin: theme.spacing(1),
      width: theme.spacing(16),
      height: theme.spacing(16),
    },
  },

  customAlert: {
    textAlign: 'center',
    align: CenterFocusStrong,
    margin: theme.spacing(1),
    width: '100%',
    height: theme.spacing(16),
  },
}));
export default function RemindersList() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const history = useHistory();
  // const [questionaires, setQuestionaires] = useState(null);
  // const [departments, setDepartments] = useState(null);
  // const [addAction, showAddAction] = useState(false);

  const columns = [
    {
      label: 'ID',
      name: 'id',
      options: {
        filter: false,
        display: false,
      },
    },

    {
      label: 'Name',
      name: 'first_name',
      options: {
        filter: false,
      },
    },
    {
      label: 'SurName',
      name: 'last_name',
      options: {
        filter: false,
      },
    },
    {
      label: 'Department',
      name: 'department',
      options: {
        filter: true,
      },
    },
    {
      label: 'Email',
      name: 'email',
      options: {
        filter: false,
      },
    },
    {
      label: 'Activated',
      name: 'activation_key',
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          if (value == null) {
            // tableMeta contains all the info that is needed
            return <Done />;
          }
        },
      },
    },
    {
      label: 'Activation Key',
      name: 'activation_key',
      options: {
        filter: false,
      },
    },
    {
      label: '# Open Assessment',
      name: 'OpenAssessment',
      options: {
        filter: false,
      },
    },
    {
      label: 'Type',
      name: 'user_type_id',
      options: {
        display: false,
        filter: false,
      },
    },
  ];

  const options = {
    selectableRows: 'multiple',
    filterType: 'checkbox',
    rowsPerPage: 100,
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
      <CustomToolbarSelect
        selectedRows={selectedRows}
        displayData={displayData}
        setSelectedRows={setSelectedRows}
        callback={() => history.push('/reminders-history')}
      />
    ),
  };

  const LoadData = () => {
    GetUserAssessments().then((res) => {
      var _data = res.data;

      if (_data.length === 0) {
        _data = [];
      }

      setData(_data);
      setLoading(false);
    });
  };
  useEffect(() => {
    LoadData();
  }, []);
  return (
    <>
      <Loading show={loading} />
      {!loading && (
        <GridContainer>
          <GridItem md={12}>
            <PrimaryTypo>
              You can use this page to send individual reminders to the
              employees performing assessments
            </PrimaryTypo>
          </GridItem>
          <GridItem md={12}>
            {!loading && (
              <MUIDataTable
                title="Users List"
                data={data}
                columns={columns}
                options={options}
              />
            )}
          </GridItem>
        </GridContainer>
      )}
    </>
  );
}
