import React, { useState } from 'react';
import Button from 'components/CustomButtons/Button.js';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { addCompany } from 'services/user';
import AddIcon from '@material-ui/icons/Add';
import { addDays } from 'services/variables';
import PropTypes from 'prop-types';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
export default function CompanyEdit({ callBack, questionaires }) {
  const classes = useStyles();
  let _tomorrow = addDays(1, new Date());

  let _weekFromTomorrow = addDays(7, _tomorrow);
  const [open, setOpen] = useState(false);
  const [name, setName] = useState('');

  const [questionaireId, setQuestionaireId] = useState(3);
  const [location, setLocation] = useState('');
  const [startDate, setStartDate] = useState(_tomorrow);
  const [endDate, setEndDate] = useState(_weekFromTomorrow);

  const changeStartDate = (dt) => {
    setStartDate(dt);
    var _newEnd = addDays(7, dt);
    setEndDate(_newEnd);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleSave = () => {
    addCompany({
      name: name,
      location: location,
      defaultQuestionaire: questionaireId,
      starExpected: startDate,
      endExpected: endDate,
    }).then(() => {
      callBack();
      handleClose();
    });
  };
  return (
    <div>
      <Button
        variant="outlined"
        color="primary"
        tooltip="Add Company"
        onClick={handleClickOpen}
      >
        <AddIcon />
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Add Company</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Add company by providing their details
          </DialogContentText>
          <GridContainer>
            <GridItem md={12}>
              <TextField
                margin="dense"
                id="name"
                label="Company name"
                value={name}
                onChange={(event) => setName(event.target.value)}
                fullWidth
              />
            </GridItem>
            <GridItem md={12}>
              <TextField
                margin="dense"
                id="location"
                label="Location"
                value={location}
                onChange={(event) => setLocation(event.target.value)}
                fullWidth
              />
            </GridItem>
            <GridItem md={12}>
              <InputLabel id="demo-simple-select-helper-label">
                Default Questionaire
              </InputLabel>
              <Select
                className={classes.formControl}
                id="questionaire"
                label="Questionaire to apply"
                minWidth="150"
                fullWidth
                value={questionaireId}
                onChange={(e) => setQuestionaireId(e.target.value)}
              >
                {questionaires &&
                  questionaires.map(({ id, name }) => {
                    return (
                      <MenuItem key={id} value={id}>
                        {name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </GridItem>{' '}
            <GridItem md={5}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  format="dd/MM/yyyy"
                  variant="inline"
                  label="Start Date"
                  value={startDate}
                  onChange={changeStartDate}
                />
              </MuiPickersUtilsProvider>
            </GridItem>
            <GridItem md={5}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  variant="inline"
                  format="dd/MM/yyyy"
                  label="Expected End Date"
                  value={endDate}
                  onChange={setEndDate}
                />
              </MuiPickersUtilsProvider>{' '}
            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

CompanyEdit.propTypes = {
  callBack: PropTypes.func,
  questionaires: PropTypes.array,
};
