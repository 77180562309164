//https://github.com/slutske22/react-leaflet-editable-popup/blob/master/example/src/components/Map.js
import Drawer from '@material-ui/core/Drawer';

import React, { useState, useEffect } from 'react';
import Switch from '@material-ui/core/Switch';
import Loading from 'components/Loading';
import PropTypes from 'prop-types';
import {
  MapContainer,
  ImageOverlay,
  FeatureGroup,
  TileLayer,
  useMapEvents,
  Marker,
  MapConsumer,
  ZoomControl,
} from 'react-leaflet';
import InfoContact from './InfoContact';
import 'leaflet-draw/dist/leaflet.draw.css';
import { Input, SwipeableDrawer } from '@material-ui/core';
import L from 'leaflet';
import * as IconMarker from './icons';
import { makeStyles } from '@material-ui/core/styles';
import 'leaflet/dist/leaflet.css';
import Popup from 'react-leaflet-editable-popup';
import Button from 'components/CustomButtons/Button.js';

import {
  SaveBookableMap,
  GetBookableMapURI,
  GetBookableMap,
} from '../../services/user';
import { v4 as uuidv4 } from 'uuid';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import PrimaryTypo from 'components/Typography/Primary';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {
  primaryColor,
  dangerColor,
  roseColor,
  grayColor,
} from 'assets/jss/material-kit-react.js';

const bounds = [
  [0, 0],
  [800, 1800],
];
const useStyles = makeStyles(() => ({
  checkRoot: {
    padding: '12px',
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  radioRoot: {
    padding: '12px',
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  labelRoot: {
    marginLeft: '-14px',
  },
  checkboxAndRadio: {
    position: 'relative',
    display: 'block',
    marginTop: '10px',
    marginBottom: '10px',
  },
  checkboxAndRadioHorizontal: {
    position: 'relative',
    display: 'block',
    '&:first-child': {
      marginTop: '10px',
    },
    '&:not(:first-child)': {
      marginTop: '-14px',
    },
    marginTop: '0',
    marginBottom: '0',
  },
  checked: {
    color: primaryColor + '!important',
  },
  checkedIcon: {
    width: '20px',
    height: '20px',
    border: '1px solid rgba(0, 0, 0, .54)',
    borderRadius: '3px',
  },
  uncheckedIcon: {
    width: '0px',
    height: '0px',
    padding: '9px',
    border: '1px solid rgba(0, 0, 0, .54)',
    borderRadius: '3px',
  },
  disabledCheckboxAndRadio: {
    opacity: '0.45',
  },
  label: {
    cursor: 'pointer',
    paddingLeft: '0',

    fontSize: '24px',
    lineHeight: '1.428571429',
    fontWeight: '800',
    display: 'inline-flex',
    transition: '0.3s ease all',
    letterSpacing: 'unset',
  },
  labelHorizontal: {
    color: 'rgba(0, 0, 0, 0.26)',
    cursor: 'pointer',
    display: 'inline-flex',
    fontSize: '14px',
    lineHeight: '1.428571429',
    fontWeight: '400',
    paddingTop: '39px',
    marginRight: '0',
    '@media (min-width: 992px)': {
      float: 'right',
    },
  },
  labelHorizontalRadioCheckbox: {
    paddingTop: '22px',
  },
  labelLeftHorizontal: {
    color: 'rgba(0, 0, 0, 0.26)',
    cursor: 'pointer',
    display: 'inline-flex',
    fontSize: '14px',
    lineHeight: '1.428571429',
    fontWeight: '400',
    paddingTop: '22px',
    marginRight: '0',
  },
  labelError: {
    color: dangerColor,
  },
  radio: {
    color: primaryColor + '!important',
  },
  radioChecked: {
    width: '16px',
    height: '16px',
    border: '1px solid ' + primaryColor,
    borderRadius: '50%',
  },
  radioUnchecked: {
    width: '0px',
    height: '0px',
    padding: '7px',
    border: '1px solid rgba(0, 0, 0, .54)',
    borderRadius: '50%',
  },
  inlineChecks: {
    marginTop: '8px',
  },
  iconCheckbox: {
    height: '116px',
    width: '116px',
    color: grayColor,
    '& > span:first-child': {
      borderWidth: '4px',
      borderStyle: 'solid',
      borderColor: '#CCCCCC',
      textAlign: 'center',
      verticalAlign: 'middle',
      borderRadius: '50%',
      color: 'inherit',
      margin: '0 auto 20px',
      transition: 'all 0.2s',
    },
    '&:hover': {
      color: roseColor,
      '& > span:first-child': {
        borderColor: roseColor,
      },
    },
  },
  iconCheckboxChecked: {
    color: roseColor,
    '& > span:first-child': {
      borderColor: roseColor,
    },
  },
  iconCheckboxIcon: {
    fontSize: '40px',
    lineHeight: '111px',
  },
  switchBase: {
    color: primaryColor + '!important',
  },
  switchIcon: {
    boxShadow: '0 1px 3px 1px rgba(0, 0, 0, 0.4)',
    color: '#FFFFFF !important',
    border: '1px solid rgba(0, 0, 0, .54)',
  },
  switchBar: {
    width: '30px',
    height: '15px',
    backgroundColor: 'rgb(80, 80, 80)',
    borderRadius: '15px',
    opacity: '0.7!important',
  },
  switchChecked: {
    '& + $switchBar': {
      backgroundColor: '#101B2C !important',
    },
    '& $switchIcon': {
      borderColor: '#C4A168',
    },
  },
  switchRoot: {
    height: '48px',
  },
}));
//const style = { height: '100vh', width: '80vw' };

export default function FloorMap(props) {
  const classes = useStyles();
  const { id, location, date } = props;
  const [map, setMap] = useState(null);
  const [markers, setMarkers] = useState([]);
  const [showDrawer, setShowDrawer] = useState(false);
  const [selectedMark, setSelectedMark] = useState(false);
  const [loading, setLoading] = useState(true);

  var group = L.layerGroup();
  const showDrawerInfo = (info) => {
    setShowDrawer(true);
    setSelectedMark(info);
  };
  const _setMapRef = (map) => {
    L.tileLayer('', {
      attribution:
        '&copy; <a href="http://capella-ws.com" target="_new">Capella Workplace Solutions</a>',
    }).addTo(map);
    map.addLayer(group);

    const image = L.imageOverlay(GetBookableMapURI(id), bounds).addTo(map);
    map.fitBounds(image.getBounds());

    setMap(map);
  };
  const MapPlaceholder = () => {
    return (
      <p>
        Floor Map.{' '}
        <noscript>You need to enable JavaScript to see this map.</noscript>
      </p>
    );
  };
  const [showAll, setShowAll] = useState(false);
  const style = {
    top: '-10px',
    left: '0px',
    zIndex: '0',
    height: '90vh',
    width: '80vw',
    backgroundColor: 'transparent',
    position: 'relative',
  };

  useEffect(() => {
    setLoading(true);

    GetBookableMap(id).then((res) => {
      let data = res.data;
      var bookableItems = data.floormap;
      if (bookableItems !== null) {
        var bookable = JSON.parse(bookableItems.replace(/\\"/g, '"'));

        var bookinginfo = [
          {
            deskid: 'Desk-1-1',
            name: 'Mitchell Murphy',
            initials: 'MM',
            department: 'IT',
            picture: '/person/male.jpg',
          },
          {
            deskid: 'Desk-1-1',
            name: 'Frank Duckworth',
            initials: 'KL',
            department: 'IT',
            picture: '/person/male2.jpg',
          },
          {
            deskid: 'Desk-1-3',
            name: 'Trudi Kaitlynn',
            initials: 'TK',
            department: 'Sales',
            picture: '/person/female.jpg',
          },
          {
            deskid: 'Desk-1-3',
            name: 'Marilyn Gutierrez',
            initials: 'MG',
            department: 'HR',
            picture: '/person/female5.jpg',
          },
          {
            deskid: 'Desk-1-3',
            name: 'Sheila Lane',
            initials: 'SL',
            department: 'IT',
            picture: '/person/female3.jpg',
          },
          {
            deskid: 'Desk-1-1',
            name: 'John Knight',
            initials: 'JK',
            department: 'Customer Service',
            picture: '/person/male3.jpg',
          },
          {
            deskid: 'Desk-1-1',
            name: 'Ruth Green',
            initials: 'RG',
            department: 'Customer Service',
            picture: '/person/female4.jpg',
          },
          {
            deskid: 'Desk-1-1',
            name: 'Wendy Vogt',
            initials: 'Wv',
            department: 'Customer Service',
            picture: '/person/female2.jpg',
          },
        ];
        //poc code
        bookable.forEach((element, index) => {
          var _booked =
            index === 0 ||
            index === 2 ||
            index === 8 ||
            index === 12 ||
            index === 14 ||
            index === 15 ||
            index === 20 ||
            index === 24;
          var personIndex = 0;
          switch (index) {
            case 0:
              personIndex = 0;
              break;
            case 2:
              personIndex = 1;
              break;
            case 8:
              personIndex = 2;
              break;
            case 12:
              personIndex = 3;
              break;
            case 14:
              personIndex = 4;
              break;
            case 15:
              personIndex = 5;
              break;
            case 20:
              personIndex = 6;
              break;
            case 24:
              personIndex = 7;
              break;
            default:
              break;
          }

          if (_booked) {
            element.avatar = personIndex;
            element.name = bookinginfo[personIndex].name;
            element.initials = bookinginfo[personIndex].initials;
            element.department = bookinginfo[personIndex].department;
            element.picture = bookinginfo[personIndex].picture;
          }
          element.booked = _booked;
        });
        setMarkers(bookable);
      }

      // if (data.departments != null) {
      //   setDepartments(JSON.parse(data.departments.replace(/\\"/g, '"')));
      // }

      setLoading(false);
    });
  }, [id]);

  return (
    <>
      <GridContainer>
        <GridItem md={6}>
          <PrimaryTypo>
            Selected Location {location} / Date {new Date(date).toDateString()}
          </PrimaryTypo>
        </GridItem>
        <GridItem md={6}>
          <PrimaryTypo>
            <FormControlLabel
              control={
                <Switch
                  checked={showAll}
                  onChange={(event) => setShowAll(event.target.checked)}
                  classes={{
                    switchBase: classes.switchBase,
                    checked: classes.switchChecked,
                    thumb: classes.switchIcon,
                    track: classes.switchBar,
                  }}
                />
              }
              classes={{
                label: classes.label,
              }}
              label="Show Already Booked Desks"
            />
          </PrimaryTypo>
        </GridItem>
        <GridItem md={12}>
          <Loading show={loading} />
          {!loading && (
            <MapContainer
              crs={L.CRS.Simple}
              minZoom={0}
              maxZoom={3}
              doubleClickZoom={false}
              maxBounds={bounds}
              bounds={bounds}
              //boundsOptions={{ padding: [50, 50] }}
              whenCreated={_setMapRef}
              style={style}
              placeholder={<MapPlaceholder />}
            >
              {markers.map((mark, index) => (
                <>
                  {((mark.booked && showAll) || !mark.booked) && (
                    <Marker
                      key={'u' + mark.id}
                      icon={IconMarker.iconForge(mark.initials, mark.booked)}
                      position={mark.position} //
                      eventHandlers={{
                        click: () => {
                          showDrawerInfo(mark);
                        },
                      }}
                    ></Marker>
                  )}
                </>
              ))}
            </MapContainer>
          )}
        </GridItem>
      </GridContainer>

      <SwipeableDrawer
        anchor="right"
        open={showDrawer}
        onClose={() => setShowDrawer(false)}
        onOpen={() => setShowDrawer(true)}
        style={{ width: '400px' }}
      >
        <InfoContact
          info={selectedMark}
          date={date}
          cancel={() => setShowDrawer(false)}
        />
      </SwipeableDrawer>
    </>
  );
}
FloorMap.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  id: PropTypes.string,
  location: PropTypes.string,
  date: PropTypes.instanceOf(Date),
};
