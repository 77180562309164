import React, { useEffect } from 'react';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
// core components
import Header from 'components/Header/Header.js';
import Footer from 'components/Footer/Footer.js';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardFooter from 'components/Card/CardFooter.js';
import styles from 'assets/jss/material-kit-react/views/loginPage.js';
import { useLocation, useHistory } from 'react-router-dom';
import image from 'assets/img/bg7.jpg';
import { GetMe, SaveUserStore } from 'services/auth';
import HeaderLinks from 'components/Header/HeaderLinks.js';
import Loading from 'components/Loading';
const useStyles = makeStyles(styles);

export default function ContinueAssessmentPage(props) {
  const location = useLocation();
  const history = useHistory();
  const [cardAnimaton, setCardAnimation] = React.useState('cardHidden');
  const [loading, setLoading] = React.useState(true);
  setTimeout(function () {
    setCardAnimation('');
  }, 700);
  const classes = useStyles();
  const { ...rest } = props;

  useEffect(() => {
    var params = new URLSearchParams(location.search);

    var token = atob(params.get('tk'));
    var exp = atob(params.get('expire'));

    var today = new Date();
    var expireDate = new Date(exp);
    var diffMs = expireDate - today; // milliseconds between now & Christmas

    var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes

    console.log('expire', diffMins);
    SaveUserStore(token, diffMins, true);
    GetMe(token).then(() => {
      setLoading(false);
      history.push('/assessment');
    });
  }, [location, history]);

  return (
    <div>
      <Header
        absolute
        color="transparent"
        brand="Material Kit React"
        rightLinks={<HeaderLinks />}
        showOnlyLogout={true}
        {...rest}
      />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: 'url(' + image + ')',
          backgroundSize: 'cover',
          backgroundPosition: 'top center',
        }}
      >
        <div className={classes.container}>
          <GridContainer justifyContent="center">
            <GridItem xs={12} sm={12} md={4}>
              <Card className={classes[cardAnimaton]}>
                <form
                  className={classes.form}
                  style={{
                    align: 'center',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                  }}
                >
                  <CardHeader color="primary" className={classes.cardHeader}>
                    <h4>Continue Assessment in another device</h4>
                  </CardHeader>
                  <CardBody>
                    <Loading show={loading} />
                  </CardBody>
                </form>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
        <Footer whiteFont />
      </div>
    </div>
  );
}
