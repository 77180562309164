import React, { useEffect, useState } from 'react';
import MUIDataTable from 'mui-datatables';
import Loading from 'components/Loading';
import { GetRemindersHistory } from 'services/user';
export default function HistoryList() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const columns = [
    {
      label: 'ID',
      name: 'id',
      options: {
        filter: false,
        display: false,
      },
    },

    {
      label: 'Date',
      name: 'dt',
      options: {
        filter: true,
      },
    },
    {
      label: 'Email',
      name: 'email',
      options: {
        filter: false,
      },
    },
    {
      label: 'Reminder Type',
      name: 'reminder_type',
      options: {
        filter: true,
      },
    },
  ];

  const options = {
    selectableRows: 'none',
    filterType: 'checkbox',
    rowsPerPage: 100,
  };

  const LoadData = () => {
    GetRemindersHistory().then((res) => {
      var _data = res.data;

      if (_data.length === 0) {
        _data = [];
      }

      setData(_data);
      setLoading(false);
    });
  };
  useEffect(() => {
    LoadData();
  }, []);
  return (
    <>
      <Loading show={loading} />

      {!loading && (
        <MUIDataTable
          title="Reminders History"
          data={data}
          columns={columns}
          options={options}
        />
      )}
    </>
  );
}
